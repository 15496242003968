export var isSuccessfulResponse = function isSuccessfulResponse(response) {
  if (!response) {
    return false;
  }
  var hasSuccessfulStatus = response.status === 200;
  var hasSuccessBody = ('body' in response);
  return hasSuccessfulStatus && hasSuccessBody;
};
export var isErrorResponse = function isErrorResponse(response) {
  if (!response) {
    return false;
  }
  var hasStatus = 'status' in response && response.status >= 200;
  var hasErrorBody = ('error' in response);
  return hasStatus && hasErrorBody;
};