import supports from '../utils/supported-features';
import { bindActions } from './bind-actions';
import createStoreState from './create-state';
export const GLOBAL_SCOPE = '__global__';
export class StoreRegistry {
  constructor(defaultScope) {
    var _this = this;

    if (defaultScope === void 0) {
      defaultScope = GLOBAL_SCOPE;
    }

    this.stores = new Map();

    this.initStore = (key, Store, config) => {
      const {
        initialState,
        actions
      } = Store;

      if (Store.containedBy && !config.contained(Store)) {
        const err = new Error(`Store ${Store.key} should be contained by a container but it is used globally. ` + `While it might still work, it will likely cause unexpected behaviours.`);
        if (supports.scheduling()) Promise.reject(err);else throw err;
      }

      const storeState = createStoreState(key, initialState);
      let boundActions;
      const store = {
        storeState,

        // these are used only when container-less, so we generate them on-demand
        get actions() {
          if (!boundActions) boundActions = bindActions(actions, storeState, config);
          return boundActions;
        }

      };
      this.stores.set(key, store);
      return store;
    };

    this.hasStore = function (Store, scopeId) {
      if (scopeId === void 0) {
        scopeId = _this.defaultScope;
      }

      const key = _this.generateKey(Store, scopeId);

      return _this.stores.has(key);
    };

    this.getStore = function (Store, scopeId, config) {
      if (scopeId === void 0) {
        scopeId = _this.defaultScope;
      }

      if (config === void 0) {
        config = {
          props: () => ({}),
          contained: () => false
        };
      }

      const key = _this.generateKey(Store, scopeId);

      return _this.stores.get(key) || config && _this.initStore(key, Store, config);
    };

    this.deleteStore = function (Store, scopeId) {
      if (scopeId === void 0) {
        scopeId = _this.defaultScope;
      }

      const key = _this.generateKey(Store, scopeId);

      _this.stores.delete(key);
    };

    this.generateKey = (Store, scopeId) => `${Store.key}@${scopeId}`;

    this.defaultScope = defaultScope;
  }

}
export const defaultRegistry = new StoreRegistry();