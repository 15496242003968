var getStatusForNotFoundVisibility = function getStatusForNotFoundVisibility(meta) {
  var _meta$requestAccess;
  var accessType = (_meta$requestAccess = meta.requestAccess) === null || _meta$requestAccess === void 0 ? void 0 : _meta$requestAccess.accessType;
  if (!accessType || accessType === 'ACCESS_EXISTS') {
    return 'not_found';
  }
  // for the rest of the accessTypes we will return forbidden,see https://product-fabric.atlassian.net/browse/EDM-7125
  return 'forbidden';
};
export var getStatus = function getStatus(_ref) {
  var meta = _ref.meta;
  var access = meta.access,
    visibility = meta.visibility;
  switch (access) {
    case 'forbidden':
      if (visibility === 'not_found') {
        return getStatusForNotFoundVisibility(meta);
      } else {
        return 'forbidden';
      }
    case 'unauthorized':
      return 'unauthorized';
    default:
      return 'resolved';
  }
};