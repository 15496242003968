/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, select, spawn, take } from 'redux-saga/effects';

import { ActivityOverviewEntry } from 'src/components/activity/types';
import initialState from 'src/initial-state';
import {
  EnteredCodeReviewAction,
  EXITED_CODE_REVIEW,
  LOAD_DIFFSTAT,
  FETCH_DIFF,
  fetchTasks,
} from 'src/redux/pull-request/actions';
import { FETCH_ACTIVITY } from 'src/redux/pull-request/activity-reducer';
import {
  getLastPullRequestRetrievalTime,
  getCurrentPullRequest,
  getDefaultMergeStrategy,
} from 'src/redux/pull-request/selectors';
import { getPullRequestRichActivityFeed } from 'src/redux/pull-request/selectors/activity-selectors';
import { prefetchCommits } from 'src/redux/repo-commits/sagas';
import { getCurrentRepoCommits } from 'src/redux/repo-commits/selectors';
import { hasTransitioned } from 'src/router/history';
import {
  appWasServerSideRendered,
  getInitialOrBucketState,
} from 'src/utils/ssr';

import * as api from '../api';

import fetchBranchSyncInfoSaga from './branch-sync-info-saga';
import fetchConflictsSaga from './fetch-conflicts-saga';
import { fetchDestinationBranch } from './fetch-destination-branch';
import { watchSaga } from './watch-saga';

export const TIME_TO_STALE = 30 * 1000;

// Utils
// @ts-ignore TODO: fix noImplicitAny error here
export const isSamePR = (prData, owner, slug, id) => {
  const currentPullRequest = prData?.currentPullRequest || {};
  const prFullName =
    currentPullRequest?.destination?.repository?.full_name || '/';
  const [prOwner, prSlug] = prFullName.split('/');

  return (
    parseInt(currentPullRequest.id, 10) === parseInt(id, 10) &&
    prOwner === owner &&
    prSlug === slug
  );
};

export function* codeReviewDataSaga(action: EnteredCodeReviewAction) {
  // In rare cases, it's possible that EXITED_CODE_REVIEW fails to dispatch
  // If activityEvents length is !== 0, then the action was not dispatched
  const activityEvents: ActivityOverviewEntry[] = yield select(
    getPullRequestRichActivityFeed
  );
  if (activityEvents.length !== 0) {
    yield put({ type: EXITED_CODE_REVIEW });
  }

  const { owner, slug, id } = action;

  // This mimics the structure of the hydrated pr data from window.__initial_state__
  const statePRdata = {
    // @ts-ignore
    commits: yield select(getCurrentRepoCommits),
    // @ts-ignore
    currentPullRequest: yield select(getCurrentPullRequest),
    // @ts-ignore
    defaultMergeStrategy: yield select(getDefaultMergeStrategy),
  };

  // When SSR is enabled and init load, PR data should be ready in ssrstate.reduxStore
  // When SSR is enabled and has transitioned, hydratedPRdata should be undefined
  // If SSR is off, get PRdata from initialState

  let hydratedPRdata;
  if (appWasServerSideRendered()) {
    hydratedPRdata = !hasTransitioned()
      ? getInitialOrBucketState()?.repository?.pullRequest
      : undefined;
  } else {
    hydratedPRdata = initialState?.repository?.pullRequest;
  }

  const lastFetched: number = yield select(getLastPullRequestRetrievalTime);
  const timeSinceLastFetch = lastFetched ? Date.now() - lastFetched : 0;

  const shouldUseState =
    !!statePRdata &&
    !!statePRdata.commits &&
    !!statePRdata.currentPullRequest &&
    isSamePR(statePRdata, owner, slug, id) &&
    timeSinceLastFetch <= TIME_TO_STALE &&
    // Only use cached redux state and skip fetching of non-critical data
    // when it's not an initial page load via SSR
    hasTransitioned();

  // Activity feed should be cleared when the user leaves a PR.
  // So, we need to refetch it even if it's the same PR
  yield put({ type: FETCH_ACTIVITY.REQUEST, owner, slug, id });

  // Fetch the watching status when PR initially loads (ENTERED_CODE_REVIEW)
  yield spawn(watchSaga, api.getWatch, {
    owner,
    slug,
    id,
  });

  if (shouldUseState) {
    return;
  }

  yield spawn(prefetchCommits, { owner, slug, id });
  yield spawn(fetchBranchSyncInfoSaga, owner, slug, id);
  yield put(fetchTasks({ owner, repoSlug: slug, pullRequestId: id }));

  // Completing a fetch of pullrequest will trigger a fetch of conversations
  const haveHydratedPR = !!hydratedPRdata;
  if (haveHydratedPR) {
    // we should not have to delete a property on an initialState object,
    // but this was flagged as an error when upgrading to Typescript 4.1
    // so in order to avoid changing too much as part of the upgrade I'm just
    // ts-ignoring the error for now and flagging it.
    // @ts-ignore TODO: Change approach to no longer delete the property
    delete initialState?.repository?.pullRequest;
  }

  yield spawn(fetchDestinationBranch, owner, slug);

  yield take([FETCH_DIFF.SUCCESS, LOAD_DIFFSTAT.SUCCESS]);
  yield spawn(fetchConflictsSaga, owner, slug, id);
}
