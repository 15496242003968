import { clearScheduled, scheduleTimeout } from './shared-schedule';

// This file is a singleton for managing tooltips

var active = null;
export function show(entry) {
  var phase = 'waiting-to-show';
  function isActive() {
    return Boolean(active && active.entry === entry);
  }
  function cleanup() {
    if (isActive()) {
      clearScheduled();
      active = null;
    }
  }
  function done() {
    if (isActive()) {
      entry.done();
    }
    phase = 'done';
    cleanup();
  }
  function immediatelyHideAndDone() {
    if (isActive()) {
      entry.hide({
        isImmediate: true
      });
    }
    done();
  }
  function keep() {
    if (!isActive()) {
      return;
    }

    // aborting a wait to hide
    if (phase === 'waiting-to-hide') {
      phase = 'shown';
      clearScheduled();
      return;
    }

    // aborting hide animation
    if (phase === 'hide-animating') {
      phase = 'shown';
      clearScheduled();
      entry.show({
        isImmediate: false
      });
      return;
    }
  }
  function requestHide(_ref) {
    var isImmediate = _ref.isImmediate;
    if (!isActive()) {
      return;
    }

    // If we were not showing yet anyway; finish straight away
    if (phase === 'waiting-to-show') {
      immediatelyHideAndDone();
      return;
    }

    // already waiting to hide
    if (phase === 'waiting-to-hide') {
      return;
    }
    if (isImmediate) {
      immediatelyHideAndDone();
      return;
    }
    phase = 'waiting-to-hide';
    scheduleTimeout(function () {
      phase = 'hide-animating';
      entry.hide({
        isImmediate: false
      });
    }, entry.delay);
  }
  function finishHideAnimation() {
    if (isActive() && phase === 'hide-animating') {
      done();
    }
  }
  function isVisible() {
    return phase === 'shown' || phase === 'waiting-to-hide' || phase === 'hide-animating';
  }
  function getInitialMouse() {
    if (entry.source.type === 'mouse') {
      return entry.source.mouse;
    }
    return null;
  }
  function start() {
    var showImmediately = Boolean(active && active.isVisible());

    // If there was an active tooltip; we tell it to remove itself at once!
    if (active) {
      clearScheduled();
      active.entry.hide({
        isImmediate: true
      });
      active.entry.done();
      active = null;
    }

    // this tooltip is now the active tooltip
    active = {
      entry: entry,
      isVisible: isVisible
    };
    function show() {
      phase = 'shown';
      entry.show({
        isImmediate: showImmediately
      });
    }
    if (showImmediately) {
      show();
      return;
    }
    phase = 'waiting-to-show';
    scheduleTimeout(show, entry.delay);
  }
  // let's get started!
  start();
  var result = {
    keep: keep,
    abort: cleanup,
    isActive: isActive,
    requestHide: requestHide,
    finishHideAnimation: finishHideAnimation,
    mousePosition: getInitialMouse()
  };
  return result;
}