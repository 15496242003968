import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useRef, useState } from 'react';

/**
 * Functions similarly to `useState` however the return value is a ref.
 *
 * ```js
 * const [valueRef, setValue] = useStateRef(0);
 * ```
 *
 * @param initialState
 */
export default function useStateRef(initialState) {
  var _useState = useState(initialState),
    _useState2 = _slicedToArray(_useState, 2),
    value = _useState2[0],
    setValue = _useState2[1];
  var valueRef = useRef(value);
  valueRef.current = value;
  return [valueRef, setValue];
}