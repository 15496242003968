import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { jsx } from '@emotion/react';
import { fg } from '@atlaskit/platform-feature-flags';
import { media } from '@atlaskit/primitives';
import { getStyleProps } from '../utils';

// ==============================
// Root Container
// ==============================

export var containerCSS = function containerCSS(_ref) {
  var isDisabled = _ref.isDisabled,
    isRtl = _ref.isRtl;
  return _defineProperty({
    label: 'container',
    direction: isRtl ? 'rtl' : undefined,
    position: 'relative',
    font: fg('platform_design_system_team_safari_input_fix') ? "var(--ds-font-body-large, normal 400 16px/24px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)" : "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
    pointerEvents: 'all',
    cursor: isDisabled ? 'not-allowed' : undefined
  }, "".concat(media.above.xs), {
    font: fg('platform_design_system_team_safari_input_fix') ? "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)" : undefined
  });
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var SelectContainer = function SelectContainer(props) {
  var children = props.children,
    innerProps = props.innerProps,
    isDisabled = props.isDisabled,
    isRtl = props.isRtl;
  return jsx("div", _extends({}, getStyleProps(props, 'container', {
    '--is-disabled': isDisabled,
    '--is-rtl': isRtl
  }), innerProps), children);
};

// ==============================
// Value Container
// ==============================

export var valueContainerCSS = function valueContainerCSS(_ref3) {
  var isMulti = _ref3.isMulti,
    hasValue = _ref3.hasValue,
    isCompact = _ref3.isCompact,
    controlShouldRenderValue = _ref3.selectProps.controlShouldRenderValue;
  return {
    alignItems: 'center',
    display: isMulti && hasValue && controlShouldRenderValue ? 'flex' : 'grid',
    flex: 1,
    flexWrap: 'wrap',
    WebkitOverflowScrolling: 'touch',
    position: 'relative',
    overflow: 'hidden',
    padding: "".concat(isCompact ? 0 : "var(--ds-space-025, 2px)", " ", "var(--ds-space-075, 6px)")
  };
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var ValueContainer = function ValueContainer(props) {
  var children = props.children,
    innerProps = props.innerProps,
    isMulti = props.isMulti,
    hasValue = props.hasValue;
  var styles = getStyleProps(props, 'valueContainer', {
    'value-container': true,
    'value-container--is-multi': isMulti,
    'value-container--has-value': hasValue
  });
  return jsx("div", _extends({
    css: styles.css
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
    ,
    className: styles.className || 'value-container'
  }, innerProps), children);
};

// ==============================
// Indicator Container
// ==============================

export var indicatorsContainerCSS = function indicatorsContainerCSS() {
  return {
    alignItems: 'center',
    alignSelf: 'stretch',
    display: 'flex',
    flexShrink: 0,
    paddingRight: "var(--ds-space-050, 4px)"
  };
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var IndicatorsContainer = function IndicatorsContainer(props) {
  var children = props.children,
    innerProps = props.innerProps;
  return jsx("div", _extends({}, getStyleProps(props, 'indicatorsContainer', {
    indicators: true
  }), innerProps), children);
};