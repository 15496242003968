import { getCLS } from './cls';
import { PerformanceObserverEntryTypes } from './const';
import { getTBT } from './tbt';
import { EntriesBuffer } from './utils/buffer';
import { startLSObserver, startLTObserver } from './utils/observer';
export function startLighthouseObserver() {
  startLSObserver();
  startLTObserver();
}
export var getLighthouseMetrics = function getLighthouseMetrics(_ref) {
  var start = _ref.start,
    stop = _ref.stop;
  var tbt = getTBT(start, stop, EntriesBuffer[PerformanceObserverEntryTypes.LongTask]);

  // no round as CLS is usually 0-1
  var cls = getCLS(start, stop, EntriesBuffer[PerformanceObserverEntryTypes.LayoutShift]);
  return {
    'metric:tbt': Math.round(tbt.total),
    'metric:tbt:observed': Math.round(tbt.observed),
    'metric:cls': cls
  };
};