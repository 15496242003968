import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pullRequestDraftReviewersFlagTitle: {
    id: 'global.flags.pullRequestDraftReviewers.flag.title',
    defaultMessage: 'Notifying reviewers',
  },
  pullRequestDraftReviewersFlagDescription: {
    id: 'global.flags.pullRequestDraftReviewers.flag.description',
    defaultMessage:
      'New reviewers added to this draft pull request will be notified when the pull request is marked as ready. Any reviewers added previously will not be notified again.',
  },
  pullRequestDraftReviewersFlagEditorDismiss: {
    id: 'global.flags.pullRequestDraftReviewers.flag.dismiss',
    defaultMessage: 'Understood',
  },
});
