import { useCallback, useEffect, useRef } from 'react';
import { supportsPassiveEvents } from '../../utils';
var cancelScroll = function cancelScroll(event) {
  if (event.cancelable) {
    event.preventDefault();
  }
  event.stopPropagation();
};
// TODO: Fill in the hook {description}.
/**
 * {description}.
 */
export default function useScrollCapture(_ref) {
  var isEnabled = _ref.isEnabled,
    onBottomArrive = _ref.onBottomArrive,
    onBottomLeave = _ref.onBottomLeave,
    onTopArrive = _ref.onTopArrive,
    onTopLeave = _ref.onTopLeave;
  var isBottom = useRef(false);
  var isTop = useRef(false);
  var touchStart = useRef(0);
  var scrollTarget = useRef(null);
  var handleEventDelta = useCallback(function (event, delta) {
    if (scrollTarget.current === null) {
      return;
    }
    var _scrollTarget$current = scrollTarget.current,
      scrollTop = _scrollTarget$current.scrollTop,
      scrollHeight = _scrollTarget$current.scrollHeight,
      clientHeight = _scrollTarget$current.clientHeight;
    var target = scrollTarget.current;
    var isDeltaPositive = delta > 0;
    var availableScroll = scrollHeight - clientHeight - scrollTop;
    var shouldCancelScroll = false;

    // reset bottom/top flags
    if (availableScroll > delta && isBottom.current) {
      if (onBottomLeave) {
        onBottomLeave(event);
      }
      isBottom.current = false;
    }
    if (isDeltaPositive && isTop.current) {
      if (onTopLeave) {
        onTopLeave(event);
      }
      isTop.current = false;
    }

    // bottom limit
    if (isDeltaPositive && delta > availableScroll) {
      if (onBottomArrive && !isBottom.current) {
        onBottomArrive(event);
      }
      target.scrollTop = scrollHeight;
      shouldCancelScroll = true;
      isBottom.current = true;

      // top limit
    } else if (!isDeltaPositive && -delta > scrollTop) {
      if (onTopArrive && !isTop.current) {
        onTopArrive(event);
      }
      target.scrollTop = 0;
      shouldCancelScroll = true;
      isTop.current = true;
    }

    // cancel scroll
    if (shouldCancelScroll) {
      cancelScroll(event);
    }
  }, [onBottomArrive, onBottomLeave, onTopArrive, onTopLeave]);
  var onWheel = useCallback(function (event) {
    handleEventDelta(event, event.deltaY);
  }, [handleEventDelta]);
  var onTouchStart = useCallback(function (event) {
    // set touch start so we can calculate touchmove delta
    touchStart.current = event.changedTouches[0].clientY;
  }, []);
  var onTouchMove = useCallback(function (event) {
    var deltaY = touchStart.current - event.changedTouches[0].clientY;
    handleEventDelta(event, deltaY);
  }, [handleEventDelta]);
  var startListening = useCallback(function (el) {
    // bail early if no element is available to attach to
    if (!el) {
      return;
    }
    var notPassive = supportsPassiveEvents ? {
      passive: false
    } : false;
    // eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
    el.addEventListener('wheel', onWheel, notPassive);
    // eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
    el.addEventListener('touchstart', onTouchStart, notPassive);
    // eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
    el.addEventListener('touchmove', onTouchMove, notPassive);
  }, [onTouchMove, onTouchStart, onWheel]);
  var stopListening = useCallback(function (el) {
    // bail early if no element is available to detach from
    if (!el) {
      return;
    }

    // eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
    el.removeEventListener('wheel', onWheel, false);
    // eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
    el.removeEventListener('touchstart', onTouchStart, false);
    // eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
    el.removeEventListener('touchmove', onTouchMove, false);
  }, [onTouchMove, onTouchStart, onWheel]);
  useEffect(function () {
    if (!isEnabled) {
      return;
    }
    var element = scrollTarget.current;
    startListening(element);
    return function () {
      stopListening(element);
    };
  }, [isEnabled, startListening, stopListening]);
  return function (element) {
    scrollTarget.current = element;
  };
}