/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import escapeRegExp from 'lodash-es/escapeRegExp';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import stripAnsi from 'strip-ansi';

import { createLogLineWorker } from 'src/components/pipelines/workers/index';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest from 'src/utils/fetch';

import { MAX_WORKER_TIMEOUT_LIMIT } from '../../constants';
import {
  LogRequestMeta,
  StepCommand,
  StepLogMap,
  LogLineWorkerProps,
  LogLineWorkerReturnTypes,
} from '../../models';
import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import {
  collapseCommand,
  expandCommand,
  DISABLE_LOG_SEARCH,
  DOWNLOAD_ALL_COMMANDS,
  REQUEST_FULL_LOG,
  REQUEST_LOG,
  REQUEST_LOG_THROTTLED,
  REQUEST_FLAT_NESTED_LOGS,
  SEARCH_LOG,
  REQUEST_DELETE_LOG,
  REQUEST_SERVICE_LOG,
  ENABLE_LOG_SEARCH,
  SET_STEP,
} from '../actions/pipelines';
import {
  getCommandsForCurrentStep,
  getCurrentPipeline,
  getCurrentStep,
  getExpandedCommands,
  getExpandedInfiniteLog,
  getIsCommandDownloaded,
  getSearchLog,
  getStepLogForCurrentStep,
  getDownloadedCommandsForCurrentStep,
} from '../selectors/pipelines';

import { overHalfOfTotalCommandsDownloaded } from './utils';

export const getLogUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string
): string => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/log`;
};

export const getServiceLogUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string,
  serviceUuid: string
): string => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/logs/${serviceUuid}`;
};

export const getDeleteLogUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string,
  serviceUuid: string
): string => {
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/logs/${serviceUuid}`;
};

export const getFileUrl = (
  repositoryFullSlug: string,
  revision: string,
  path = ''
): string => {
  return `${location.origin}/${repositoryFullSlug}/src/${encodeURIComponent(
    revision
  )}/${path}`;
};

const IGNORED_ERRORS = ['upstream service timed out on proxied request'];

export function spawnWorker<T, U>(
  create: () => Worker,
  message: T
): Promise<U | Error> {
  return new Promise((resolve, reject) => {
    const worker = create();
    worker.postMessage(message);
    worker.onmessage = (event: MessageEvent<U>) => {
      worker.terminate();
      resolve(event.data);
    };
    worker.onerror = err => {
      worker.terminate();
      reject(err);
    };
    worker.onmessageerror = err => {
      worker.terminate();
      reject(err);
    };
    setTimeout(() => {
      worker.terminate();
      reject(new Error('Log worker timeout'));
    }, MAX_WORKER_TIMEOUT_LIMIT);
  });
}

export function* processLogLinesSaga(
  log: string,
  meta: LogRequestMeta
): Generator {
  const stepLog = (yield select(getStepLogForCurrentStep)) as StepLogMap;

  return yield spawnWorker<LogLineWorkerProps, LogLineWorkerReturnTypes>(
    createLogLineWorker,
    {
      logLines: log.trimEnd().split(/\r?\n|\r/),
      stepLog,
      meta,
    }
  );
}

export function* requestLogSaga(action: {
  meta: LogRequestMeta;
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const { pipelineUuid, stepUuid, range, requestCounter, index, requestType } =
    action.meta;
  try {
    const url = getLogUrl(full_name, pipelineUuid, stepUuid);

    const res: Response = yield call(
      fetch,
      authRequest(url, {
        headers: {
          Range: `bytes=${range.rangeStart}-${range.rangeEnd}`,
          'Command-Index': index,
          'Request-Counter': requestCounter,
          'Incremental-Request-Enabled': true,
          'Request-Type': requestType || '',
        },
        responseType: 'text',
      } as any)
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }

    const data = yield call([res, 'text']);

    if (action.meta.index === undefined) {
      const stepCommands = yield select(getCommandsForCurrentStep);

      yield put({
        type: REQUEST_FULL_LOG.SUCCESS, // Used in Log download or log search
        meta: {
          ...action.meta,
          stepCommands,
        },
        payload: data,
      });
    } else {
      const payload = yield call(processLogLinesSaga, data, action.meta);
      yield put({
        type: REQUEST_FLAT_NESTED_LOGS.SUCCESS,
        meta: action.meta,
        payload,
      });
    }
  } catch (e) {
    capturePipelinesExceptionWithTags(
      e,
      {
        segment: REQUEST_LOG.ERROR,
      },
      {},
      IGNORED_ERRORS
    );
    yield put({
      type: REQUEST_LOG.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestServiceLogSaga(action: {
  meta: { pipelineUuid: string; stepUuid: string; serviceUuid: string };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const { pipelineUuid, stepUuid, serviceUuid } = action.meta;
  try {
    const url = getServiceLogUrl(
      full_name,
      pipelineUuid,
      stepUuid,
      serviceUuid
    );
    const res: Response = yield call(
      fetch,
      authRequest(url, { responseType: 'text' } as any)
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'text']);
    yield put({
      type: REQUEST_SERVICE_LOG.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(
      e,
      {
        segment: REQUEST_SERVICE_LOG.ERROR,
      },
      {},
      IGNORED_ERRORS
    );
    yield put({
      type: REQUEST_SERVICE_LOG.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestDeleteLogSaga(action: {
  meta: { serviceUuid?: string };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const pipeline = yield select(getCurrentPipeline);
  const step = yield select(getCurrentStep);
  try {
    const url = getDeleteLogUrl(
      full_name,
      pipeline.uuid,
      step.uuid,
      action.meta.serviceUuid || step.uuid
    );

    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'DELETE' })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }

    yield put({
      type: REQUEST_DELETE_LOG.SUCCESS,
      meta: { ...action.meta, stepUuid: step.uuid },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(
      e,
      {
        segment: REQUEST_DELETE_LOG.ERROR,
      },
      {},
      IGNORED_ERRORS
    );
    yield put({
      type: REQUEST_DELETE_LOG.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* matchLogLinesSaga(action: { meta: string }): any {
  const matches: number[] = [];
  if (action.meta.length > 2) {
    const infiniteLog = yield select(getExpandedInfiniteLog);
    const regex = new RegExp(escapeRegExp(action.meta), 'i');
    const logLimit = infiniteLog.length;
    for (let index = 0; index < logLimit; index++) {
      const line: string | StepCommand = infiniteLog[index];
      if (typeof line === 'string' && regex.test(stripAnsi(line))) {
        matches.push(index);
      }
    }
  }
  yield put({ type: SEARCH_LOG.SUCCESS, payload: matches, meta: action.meta });
}

export function* downloadRemainingCommandsSaga(
  isLogSearchOptimisationEnabled: boolean
): any {
  const pipeline = yield select(getCurrentPipeline);
  const step = yield select(getCurrentStep);
  const commands = yield select(getCommandsForCurrentStep);
  const isCommandDownloaded = yield select(getIsCommandDownloaded);

  yield all(
    commands.map((command: StepCommand, index: number) => {
      if (!command.log_range) return undefined;
      const rangeStart = command.log_range.first_byte_position;
      const rangeEnd = command.log_range.last_byte_position;
      if (!isCommandDownloaded(command) && rangeStart !== rangeEnd) {
        const range = {
          rangeStart,
          rangeEnd,
        };
        return call(requestLogSaga, {
          meta: {
            pipelineUuid: pipeline.uuid,
            stepUuid: step.uuid,
            range,
            index,
            requestCounter: 0,
            isLogSearchOptimisationEnabled,
          },
          type: REQUEST_LOG.REQUEST,
        });
      } else {
        return undefined;
      }
    })
  );
}

export function* downloadAllCommandsSaga(
  isLogSearchOptimisationEnabled: boolean
): any {
  const pipeline = yield select(getCurrentPipeline);
  const step = yield select(getCurrentStep);

  yield call(requestLogSaga, {
    meta: {
      pipelineUuid: pipeline.uuid,
      stepUuid: step.uuid,
      range: {
        rangeStart: 0,
      },
      isLogSearchOptimisationEnabled,
    },
    type: REQUEST_LOG.REQUEST,
  });
}

export function* downloadCommandsSaga(
  isLogSearchOptimisationEnabled: boolean
): any {
  if (!isLogSearchOptimisationEnabled) {
    return yield call(
      downloadRemainingCommandsSaga,
      isLogSearchOptimisationEnabled
    );
  }

  const commands = yield select(getCommandsForCurrentStep);
  const downloadedCommands = yield select(getDownloadedCommandsForCurrentStep);

  /*
    Logs endpoint does not (and probably never will) support requesting multiple ranges in a single request.
    If it was supported we could perfectly optimise downloading all commands by
    always downloaded all undownloaded commands in a single HTTP request.

    Since it is not supported, we can either:
      - Download ALL commands in a single HTTP request
      - Download undownloaded commands in a HTTP request per command

    Downloading all commands in a single HTTP request will typically be the fastest option.
    However, in the case where a user has already downloaded most commands individually (e.g. by opening a few),
    downloading ALL commands in a single HTTP request would be slower than
    downloading the remaining undownloaded commands even if done in multiple HTTP requests.

    In order to not worsen user experience in these cases, we make an approximate guess which method would
    be faster by checking if the user has downloaded more than half of the available commands. If so
    we downloaded the remaining commands individually, but otherwise we download all commands in one request.
  */
  if (overHalfOfTotalCommandsDownloaded(downloadedCommands, commands.length)) {
    return yield call(
      downloadRemainingCommandsSaga,
      isLogSearchOptimisationEnabled
    );
  } else {
    return yield call(downloadAllCommandsSaga, isLogSearchOptimisationEnabled);
  }
}

export function* enableLogSearchSaga(action: {
  meta: { isLogSearchOptimisationEnabled: boolean };
}): any {
  const step = yield select(getCurrentStep);
  const commands = yield select(getCommandsForCurrentStep);
  yield put({ type: DOWNLOAD_ALL_COMMANDS.REQUEST });
  try {
    yield call(
      downloadCommandsSaga,
      action.meta.isLogSearchOptimisationEnabled
    );
    yield put({ type: DOWNLOAD_ALL_COMMANDS.SUCCESS });
    yield all(
      /* eslint-disable @typescript-eslint/no-unused-vars */
      // @ts-ignore
      commands.map((c: any, index: number) =>
        put(expandCommand(step.uuid, index))
      )
    );
  } catch (ignore) {
    yield put({ type: DOWNLOAD_ALL_COMMANDS.ERROR });
  }
}

export function* disableLogSearchSaga(): any {
  const expandedCommands = yield select(getExpandedCommands);
  const collapseAllCommands = Object.keys(expandedCommands).reduce(
    (reducer, stepUuid) => {
      return reducer.concat(
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // @ts-ignore
        expandedCommands[stepUuid].map((c: any, index: number) =>
          put(collapseCommand(stepUuid, index))
        )
      );
    },
    []
  );
  yield all(collapseAllCommands);
}

export function* setStepSearchSaga(): any {
  const searchLog = yield select(getSearchLog);
  if (!searchLog.enabled) {
    return;
  }
  yield put({ type: DISABLE_LOG_SEARCH });
}

export const logSaga = function* () {
  yield all([
    takeLeading(REQUEST_LOG_THROTTLED.REQUEST, requestLogSaga),
    takeEvery(REQUEST_LOG.REQUEST, requestLogSaga),
    takeLatest(REQUEST_SERVICE_LOG.REQUEST, requestServiceLogSaga),
    takeLatest(REQUEST_DELETE_LOG.REQUEST, requestDeleteLogSaga),
    takeEvery(ENABLE_LOG_SEARCH, enableLogSearchSaga),
    takeEvery(DISABLE_LOG_SEARCH, disableLogSearchSaga),
    takeEvery(SEARCH_LOG.REQUEST, matchLogLinesSaga),
    takeEvery(SET_STEP, setStepSearchSaga),
  ]);
};
