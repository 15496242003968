var MAX_ACCEPTABLE_TASK_DURATION = 50;
export var getTBT = function getTBT(start, stop, buffer) {
  return buffer.getAll().filter(function (entry) {
    return entry.startTime <= stop && entry.duration > MAX_ACCEPTABLE_TASK_DURATION && (entry.startTime >= start || entry.startTime + entry.duration >= start || entry.startTime <= start && entry.startTime + entry.duration >= stop);
  }).reduce(function (tbt, entry) {
    var longTaskStop = entry.startTime + entry.duration;
    var intersectStart = Math.max(entry.startTime + MAX_ACCEPTABLE_TASK_DURATION, start);
    var intersectStop = Math.min(longTaskStop, stop);
    var longTaskIncluded = Math.max(intersectStop - intersectStart, 0);
    // eslint-disable-next-line no-param-reassign
    tbt.observed += entry.duration - MAX_ACCEPTABLE_TASK_DURATION;
    // eslint-disable-next-line no-param-reassign
    tbt.total += longTaskIncluded;
    return tbt;
  }, {
    total: 0,
    observed: 0
  });
};