import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export function sanitizeNodes(nodes, supportedMarks) {
  var nodeNames = Object.keys(nodes);
  nodeNames.forEach(function (nodeKey) {
    var nodeSpec = _objectSpread({}, nodes[nodeKey]);
    if (nodeSpec.marks && nodeSpec.marks !== '_') {
      nodeSpec.marks = nodeSpec.marks.split(' ').filter(function (mark) {
        return !!supportedMarks[mark];
      }).join(' ');
    }
    if (nodeSpec.content) {
      nodeSpec.content = sanitizeNodeSpecContent(nodes, nodeSpec.content);
    }
    nodes[nodeKey] = nodeSpec;
  });
  return nodes;
}
function sanitizeNodeSpecContent(nodes, rawContent) {
  var content = rawContent.replace(/\W/g, ' ');
  var contentKeys = content.split(' ');
  var unsupportedContentKeys = contentKeys.filter(function (contentKey) {
    return !isContentSupported(nodes, contentKey);
  });
  return unsupportedContentKeys.reduce(function (newContent, nodeName) {
    return sanitizedContent(newContent, nodeName);
  }, rawContent);
}
function sanitizedContent(content, invalidContent) {
  if (!invalidContent.length) {
    return content || '';
  }
  if (!content || !content.match(/\w/)) {
    return '';
  }
  var pattern = "(".concat(invalidContent, "((\\s)*\\|)+)|((\\|(\\s)*)+").concat(invalidContent, "(\\+|\\*)?)|(").concat(invalidContent, "$)|(").concat(invalidContent, "(\\+|\\*))");
  return content.replace(new RegExp(pattern, 'g'), '').replace('  ', ' ').trim();
}
function isContentSupported(nodes, contentKey) {
  var nodeKeys = Object.keys(nodes);

  // content is with valid node
  if (nodeKeys.indexOf(contentKey) > -1) {
    return true;
  }

  // content is with valid group
  for (var supportedKey in nodes) {
    var nodeSpec = nodes[supportedKey];
    if (nodeSpec && nodeSpec.group === contentKey) {
      return true;
    }
  }
  return false;
}