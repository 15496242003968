/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest from 'src/utils/fetch';

import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import { REQUEST_ARTIFACTS } from '../actions/pipelines';

export const getArtifactsUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string
) => {
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/artifacts`;
};

export const getArtifactsContentUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  artifactUuid: string
) => {
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/artifacts/${artifactUuid}/content`;
};

export function* requestArtifactsSaga(action: {
  meta: { pipelineUuid: string };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getArtifactsUrl(full_name, action.meta.pipelineUuid);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);

    data.values = data.values.map((artifact: any) => ({
      ...artifact,
      artifact_content_url: getArtifactsContentUrl(
        full_name,
        action.meta.pipelineUuid,
        artifact.uuid
      ),
    }));

    yield put({
      type: REQUEST_ARTIFACTS.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_ARTIFACTS.ERROR,
    });
    yield put({
      type: REQUEST_ARTIFACTS.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export default function* () {
  yield all([takeLatest(REQUEST_ARTIFACTS.REQUEST, requestArtifactsSaga)]);
}
