import { B200, B400, B50, N0, N100, N20, N200, N600, N700 } from '@atlaskit/theme/colors';
import { hexToRGBA } from './theme-helpers';
var defaultTheme = {
  mode: {
    create: {
      active: {
        color: "var(--ds-text-inverse, ".concat(N0, ")"),
        backgroundColor: "var(--ds-background-brand-bold-pressed, ".concat(hexToRGBA(B400, 0.8), ")"),
        boxShadow: ''
      },
      default: {
        color: "var(--ds-text-inverse, ".concat(N0, ")"),
        backgroundColor: "var(--ds-background-brand-bold, ".concat(B400, ")"),
        boxShadow: ''
      },
      focus: {
        color: "var(--ds-text-inverse, ".concat(N0, ")"),
        backgroundColor: '',
        boxShadow: ''
      },
      hover: {
        color: "var(--ds-text-inverse, ".concat(N0, ")"),
        backgroundColor: "var(--ds-background-brand-bold-hovered, ".concat(hexToRGBA(B400, 0.9), ")"),
        boxShadow: ''
      },
      selected: {
        color: '',
        backgroundColor: '',
        boxShadow: ''
      }
    },
    iconButton: {
      active: {
        color: "var(--ds-text-subtle, ".concat(B400, ")"),
        backgroundColor: "var(--ds-background-neutral-pressed, ".concat(hexToRGBA(B50, 0.6), ")"),
        boxShadow: ''
      },
      default: {
        color: "var(--ds-text-subtle, ".concat(N600, ")"),
        backgroundColor: 'transparent',
        boxShadow: ''
      },
      focus: {
        color: "var(--ds-text-subtle, ".concat(N600, ")"),
        backgroundColor: '',
        boxShadow: ''
      },
      hover: {
        color: "var(--ds-text-subtle, ".concat(B400, ")"),
        backgroundColor: "var(--ds-background-neutral-hovered, ".concat(hexToRGBA(B50, 0.9), ")"),
        boxShadow: ''
      },
      selected: {
        color: "var(--ds-text-selected, ".concat(B400, ")"),
        backgroundColor: "var(--ds-background-selected, ".concat(hexToRGBA(B50, 0.6), ")"),
        boxShadow: ''
      },
      selectedHover: {
        color: "var(--ds-text-selected, ".concat(B400, ")"),
        backgroundColor: "var(--ds-background-selected-hovered, ".concat(hexToRGBA(B50, 0.6), ")"),
        boxShadow: ''
      }
    },
    navigation: {
      backgroundColor: "var(--ds-surface, ".concat(N0, ")"),
      color: "var(--ds-text-subtlest, ".concat(N200, ")")
    },
    productHome: {
      backgroundColor: "var(--ds-text-brand, ".concat(B400, ")"),
      color: "var(--ds-text, ".concat(N700, ")"),
      borderRight: "1px solid ".concat("var(--ds-border, ".concat(hexToRGBA(N200, 0.3), ")")),
      // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
      iconColor: '#357DE8' // TODO: replace with token after brand refresh
    },
    primaryButton: {
      active: {
        color: "var(--ds-text-subtle, ".concat(B400, ")"),
        backgroundColor: "var(--ds-background-neutral-pressed, ".concat(hexToRGBA(B50, 0.7), ")"),
        boxShadow: ''
      },
      default: {
        color: "var(--ds-text-subtle, ".concat(N600, ")"),
        backgroundColor: 'transparent',
        boxShadow: ''
      },
      focus: {
        color: "var(--ds-text-subtle, ".concat(N600, ")"),
        backgroundColor: '',
        boxShadow: ''
      },
      hover: {
        color: "var(--ds-text-subtle, ".concat(B400, ")"),
        backgroundColor: "var(--ds-background-neutral-hovered, ".concat(hexToRGBA(B50, 0.9), ")"),
        boxShadow: ''
      },
      selected: {
        color: "var(--ds-text-selected, ".concat(B400, ")"),
        backgroundColor: "var(--ds-background-selected, ".concat(hexToRGBA(B50, 0.7), ")"),
        boxShadow: '',
        borderColor: "var(--ds-border-selected, ".concat(B400, ")")
      },
      selectedHover: {
        color: "var(--ds-text-selected, ".concat(B400, ")"),
        backgroundColor: "var(--ds-background-selected-hovered, ".concat(hexToRGBA(B50, 0.6), ")"),
        boxShadow: '',
        borderColor: "var(--ds-border-selected, ".concat(B400, ")")
      }
    },
    search: {
      default: {
        backgroundColor: "var(--ds-background-input, ".concat(N0, ")"),
        color: "var(--ds-text-subtlest, ".concat(N200, ")"),
        borderColor: "var(--ds-border-input, ".concat(N100, ")")
      },
      focus: {
        borderColor: "var(--ds-border-focused, ".concat(B200, ")")
      },
      hover: {
        backgroundColor: "var(--ds-background-input-hovered, ".concat(N0, ")")
      }
    },
    skeleton: {
      backgroundColor: "var(--ds-background-neutral, ".concat(N20, ")"),
      opacity: 1
    }
  }
};
export var DEFAULT_THEME_NAME = 'atlassian';
export default defaultTheme;