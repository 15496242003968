import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _typeof from "@babel/runtime/helpers/typeof";
var _excluded = ["buttonStyles"],
  _excluded2 = ["buttonStyles"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import * as colors from '@atlaskit/theme/colors';
var spotlightTheme = {
  default: {
    background: {
      default: "var(--ds-background-inverse-subtle, ".concat(colors.P500, ")"),
      hover: "var(--ds-background-inverse-subtle-hovered, ".concat(colors.P200, ")"),
      active: "var(--ds-background-inverse-subtle-pressed, ".concat(colors.P500, ")"),
      disabled: "var(--ds-background-disabled, ".concat(colors.P400, ")"),
      selected: "var(--ds-background-inverse-subtle-pressed, ".concat(colors.R500, ")"),
      focus: "var(--ds-background-inverse-subtle, ".concat(colors.P500, ")")
    },
    color: {
      default: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
      hover: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
      active: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
      disabled: {
        light: "var(--ds-text-disabled, ".concat(colors.N0, ")"),
        dark: "var(--ds-text-disabled, ".concat(colors.DN30, ")")
      },
      selected: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
      focus: "var(--ds-text-inverse, ".concat(colors.N0, ")")
    }
  },
  subtle: {
    background: {
      default: 'none',
      hover: "var(--ds-background-inverse-subtle-hovered, ".concat(colors.P200, ")"),
      active: "var(--ds-background-inverse-subtle-pressed, ".concat(colors.P500, ")"),
      disabled: 'none',
      selected: {
        light: "var(--ds-background-selected-hovered, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected-hovered, ".concat(colors.DN0, ")")
      },
      focusSelected: {
        light: "var(--ds-background-selected-hovered, ".concat(colors.N700, ")"),
        dark: "var(--ds-background-selected-hovered, ".concat(colors.DN0, ")")
      }
    },
    color: {
      default: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
      hover: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
      active: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
      disabled: "var(--ds-text-disabled, ".concat(colors.N0, ")"),
      selected: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
      focus: "var(--ds-text-inverse, ".concat(colors.N0, ")")
    }
  },
  'subtle-link': {
    textDecoration: {
      hover: "underline ".concat("var(--ds-text-inverse, ".concat(colors.P75, ")"))
    },
    textDecorationLine: {
      active: 'none'
    },
    color: {
      default: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
      hover: "var(--ds-text-inverse, ".concat(colors.P75, ")"),
      active: "var(--ds-text-inverse, ".concat(colors.P100, ")"),
      disabled: "var(--ds-text-discovery, ".concat(colors.P500, ")"),
      selected: "var(--ds-text-selected, ".concat(colors.N0, ")"),
      focus: "var(--ds-text-inverse, ".concat(colors.N0, ")")
    }
  }
};
var modalTheme = {
  primary: {
    background: {
      default: "var(--ds-background-discovery-bold, ".concat(colors.P400, ")"),
      hover: "var(--ds-background-discovery-bold-hovered, ".concat(colors.P200, ")"),
      active: "var(--ds-background-discovery-bold-pressed, ".concat(colors.P500, ")"),
      disabled: {
        light: "var(--ds-background-disabled, ".concat(colors.N30, ")"),
        dark: "var(--ds-background-disabled, ".concat(colors.DN70, ")")
      },
      selected: "var(--ds-background-selected-hovered, ".concat(colors.R500, ")"),
      focus: "var(--ds-background-discovery-bold-hovered, ".concat(colors.P400, ")")
    },
    color: {
      default: "var(--ds-text-inverse, ".concat(colors.N0, ")"),
      disabled: {
        light: "var(--ds-text-disabled, ".concat(colors.N0, ")"),
        dark: "var(--ds-text-disabled, ".concat(colors.DN30, ")")
      },
      selected: "var(--ds-text-selected, ".concat(colors.N0, ")"),
      focus: "var(--ds-text-inverse, ".concat(colors.N0, ")")
    }
  }
};
function extract(newTheme, _ref) {
  var mode = _ref.mode,
    appearance = _ref.appearance,
    state = _ref.state;
  if (!newTheme[appearance]) {
    return undefined;
  }
  var root = newTheme[appearance];
  return Object.keys(root).reduce(function (acc, val) {
    var node = root;
    [val, state, mode].forEach(function (item) {
      if (!node[item]) {
        return undefined;
      }
      if (_typeof(node[item]) !== 'object') {
        acc[val] = node[item];
        return undefined;
      }
      node = node[item];
      return undefined;
    });
    return acc;
  }, {});
}

/**
 * @deprecated
 * Custom button themes are deprecated and will be removed in the future.
 */
export var spotlightButtonTheme = function spotlightButtonTheme(current, themeProps) {
  var _current = current(themeProps),
    buttonStyles = _current.buttonStyles,
    rest = _objectWithoutProperties(_current, _excluded);
  return _objectSpread({
    buttonStyles: _objectSpread(_objectSpread({}, buttonStyles), extract(spotlightTheme, themeProps))
  }, rest);
};

/**
 * @deprecated
 * Custom button themes are deprecated and will be removed in the future.
 */
export var modalButtonTheme = function modalButtonTheme(current, themeProps) {
  var _current2 = current(themeProps),
    buttonStyles = _current2.buttonStyles,
    rest = _objectWithoutProperties(_current2, _excluded2);
  return _objectSpread({
    buttonStyles: _objectSpread(_objectSpread({}, buttonStyles), extract(modalTheme, themeProps))
  }, rest);
};