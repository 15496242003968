/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import Button from '@atlaskit/button/custom-theme-button';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { N0, N50, N50A, N60A } from '@atlaskit/theme/colors';
import { borderRadius } from '@atlaskit/theme/constants';
import { surveyInnerWidth } from '../constants';
export default (function (_ref) {
  var children = _ref.children,
    onDismiss = _ref.onDismiss;
  return jsx("div", {
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css: css({
      backgroundColor: "var(--ds-surface-overlay, ".concat(N0, ")"),
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      borderRadius: "".concat(borderRadius(), "px"),
      padding: "var(--ds-space-300, 24px)",
      boxShadow: "var(--ds-shadow-overlay, ".concat("0 20px 32px -8px ".concat(N50A, ", 0 0 1px ").concat(N60A), ")"),
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
      width: "".concat(surveyInnerWidth, "px")
    })
  }, jsx("div", {
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css: css({
      position: 'absolute',
      top: "var(--ds-space-200, 16px)",
      right: "var(--ds-space-200, 16px)"
    })
  }, jsx(Button, {
    iconBefore: jsx(CrossIcon, {
      label: "",
      color: "var(--ds-icon-subtle, ".concat(N50, ")")
    }),
    "aria-label": "Dismiss",
    appearance: "subtle",
    onClick: onDismiss
  })), children);
});