import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getBscGlobalState } from '../../../common/utils';
import { getBitValueFromBool } from '../../../common/utils/helpers';
import { V001_SCHEMA_KEY, V002_SCHEMA_KEY, V003_SCHEMA_KEY } from '../../../constants';
import { checkIfTokenIsDefault, serializeConsents } from '../../../services/consent-hub-service/utils';
import { ConsentPreferenceV2, ContextualConsentProperty, PreferenceCategory, PrefetchedPreferenceState } from '../../../types';
export var checkTokenVersionValidity = function checkTokenVersionValidity(consentToken) {
  // A token can technically be bad data (alphanumeric instead of binary) but we should fail gracefully if so
  if (consentToken.length < 3) {
    // Wrong format, no version
    return {
      isValid: false,
      isV001: false,
      isV002: false,
      isV003: false
    };
  }
  var version = consentToken[0] + consentToken[1] + consentToken[2];
  var isV001 = version === V001_SCHEMA_KEY && consentToken.length === 7;
  var isV002 = version === V002_SCHEMA_KEY && consentToken.length === 12;
  var isV003 = version === V003_SCHEMA_KEY && consentToken.length === 10;

  // We support version 001, 002, 003 only
  var isSupportedVersion = [V001_SCHEMA_KEY, V002_SCHEMA_KEY, V003_SCHEMA_KEY].includes(version);
  var isValid = isSupportedVersion && (isV001 || isV002 || isV003);
  return {
    isValid: isValid,
    isV001: isV001,
    isV002: isV002,
    isV003: isV003
  };
};

/* Schema Version 001:
---------------------------------------------------
  - Format 0000000
  - 001 (schema version 3 digits)
  - 0/1 (1 digit, FUNCTIONAL cookies disabled/enabled)
  - 0/1 (1 digit, ANALYTICS cookies disabled/enabled)
  - 0/1 (1 digit, MARKETING cookies disabled/enabled)
  - 0/1 (1 digit, UNKNOWN cookies disabled/enabled)
*/

var packPrefsVersion001 = function packPrefsVersion001(userPreferences) {
  var version = V001_SCHEMA_KEY;
  return [version, getBitValueFromBool(userPreferences.FUNCTIONAL), getBitValueFromBool(userPreferences.ANALYTICS), getBitValueFromBool(userPreferences.MARKETING), getBitValueFromBool(userPreferences.UNKNOWN)].join('');
};
var unpackPrefsVersion001 = function unpackPrefsVersion001(userPreferencesCookie) {
  if (userPreferencesCookie.length !== 7) {
    // Wrong length for version 001
    return;
  }
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, PreferenceCategory.StrictlyNecessary, true), PreferenceCategory.Functional, userPreferencesCookie[3] === '1'), PreferenceCategory.Analytics, userPreferencesCookie[4] === '1'), PreferenceCategory.Marketing, userPreferencesCookie[5] === '1'), PreferenceCategory.Unknown, userPreferencesCookie[6] === '1');
};

/* Schema Version 002 ***** DEPRECATED *****
---------------------------------------------------
  - Format 000000000000
  - 002 (schema version, 3 digits)
  - 0/1 (1 digit, FUNCTIONAL cookies - disabled/enabled)
  - 0/1 (1 digit, ANALYTICS cookies - disabled/enabled)
  - 0/1 (1 digit, MARKETING cookies - disabled/enabled)
  - 0/1 (1 digit, UNKNOWN cookies - disabled/enabled)
  - 0/1 (1 digit, consent records are unavailable from ConsentHub (logged out) - yes/no)
  - 0/1 (1 digit, consents saved while in GDPR locale (Europe) - yes/no)
  - 0/1 (1 digit, consents saved while in CPRA locale (CPRA will act as 'all USA' bucket) - yes/no)
  - 0/1 (1 digit, consents saved while in LGPD locale (Brazil) - yes/no)
  - 0/1 (1 digit, consents saved while in FADP locale (Switzerland) - yes/no)
*/
var packPrefsVersion002 = function packPrefsVersion002(userPreferences) {
  // As we want to slowly kill off any V002 preferences, if we're provided one for some reason
  // internally convert it to a V003 preference to drop contextual GeoIP details
  var version = V003_SCHEMA_KEY;
  var hasConsentedPreviously = userPreferences[ConsentPreferenceV2.ConsentedUnderGDPR] || userPreferences[ConsentPreferenceV2.ConsentedUnderCPRA] || userPreferences[ConsentPreferenceV2.ConsentedUnderLGPD] || userPreferences[ConsentPreferenceV2.ConsentedUnderFADP];
  var isDefaultConsentBit = !hasConsentedPreviously;
  // As we've broken out authentication insights/context from "ConsentDataUnavailable" bit in V3,
  // and it does not exist, we will backfill the value based on the previous bit state
  var existingConsentDataUnavailableBit = userPreferences[ContextualConsentProperty.ConsentDataUnavailable];
  var userIsAuthenticatedBit = !existingConsentDataUnavailableBit;
  return [version, getBitValueFromBool(userPreferences[PreferenceCategory.Functional]), getBitValueFromBool(userPreferences[PreferenceCategory.Analytics]), getBitValueFromBool(userPreferences[PreferenceCategory.Marketing]), getBitValueFromBool(userPreferences[PreferenceCategory.Unknown]), getBitValueFromBool(isDefaultConsentBit), getBitValueFromBool(userIsAuthenticatedBit), getBitValueFromBool(existingConsentDataUnavailableBit)].join('');
};
var unpackPrefsVersion002 = function unpackPrefsVersion002(userPreferencesCookie) {
  // If the provided V002 token has consented anywhere, we'll drop the contextual GeoIP bits and
  // convert it to a V003 schema
  var isADefaultConsentToken = checkIfTokenIsDefault(userPreferencesCookie);

  // Backfill the value based on the previous "ConsentDataUnavailable" bit state as we've broken this contextual info out
  var consentDataUnavailable = userPreferencesCookie[7] === '1';
  var userIsDeemedAuthenticated = !consentDataUnavailable;
  return _objectSpread(_objectSpread({}, unpackPrefsVersion003(userPreferencesCookie)), {}, _defineProperty(_defineProperty(_defineProperty({}, ContextualConsentProperty.ConsentsAreDefault, !!isADefaultConsentToken), ContextualConsentProperty.UserIsAuthenticated, userIsDeemedAuthenticated), ContextualConsentProperty.ConsentDataUnavailable, consentDataUnavailable));
};

/* Schema Version 003
---------------------------------------------------
  - Format 0000000000
  - 003 (schema version, 3 digits)
  - 0/1 (1 digit, FUNCTIONAL cookies - disabled/enabled)
  - 0/1 (1 digit, ANALYTICS cookies - disabled/enabled)
  - 0/1 (1 digit, MARKETING cookies - disabled/enabled)
  - 0/1 (1 digit, UNKNOWN cookies - disabled/enabled)
  - 0/1 (1 digit, Consent token in a default state, user has yet to consent) - no/yes)
  - 0/1 (1 digit, User is authenticated, did not 401 during save) - no/yes)
  - 0/1 (1 digit, ConsentHub records are unavailable (CH is down, errored while saving, etc) - no/yes)
*/
var packPrefsVersion003 = function packPrefsVersion003(userPreferences) {
  var version = V003_SCHEMA_KEY;
  return [version, getBitValueFromBool(userPreferences[PreferenceCategory.Functional]), getBitValueFromBool(userPreferences[PreferenceCategory.Analytics]), getBitValueFromBool(userPreferences[PreferenceCategory.Marketing]), getBitValueFromBool(userPreferences[PreferenceCategory.Unknown]), getBitValueFromBool(userPreferences[ContextualConsentProperty.ConsentsAreDefault]), getBitValueFromBool(userPreferences[ContextualConsentProperty.UserIsAuthenticated]), getBitValueFromBool(userPreferences[ContextualConsentProperty.ConsentDataUnavailable])].join('');
};
var unpackPrefsVersion003 = function unpackPrefsVersion003(userPreferencesCookie) {
  return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, PreferenceCategory.StrictlyNecessary, true), PreferenceCategory.Functional, userPreferencesCookie[3] === '1'), PreferenceCategory.Analytics, userPreferencesCookie[4] === '1'), PreferenceCategory.Marketing, userPreferencesCookie[5] === '1'), PreferenceCategory.Unknown, userPreferencesCookie[6] === '1'), ContextualConsentProperty.ConsentsAreDefault, userPreferencesCookie[7] === '1'), ContextualConsentProperty.UserIsAuthenticated, userPreferencesCookie[8] === '1'), ContextualConsentProperty.ConsentDataUnavailable, userPreferencesCookie[9] === '1');
};
var V002SpecificBitKeys = [ContextualConsentProperty.ConsentDataUnavailable, ConsentPreferenceV2.ConsentedUnderGDPR, ConsentPreferenceV2.ConsentedUnderCPRA, ConsentPreferenceV2.ConsentedUnderLGPD, ConsentPreferenceV2.ConsentedUnderFADP];
var V003SpecificBitKeys = [ContextualConsentProperty.ConsentsAreDefault, ContextualConsentProperty.UserIsAuthenticated, ContextualConsentProperty.ConsentDataUnavailable];
export function packUserPreferences(userPreferences) {
  if (getBscGlobalState().localConsentMode) {
    return packPrefsVersion001(userPreferences);
  }
  var isV002 = V002SpecificBitKeys.every(function (key) {
    return key in userPreferences;
  });
  if (isV002) {
    // As V002 is deprecated, if we've been provided V002 set of "legacy" prefs, internally
    // convert them to V003 to drop the unnecessary contextual details
    return packPrefsVersion002(userPreferences);
  }
  var isV003 = V003SpecificBitKeys.every(function (key) {
    return key in userPreferences;
  });
  if (isV003) {
    return packPrefsVersion003(userPreferences);
  }
  // Fallback to V001
  return packPrefsVersion001(userPreferences);
}
export function unpackUserPreferencesCookie(userPreferencesCookie) {
  if (getBscGlobalState().localConsentMode) {
    if (userPreferencesCookie.length < 3) {
      // Wrong format, no version
      return;
    }
    var version = userPreferencesCookie[0] + userPreferencesCookie[1] + userPreferencesCookie[2];
    if (version === V001_SCHEMA_KEY) {
      return unpackPrefsVersion001(userPreferencesCookie);
    }
    return;
  }
  var _checkTokenVersionVal = checkTokenVersionValidity(userPreferencesCookie),
    isValid = _checkTokenVersionVal.isValid,
    isV001 = _checkTokenVersionVal.isV001,
    isV002 = _checkTokenVersionVal.isV002,
    isV003 = _checkTokenVersionVal.isV003;
  if (!isValid) {
    // We support version 001, 002, 003 only
    return;
  }
  if (isV001) {
    return unpackPrefsVersion001(userPreferencesCookie);
  }
  if (isV002) {
    // Deprecated, so this will unpack to 003 to drop the contextual GeoIP bits
    return unpackPrefsVersion002(userPreferencesCookie);
  }
  if (isV003) {
    return unpackPrefsVersion003(userPreferencesCookie);
  }
}
export var initialPreferencesAreMissing = function initialPreferencesAreMissing(initialPreferences) {
  if (!initialPreferences) {
    return true;
  }
  var prefExistButAreEmpty = Object.keys(initialPreferences).length === 0;
  return prefExistButAreEmpty;
};

// The goal here is to transform the initialPreferences into a format we can consume
// and store values in the correct cookies if necessary
// If at anytime, something fails during the process, set isPrefetchedPreferenceInternalState
// to true so we can refetch
export var initializePreferencesData = function initializePreferencesData(initialPreferences) {
  if (initialPreferences !== PrefetchedPreferenceState.INTERNAL) {
    if (initialPreferencesAreMissing(initialPreferences)) {
      // Failsafe handling: Consumer failed to provide data even though they have prefetched, so we need to fetch again
      // This may occur if a consumer's prefetch requests fails, or if they fail to implement the system correctly
      return {
        prefs: undefined,
        isPrefetchedPrefsInternalState: true
      };
    }
    // If initialPreferences is not INTERNAL on first render, we have been provided a pre-fetched
    // value, which means we don't need to make a fetch to storage or ConsentHub to get it.
    // If that preference is defined, we persist it to our local cookie set

    // If the data is a ConsentHubResponse/PrefetchedConsentCookieData, we'll need to transform it properly before consumption
    var consentToken = initialPreferences && 'consentToken' in initialPreferences && initialPreferences.consentToken;
    if (consentToken) {
      // We already have a consent cookie that was passed in, let's not override it by setting it again
      var unpackedExistingPrefs = unpackUserPreferencesCookie(consentToken);
      return {
        prefs: unpackedExistingPrefs,
        isPrefetchedPrefsInternalState: !Boolean(unpackedExistingPrefs)
      };
    }

    // This type guard protects the below casting of ConsentHubResponse ensuring we have the correct shape
    if (initialPreferences && 'consents' in initialPreferences) {
      var serializedPrefetchedConsentToken = serializeConsents(initialPreferences);
      var unpackedPrefetchedPrefs = unpackUserPreferencesCookie(serializedPrefetchedConsentToken);
      return {
        prefs: unpackedPrefetchedPrefs,
        isPrefetchedPrefsInternalState: !Boolean(unpackedPrefetchedPrefs)
      };
    }
  }

  // If we've fallen into this, EMPTY implies we have no data at all
  // INTERNAL implies we still need to fetch new data, so trigger a refetch
  if (initialPreferences === PrefetchedPreferenceState.INTERNAL) {
    return {
      prefs: undefined,
      isPrefetchedPrefsInternalState: true
    };
  }
  return {
    prefs: undefined,
    isPrefetchedPrefsInternalState: false
  };
};