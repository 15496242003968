import { stringify } from 'qs';
import { createResource, RouterContext } from 'react-resource-router';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { StatsigFeatureKeys } from '@atlassian/bitbucket-features';

import { NavigationData } from 'src/types/navigation';
import urls from 'src/urls/projects';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

type ProjectNavigation = NavigationData & {
  project: BB.Project;
};

export const projectNavigationResource = createResource({
  type: 'PROJECT_NAVIGATION',
  getKey: ({ match }: RouterContext) => {
    const { workspaceSlug, projectKey } = match.params;
    return `project:${workspaceSlug}/${projectKey}`;
  },
  maxAge: 1000 * 60 * 10, // 10 minute
  getData: async ({ match }): Promise<ProjectNavigation | null> => {
    const { workspaceSlug, projectKey } = match.params;
    if (!workspaceSlug || !projectKey) {
      throw new Error('workspace and project are required');
    }

    const isNavigationResourceSwitchEnabled = FeatureGates.checkGate(
      StatsigFeatureKeys.navigationResourceSwitch
    );

    const url = urls.api.internal.navigation(workspaceSlug, projectKey);
    const query = stringify({ fields: '-menuItems' });
    const response = await fetch(
      authRequest(
        `${url}${isNavigationResourceSwitchEnabled ? '' : `?${query}}`}`,
        {
          headers: jsonHeaders,
        }
      )
    );

    if (!response.ok) {
      throw new Error(
        `Error fetching project navigation data (${response.status}): ${response.statusText}`
      );
    }

    return response.json();
  },
});
