import _extends from "@babel/runtime/helpers/extends";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { jsx } from '@emotion/react';
import { getStyleProps } from '../utils';
export var placeholderCSS = function placeholderCSS(_ref) {
  var isDisabled = _ref.isDisabled;
  return {
    label: 'placeholder',
    gridArea: '1 / 1 / 2 / 3',
    margin: "0 ".concat("var(--ds-space-025, 2px)"),
    color: isDisabled ? "var(--ds-text-disabled, #091E424F)" : "var(--ds-text-subtlest, #626F86)"
  };
};
var Placeholder = function Placeholder(props) {
  var children = props.children,
    innerProps = props.innerProps;
  return jsx("div", _extends({}, getStyleProps(props, 'placeholder', {
    placeholder: true
  }), innerProps), children);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Placeholder;