import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { usePlatformLeafEventHandler } from '@atlaskit/analytics-next/usePlatformLeafEventHandler';
import noop from '@atlaskit/ds-lib/noop';
import Heading from '@atlaskit/heading';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { DEFAULT_APPEARANCE } from './constants';
import Actions from './flag-actions';
import { useFlagGroup } from './flag-group';
import { DismissButton, Expander } from './internal';
import { flagBackgroundColor, flagIconColor, flagTextColor, flagTextColorToken } from './theme';
var CSS_VAR_ICON_COLOR = '--flag-icon-color';

// For cases where a single word is longer than the container (e.g. filenames)
var overflowWrapStyles = xcss({
  overflowWrap: 'anywhere'
});
var descriptionStyles = css({
  maxHeight: 100,
  // height is defined as 5 lines maximum by design
  overflow: 'auto',
  overflowWrap: 'anywhere' // For cases where a single word is longer than the container (e.g. filenames)
});
var iconWrapperStyles = css({
  display: 'flex',
  alignItems: 'start',
  flexShrink: 0,
  color: "var(".concat(CSS_VAR_ICON_COLOR, ")")
});
var flagStyles = xcss({
  boxShadow: 'elevation.shadow.overlay',
  borderRadius: 'border.radius.100',
  overflow: 'hidden',
  zIndex: 'flag',
  width: '100%',
  transition: 'background-color 200ms'
});
var flagWrapperStyles = css({
  width: '100%'
});
var analyticsAttributes = {
  componentName: 'flag',
  packageName: "@atlaskit/flag",
  packageVersion: "15.9.2"
};
var transitionStyles = css({
  flexGrow: 1,
  transition: "gap 0.3s"
});

/**
 * __Flag__
 *
 * A flag is used for confirmations, alerts, and acknowledgments that require minimal user interaction,
 * often displayed using a flag group.
 *
 * - [Examples](https://atlassian.design/components/flag/examples)
 * - [Code](https://atlassian.design/components/flag/code)
 * - [Usage](https://atlassian.design/components/flag/usage)
 */
var Flag = function Flag(props) {
  var _props$actions = props.actions,
    actions = _props$actions === void 0 ? [] : _props$actions,
    _props$appearance = props.appearance,
    appearance = _props$appearance === void 0 ? DEFAULT_APPEARANCE : _props$appearance,
    icon = props.icon,
    title = props.title,
    description = props.description,
    linkComponent = props.linkComponent,
    onMouseOver = props.onMouseOver,
    _props$onFocus = props.onFocus,
    onFocus = _props$onFocus === void 0 ? noop : _props$onFocus,
    onMouseOut = props.onMouseOut,
    _props$onBlur = props.onBlur,
    onBlur = _props$onBlur === void 0 ? noop : _props$onBlur,
    _props$onDismissed = props.onDismissed,
    onDismissedProp = _props$onDismissed === void 0 ? noop : _props$onDismissed,
    testId = props.testId,
    id = props.id,
    analyticsContext = props.analyticsContext,
    delayAnnouncement = props.delayAnnouncement,
    _props$headingLevel = props.headingLevel,
    headingLevel = _props$headingLevel === void 0 ? 2 : _props$headingLevel;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isDelayToAnnounce = _useState2[0],
    setIsDelayToAnnounce = _useState2[1];
  var _useFlagGroup = useFlagGroup(),
    onDismissedFromFlagGroup = _useFlagGroup.onDismissed,
    isDismissAllowed = _useFlagGroup.isDismissAllowed;
  var onDismissed = useCallback(function (id, analyticsEvent) {
    onDismissedProp(id, analyticsEvent);
    onDismissedFromFlagGroup(id, analyticsEvent);
  }, [onDismissedProp, onDismissedFromFlagGroup]);
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    isExpanded = _useState4[0],
    setIsExpanded = _useState4[1];
  var onDismissedAnalytics = usePlatformLeafEventHandler(_objectSpread({
    fn: onDismissed,
    action: 'dismissed',
    analyticsData: analyticsContext
  }, analyticsAttributes));
  var isBold = appearance !== DEFAULT_APPEARANCE;
  var toggleExpand = useCallback(function () {
    setIsExpanded(function (previous) {
      return !previous;
    });
  }, []);
  var buttonActionCallback = useCallback(function () {
    if (isDismissAllowed) {
      onDismissedAnalytics(id);
    }
  }, [onDismissedAnalytics, id, isDismissAllowed]);
  useEffect(function () {
    // If buttons are removed as a prop, update isExpanded to be false
    if (isBold && isExpanded && !description && !actions.length) {
      setIsExpanded(false);
    }
  }, [actions.length, description, isBold, isExpanded]);
  useEffect(function () {
    if (!delayAnnouncement) {
      return;
    }
    setTimeout(function () {
      setIsDelayToAnnounce(true);
    }, delayAnnouncement);
  }, [delayAnnouncement]);
  var onFocusAnalytics = usePlatformLeafEventHandler(_objectSpread({
    fn: onFocus,
    action: 'focused',
    analyticsData: analyticsContext
  }, analyticsAttributes));
  var onBlurAnalytics = usePlatformLeafEventHandler(_objectSpread({
    fn: onBlur,
    action: 'blurred',
    analyticsData: analyticsContext
  }, analyticsAttributes));
  var autoDismissProps = {
    onMouseOver: onMouseOver,
    onFocus: onFocusAnalytics,
    onMouseOut: onMouseOut,
    onBlur: onBlurAnalytics
  };
  var textColor = flagTextColor[appearance];
  var iconColor = flagIconColor[appearance];
  var isDismissable = isBold || isDismissAllowed;
  var shouldRenderGap = !isBold && (description || actions.length) || isExpanded;
  // when delayAnnouncement is available we will use a hidden content for announcement
  var delayedAnnouncement = delayAnnouncement ? jsx(VisuallyHidden, null, title, description) : undefined;
  return jsx("div", _extends({
    role: "alert",
    css: flagWrapperStyles,
    "data-testid": testId
  }, autoDismissProps), jsx(Box, {
    backgroundColor: flagBackgroundColor[appearance],
    padding: "space.200",
    xcss: flagStyles
  }, jsx(Inline, {
    alignBlock: "stretch",
    space: "space.200"
  }, jsx("div", {
    css: iconWrapperStyles
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    ,
    style: _defineProperty({}, CSS_VAR_ICON_COLOR, iconColor)
  }, icon), jsx("span", {
    css: transitionStyles
  }, jsx(Stack, {
    space: shouldRenderGap ? 'space.100' : 'space.0' // Gap exists even when not expanded due to Expander internals always being in the DOM
  }, isDelayToAnnounce && delayedAnnouncement, jsx(Inline, {
    alignBlock: "stretch",
    space: "space.100",
    spread: "space-between"
  }, jsx(Box, {
    paddingBlockStart: "space.050",
    paddingBlockEnd: "space.025",
    xcss: overflowWrapStyles
  }, jsx(Heading, {
    as: "h".concat(headingLevel),
    size: "xsmall",
    color: textColor
  }, jsx("span", {
    /* if isDelayToAnnounce is true, we will hide the content form screen reader to avoid duplicate announcement */
    "aria-hidden": isDelayToAnnounce
  }, title))), isDismissable ? !(isBold && !description && !actions.length) && jsx(DismissButton, {
    testId: testId,
    appearance: appearance,
    isBold: isBold,
    isExpanded: isExpanded,
    onClick: isBold ? toggleExpand : buttonActionCallback
  }) : null), jsx(Expander, {
    isExpanded: !isBold || isExpanded,
    testId: testId
  }, description && jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    style: {
      color: flagTextColorToken[appearance]
    },
    css: descriptionStyles,
    "data-testid": testId && "".concat(testId, "-description")
    /* if isDelayToAnnounce is true, we will hide the content form screen reader to avoid duplicate announcement */,
    "aria-hidden": isDelayToAnnounce
  }, description), jsx(Actions, {
    actions: actions,
    appearance: appearance,
    linkComponent: linkComponent,
    testId: testId
  })))))));
};
export default Flag;