import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'frontbucket.repository.migration.title',
    description: 'Repo maintenance warning message title',
    defaultMessage: 'System maintenance in progress',
  },
  description: {
    id: 'frontbucket.repository.migration.description',
    description: 'Repo maintenance warning message description',
    defaultMessage:
      'This repository is temporarily unavailable due to required system maintenance. Wait a few minutes and try again.\n',
  },
});
