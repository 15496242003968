import { useCallback, useEffect, useRef } from 'react';
import { bindAll } from 'bind-event-listener';
import { ESCAPE } from '../utils/keycodes';
/**
 * @deprecated Please use useCloseOnEscapePress from '@atlaskit/layering' instead.
 * Calls back when the escape key is pressed.
 * To be used exclusively for closing layered components.
 * Use the `isDisabled` argument to ignore closing events.
 *
 * ```js
 * useCloseOnEscapePress({
 *   onClose: () => {},
 *   isDisabled: false,
 * });
 * ```
 */
export default function useCloseOnEscapePress(_ref) {
  var onClose = _ref.onClose,
    isDisabled = _ref.isDisabled;
  var escapePressed = useRef(false);
  var onKeyDown = useCallback(function (e) {
    if (isDisabled || escapePressed.current || e.key !== ESCAPE) {
      // We're either already handling the key down event or it's not escape.
      // Bail early!
      return;
    }
    escapePressed.current = true;
    onClose(e);
  }, [onClose, isDisabled]);
  var onKeyUp = useCallback(function () {
    escapePressed.current = false;
  }, []);
  useEffect(function () {
    return bindAll(document, [{
      type: 'keydown',
      listener: onKeyDown
    }, {
      type: 'keyup',
      listener: onKeyUp
    }], {
      capture: false
    });
  }, [onKeyDown, onKeyUp]);
}