import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { css, jsx } from '@emotion/react';
import LegacySelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import CrossIcon from '@atlaskit/icon/utility/cross';
import { fg } from '@atlaskit/platform-feature-flags';
import { Inline, xcss } from '@atlaskit/primitives';
import { getStyleProps } from '../utils';
export var multiValueCSS = function multiValueCSS(_ref) {
  var isDisabled = _ref.isDisabled,
    isFocused = _ref.isFocused;
  var backgroundColor;
  var color;
  if (isDisabled) {
    // Use the basic neutral background so it is slightly separate from the
    // field's background
    backgroundColor = "var(--ds-background-neutral, #091E420F)";
    color = "var(--ds-text-disabled, #091E424F)";
  } else if (isFocused) {
    backgroundColor = "var(--ds-background-selected, #E9F2FF)";
    color = "var(--ds-text-selected, hsl(0, 0%, 20%))";
  } else {
    backgroundColor = fg('platform-component-visual-refresh') ? "var(--ds-background-neutral-subtle-hovered, #091E420F)" : "var(--ds-background-neutral, #091E420F)";
    color = "var(--ds-text, hsl(0, 0%, 20%))";
  }
  return _objectSpread({
    label: 'multiValue',
    display: 'flex',
    minWidth: 0,
    // resolves flex/text-overflow bug
    margin: "var(--ds-space-025, 2px)",
    borderRadius: "var(--ds-border-radius-050, 2px)",
    backgroundColor: backgroundColor,
    boxShadow: isFocused ? "0 0 0 2px ".concat("var(--ds-surface, transparent)", ", 0 0 0 4px ", "var(--ds-border-focused, transparent)") : 'none',
    maxWidth: '100%',
    '@media screen and (-ms-high-contrast: active)': {
      border: isFocused ? '1px solid transparent' : 'none'
    },
    color: color
  }, fg('platform-component-visual-refresh') && {
    borderRadius: "var(--ds-border-radius-100, 4px)",
    // Hardcode this color for visual refresh as there is no token color yet
    borderColor: '#B7B9BE',
    borderWidth: "var(--ds-border-width, 1px)",
    borderStyle: 'solid',
    backgroundColor: "var(--ds-background-input, #FFFFFF)"
  });
};
export var multiValueLabelCSS = function multiValueLabelCSS(_ref2) {
  var cropWithEllipsis = _ref2.cropWithEllipsis,
    isDisabled = _ref2.isDisabled;
  return _objectSpread({
    overflow: 'hidden',
    textOverflow: cropWithEllipsis || cropWithEllipsis === undefined ? 'ellipsis' : undefined,
    whiteSpace: 'nowrap',
    borderRadius: "var(--ds-border-radius-050, 2px)",
    // eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
    fontSize: '85%',
    font: "var(--ds-font-body-UNSAFE_small, normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
    padding: "var(--ds-space-025, 2px)",
    color: isDisabled ? "var(--ds-text-disabled, #091E424F)" : 'inherit',
    paddingLeft: "var(--ds-space-075, 6px)"
  }, fg('platform-component-visual-refresh') && {
    font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "var(--ds-space-050, 4px)"
  });
};
export var multiValueRemoveCSS = function multiValueRemoveCSS(_ref3) {
  var isFocused = _ref3.isFocused;
  return _objectSpread({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: isFocused ? "var(--ds-UNSAFE-transparent, transparent)" : undefined,
    fill: isFocused ? "var(--ds-text-selected, #000)" : "var(--ds-text, #000)",
    paddingLeft: "var(--ds-space-025, 2px)",
    paddingRight: "var(--ds-space-025, 2px)",
    borderRadius: '0px 2px 2px 0px',
    // DSP-6470 we should style like Tag once we have the :has selector
    ':hover': {
      backgroundColor: "var(--ds-background-danger-hovered, #FFD5D2)",
      fill: "var(--ds-text-danger, #000)"
    },
    ':active': {
      backgroundColor: "var(--ds-background-danger-pressed, #FD9891)",
      fill: "var(--ds-text-danger, #000)"
    }
  }, fg('platform-component-visual-refresh') && {
    backgroundColor: "var(--ds-background-neutral-subtle, #00000000)",
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    appearance: 'none',
    borderRadius: "var(--ds-border-radius, 4px)",
    color: "var(--ds-text, #172B4D)",
    padding: "var(--ds-space-025, 2px)",
    marginRight: "var(--ds-space-025, 2px)",
    ':focus-visible': {
      outlineOffset: "var(--ds-space-negative-025, -2px)"
    },
    ':hover': {
      backgroundColor: "var(--ds-background-neutral-subtle-hovered, #091E420F)"
    },
    ':active': {
      backgroundColor: "var(--ds-background-neutral-subtle-pressed, #091E4224)"
    }
  });
};
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var MultiValueGeneric = function MultiValueGeneric(_ref4) {
  var children = _ref4.children,
    innerProps = _ref4.innerProps;
  return jsx("div", innerProps, children);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var MultiValueContainer = MultiValueGeneric;

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var MultiValueLabel = MultiValueGeneric;
var disabledStyles = css({
  display: 'none'
});
var enabledStyles = css({
  display: 'inherit'
});
var iconWrapperStyles = xcss({
  padding: 'space.025'
});
var renderIcon = function renderIcon() {
  // eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
  if (fg('platform-component-visual-refresh')) {
    return jsx(CrossIcon, {
      label: "Clear",
      color: "currentColor"
    });
  }

  // eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
  if (fg('platform-visual-refresh-icons-legacy-facade')) {
    return jsx(Inline, {
      xcss: iconWrapperStyles
    }, jsx(CrossIcon, {
      label: "Clear",
      color: "currentColor"
    }));
  }
  return (
    // eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
    jsx(LegacySelectClearIcon, {
      label: "Clear",
      primaryColor: "transparent",
      size: "small",
      secondaryColor: "inherit"
    })
  );
};
export function MultiValueRemove(_ref5) {
  var isDisabled = _ref5.isDisabled,
    innerProps = _ref5.innerProps;
  return jsx("div", _extends({
    role: "button"
  }, innerProps), jsx("div", {
    css: isDisabled ? disabledStyles : enabledStyles,
    "data-testid": isDisabled ? 'hide-clear-icon' : 'show-clear-icon'
  }, renderIcon()));
}
var MultiValue = function MultiValue(props) {
  var children = props.children,
    components = props.components,
    data = props.data,
    innerProps = props.innerProps,
    isDisabled = props.isDisabled,
    removeProps = props.removeProps,
    selectProps = props.selectProps;
  var Container = components.Container,
    Label = components.Label,
    Remove = components.Remove;
  return jsx(Container, {
    data: data,
    innerProps: _objectSpread(_objectSpread({}, getStyleProps(props, 'multiValue', {
      'multi-value': true,
      'multi-value--is-disabled': isDisabled
    })), innerProps),
    selectProps: selectProps
  }, jsx(Label, {
    data: data,
    innerProps: _objectSpread({}, getStyleProps(props, 'multiValueLabel', {
      'multi-value__label': true
    })),
    selectProps: selectProps
  }, children), jsx(Remove, {
    data: data,
    innerProps: _objectSpread(_objectSpread({}, getStyleProps(props, 'multiValueRemove', {
      'multi-value__remove': true
    })), {}, {
      'aria-label': "Remove ".concat(children || 'option')
    }, removeProps),
    isDisabled: isDisabled,
    selectProps: selectProps
  }));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default MultiValue;