/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import CheckCircleIcon from '@atlaskit/icon/core/migration/success--check-circle';
import { G300 } from '@atlaskit/theme/colors';
export default (function (_ref) {
  var children = _ref.children;
  return jsx("section", {
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css: css({
      marginLeft: "var(--ds-space-500, 40px)"
    })
  }, jsx("div", {
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    css: css({
      position: 'absolute',
      top: "var(--ds-space-250, 20px)",
      left: "var(--ds-space-300, 24px)"
    })
  }, jsx(CheckCircleIcon, {
    spacing: "spacious",
    label: "",
    "aria-hidden": true,
    color: "var(--ds-icon-success, ".concat(G300, ")"),
    LEGACY_margin: "4px 0 0 0"
  })), children);
});