/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { call, put, select } from 'redux-saga/effects';

import { PullRequest, User } from 'src/components/types';
import { showFlag } from 'src/redux/flags';
import {
  getCurrentPullRequest,
  getCurrentPullRequestUrlPieces,
} from 'src/redux/pull-request/selectors';
import { getCurrentUser } from 'src/selectors/user-selectors';
import { Action } from 'src/types/state';
import { publishTrackEvent } from 'src/utils/analytics/publish';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import {
  UPDATE_DESCRIPTION,
  UPDATE_TITLE,
  REMOVE_REVIEWER,
  ADD_REVIEWER,
  verifyRepoMember,
} from '../actions';
import { updatePullRequest } from '../actions/update-pull-request';
import urls from '../urls';

import { PULL_REQUEST_SCREEN_NAME } from './analytics-sagas';
import { getErrorMessage } from './utils/get-error-message';

export const messages = {
  prTitleFlagTitleError: {
    id: 'frontbucket.repository.pullrequest.flags.updatePrTitle.titleError',
    description:
      'Text for title of flag indicating updating PR title has failed',
    defaultMessage: 'Something went wrong',
  },
  prTitleFlagDescriptionError: {
    id: 'frontbucket.repository.pullrequest.flags.updatePrTitle.descriptionError',
    description:
      'Text for description of flag indicating updating PR title has failed',
    defaultMessage:
      'We couldn’t update the title. Wait a few minutes and then try again.',
  },
  prDescriptionFlagTitleError: {
    id: 'frontbucket.repository.pullrequest.flags.updatePrDesc.titleError',
    description:
      'Text for title of flag indicating updating PR description has failed',
    defaultMessage: 'Something went wrong',
  },
  prDescriptionFlagDescriptionError: {
    id: 'frontbucket.repository.pullrequest.flags.updatePrDesc.descriptionError',
    description:
      'Text for description of flag indicating updating PR description has failed',
    defaultMessage:
      'We couldn’t update the description. Wait a few minutes and then try again.',
  },
  prAddReviewerFlagDescriptionError: {
    id: 'frontbucket.repository.pullrequest.flags.addReviewer.descriptionError',
    description:
      'Text for description of flag indicating adding a reviewer has failed',
    defaultMessage:
      'We couldn’t add a reviewer. Wait a few minutes and then try again.',
  },
  prRemoveReviewerFlagTitleError: {
    id: 'frontbucket.repository.pullrequest.flags.removeReviewer.titleError',
    description:
      'Text for title of flag indicating removing a reviewer has failed',
    defaultMessage: 'Something went wrong',
  },
  prAddReviewerFlagTitleError: {
    id: 'frontbucket.repository.pullrequest.flags.addReviewer.titleError',
    description:
      'Text for title of flag indicating removing a reviewer has failed',
    defaultMessage: 'Something went wrong',
  },
  prDescriptionFlagTitleSuccess: {
    id: 'frontbucket.repository.pullrequest.flags.updatePrDesc.titleSuccess',
    description:
      'Text for title of flag indicating updating PR description has succeeded',
    defaultMessage: 'Success',
  },
};

const errorDescriptionFlag = showFlag({
  id: UPDATE_DESCRIPTION.ERROR,
  iconType: 'error',
  title: { msg: messages.prDescriptionFlagTitleError },
  description: { msg: messages.prDescriptionFlagDescriptionError },
  autoDismiss: true,
});

const errorTitleFlag = showFlag({
  id: UPDATE_TITLE.ERROR,
  iconType: 'error',
  title: { msg: messages.prTitleFlagTitleError },
  description: { msg: messages.prTitleFlagDescriptionError },
  autoDismiss: true,
});

const errorAddReviewerFlag = showFlag({
  id: ADD_REVIEWER.ERROR,
  iconType: 'error',
  title: { msg: messages.prAddReviewerFlagTitleError },
  description: { msg: messages.prAddReviewerFlagDescriptionError },
  autoDismiss: true,
});

export function* updatePrDescriptionSaga(action: Action) {
  const newDescription = action.payload.description;
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  const url = urls.api.v20.pullRequest(owner, slug, id);
  const updateRequest = authRequest(url, {
    method: 'PUT',
    headers: jsonHeaders,
    body: JSON.stringify({
      description: newDescription,
    }),
  });

  try {
    const response: Response = yield call(fetch, updateRequest);
    if (!response.ok) {
      const message: string = yield call(getErrorMessage, response.clone());
      yield put({
        type: UPDATE_DESCRIPTION.ERROR,
        payload: { error: message },
      });
      yield put(errorDescriptionFlag);
    } else {
      const respPr: PullRequest = yield response.json();
      // merge current and updated PR from resp
      const currentPr: PullRequest = yield select(getCurrentPullRequest);
      const currentUser: User = yield select(getCurrentUser);
      const updatedPr = {
        ...currentPr,
        description: respPr.description,
        rendered: respPr.rendered,
        updated_on: respPr.updated_on,
      };

      yield put(
        updatePullRequest({
          type: UPDATE_DESCRIPTION.SUCCESS,
          pullRequest: updatedPr,
          currentUser,
        })
      );

      publishTrackEvent({
        action: 'editedSuccess',
        actionSubject: 'prDescInlineEdit',
        actionSubjectId: `${owner}/${slug}/${id}`,
        source: PULL_REQUEST_SCREEN_NAME,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: UPDATE_DESCRIPTION.ERROR,
      payload: { error: e.toString() },
    });
    yield put(errorDescriptionFlag);
  }
}

export function* updatePrTitleSaga(action: Action) {
  const newTitle = action.payload.title;
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  const url = urls.api.v20.pullRequest(owner, slug, id);
  // API requires title, current reviewers and closes_source_branch to be sent to prevent
  // clearing of this data after PUT
  const updateRequest = authRequest(url, {
    method: 'PUT',
    headers: jsonHeaders,
    body: JSON.stringify({
      title: newTitle,
    }),
  });

  try {
    const response: Response = yield call(fetch, updateRequest);
    if (!response.ok) {
      const message: string = yield call(getErrorMessage, response.clone());
      yield put({
        type: UPDATE_TITLE.ERROR,
        payload: { error: message },
      });
      yield put(errorTitleFlag);
    } else {
      const respPr: PullRequest = yield response.json();
      // merge current and updated PR from resp
      const currentPr: PullRequest = yield select(getCurrentPullRequest);
      const currentUser: User = yield select(getCurrentUser);
      const updatedPr = {
        ...currentPr,
        rendered: respPr.rendered,
        updated_on: respPr.updated_on,
        title: respPr.title,
      };

      yield put(
        updatePullRequest({
          type: UPDATE_TITLE.SUCCESS,
          pullRequest: updatedPr,
          oldTitle: currentPr.title,
          currentUser,
        })
      );

      publishTrackEvent({
        action: 'editedSuccess',
        actionSubject: 'prTitleInlineEdit',
        actionSubjectId: `${owner}/${slug}/${id}`,
        source: PULL_REQUEST_SCREEN_NAME,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: UPDATE_TITLE.ERROR,
      payload: { error: e.toString() },
    });
    yield put(errorTitleFlag);
  }
}

export function* removeReviewerSaga(action: Action) {
  const {
    payload: { userAaid },
  } = action;

  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  const url = urls.api.internal.updateReviewer(owner, slug, id, userAaid);

  const deleteRequest = authRequest(url, {
    method: 'DELETE',
  });

  try {
    const response: Response = yield call(fetch, deleteRequest);
    if (!response.ok) {
      const message: string = yield call(getErrorMessage, response.clone());
      throw new Error(message);
    } else {
      const respPr: PullRequest = yield response.json();
      const currentPr: PullRequest = yield select(getCurrentPullRequest);
      const currentUser: User = yield select(getCurrentUser);
      const removedUser = currentPr.participants.find(
        participant => participant.user?.account_id === userAaid
      );

      const updatedPr = {
        ...currentPr,
        participants: respPr.participants,
      };

      yield put(
        updatePullRequest({
          type: REMOVE_REVIEWER.SUCCESS,
          pullRequest: updatedPr,
          userAaid,
          currentUser,
          removedUser: removedUser?.user || undefined,
        })
      );

      publishTrackEvent({
        action: 'removed',
        actionSubject: 'prReviewers',
        actionSubjectId: `${owner}/${slug}/${id}`,
        source: PULL_REQUEST_SCREEN_NAME,
        attributes: {
          draft: currentPr.draft,
        },
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: REMOVE_REVIEWER.ERROR,
      payload: { error: e },
    });
    yield put(
      showFlag({
        id: REMOVE_REVIEWER.ERROR,
        iconType: 'error',
        title: { msg: messages.prRemoveReviewerFlagTitleError },
        description: e.toString(),
        autoDismiss: true,
      })
    );
  }
}

export function* addReviewerSaga(action: Action) {
  const {
    payload: { userAaid },
  } = action;

  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  const url = urls.api.internal.updateReviewer(owner, slug, id, userAaid);

  const updateRequest = authRequest(url, {
    method: 'PUT',
  });

  try {
    const response: Response = yield call(fetch, updateRequest);
    if (!response.ok) {
      const message: string = yield call(getErrorMessage, response.clone());
      throw new Error(message);
    } else {
      const respPr: PullRequest = yield response.json();
      const currentPr: PullRequest = yield select(getCurrentPullRequest);
      const currentUser: User = yield select(getCurrentUser);

      const updatedPr = {
        ...currentPr,
        participants: respPr.participants,
      };

      yield put(
        updatePullRequest({
          type: ADD_REVIEWER.SUCCESS,
          pullRequest: updatedPr,
          userAaid,
          currentUser,
        })
      );

      publishTrackEvent({
        action: 'added',
        actionSubject: 'prReviewers',
        actionSubjectId: `${owner}/${slug}/${id}`,
        source: PULL_REQUEST_SCREEN_NAME,
        attributes: {
          draft: currentPr.draft,
        },
      });

      // Verify recently added reviewer has access
      yield put(verifyRepoMember(userAaid));
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: ADD_REVIEWER.ERROR,
      payload: { error: e, userAaid },
    });
    yield put(errorAddReviewerFlag);
  }
}
