import { stringify } from 'qs';
import { createResource } from 'react-resource-router';

import { Commit as CommitType } from 'src/components/types';
import { BBError } from 'src/redux/pull-request/api';
import urls from 'src/sections/repository/urls';
import authRequest from 'src/utils/fetch';

const fetchData = async (repoOwner: string, repoSlug: string, hash: string) => {
  const url = urls.api.v20.commit(repoOwner, repoSlug, hash);
  const query_params = {
    fields: '+*.rendered.*,+committer.*',
  };
  const query = stringify(query_params);
  const fullUrl = `${url}?${query}`;
  const resp = await fetch(authRequest(fullUrl));
  if (resp.ok) {
    return resp.json();
  } else {
    const error = new BBError(
      'error loading commit',
      'error-loading-commit',
      resp.status
    );
    throw error;
  }
};

export const loadCommitResource = createResource({
  type: 'loadCommit',
  getKey: ({ match }) => {
    return `loadCommit:${match.params.repositoryOwner}:${match.params.repositorySlug}:${match.params.ref}.`;
  },
  maxAge: 1000000,
  getData: async ({ match }) => {
    // TODO, check out src/redux/pull-request/resources/load-pull-request.ts
    // and try to pull the commit out of redux if we have it
    // https://softwareteams.atlassian.net/browse/COREX-5857
    const { repositoryOwner, repositorySlug, ref } = match.params;
    if (!repositoryOwner || !repositorySlug || !ref) {
      throw new Error('Could not fetch commit, URL params were not available');
    }
    const commit: CommitType = await fetchData(
      repositoryOwner,
      repositorySlug,
      ref
    );
    return commit;
  },
});
