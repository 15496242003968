import _extends from "@babel/runtime/helpers/extends";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useContext } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { prefersReducedMotion } from '@atlaskit/motion/accessibility';
import { easeOut } from '@atlaskit/motion/curves';
import { COLLAPSED_LEFT_SIDEBAR_WIDTH, DEFAULT_LEFT_SIDEBAR_FLYOUT_WIDTH, TRANSITION_DURATION, VAR_LEFT_SIDEBAR_FLYOUT } from '../../common/constants';
import { useIsSidebarDragging } from '../../common/hooks';
import { getPageLayoutSlotSelector } from '../../common/utils';
import { SidebarResizeContext, useSkipLink } from '../../controllers';
import SlotFocusRing from './internal/slot-focus-ring';

// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
var prefersReducedMotionStyles = css(prefersReducedMotion());
var mainStyles = css({
  minWidth: 0,
  flexGrow: 1,
  marginInlineStart: 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  transition: "margin-left ".concat(TRANSITION_DURATION, "ms ").concat(easeOut, " 0s")
});
var draggingStyles = css({
  // Make sure drag to resize remains snappy.
  transition: 'none'
});

/**
 * Adds a negative left margin to main,
 * which transitions at the same speed as the left sidebar's width increase.
 * This give an illusion that the flyout is appearing on top of the main content,
 * while main remains in place.
 */
var flyoutStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginInlineStart: "calc(-1 * var(--".concat(VAR_LEFT_SIDEBAR_FLYOUT, ", ").concat(DEFAULT_LEFT_SIDEBAR_FLYOUT_WIDTH, "px) + ").concat(COLLAPSED_LEFT_SIDEBAR_WIDTH, "px)")
});

/**
 * __Main__
 *
 * Provides a slot for main content within the PageLayout.
 *
 * - [Examples](https://atlassian.design/components/page-layout/examples)
 * - [Code](https://atlassian.design/components/page-layout/code)
 */
var Main = function Main(props) {
  var children = props.children,
    testId = props.testId,
    id = props.id,
    skipLinkTitle = props.skipLinkTitle;
  useSkipLink(id, skipLinkTitle);
  var isDragging = useIsSidebarDragging();
  var _useContext = useContext(SidebarResizeContext),
    _useContext$leftSideb = _useContext.leftSidebarState,
    isFlyoutOpen = _useContext$leftSideb.isFlyoutOpen,
    isFixed = _useContext$leftSideb.isFixed;
  return jsx(SlotFocusRing, null, function (_ref) {
    var className = _ref.className;
    return jsx("main", _extends({
      "data-testid": testId,
      css: [mainStyles, isDragging && draggingStyles, isFlyoutOpen && !isFixed && flyoutStyles, prefersReducedMotionStyles]
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
      ,
      className: className,
      id: id
    }, getPageLayoutSlotSelector('main')), children);
  });
};
export default Main;