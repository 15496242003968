import { stringify } from './utils';

export default {
  external: {
    support: 'https://support.atlassian.com/bitbucket-cloud/',
    suggestOrRequireChecksBeforeMerge:
      'https://confluence.atlassian.com/x/EhMQMw',
    accessKeys: 'https://confluence.atlassian.com/x/I4CNEQ',
    generateSshKey: 'https://confluence.atlassian.com/x/X4FmKw',
    statusPage: 'https://bitbucket.status.atlassian.com/',
    troubleshootSsh: 'https://confluence.atlassian.com/x/64Y1E',
    repositoryAccessToUsersAndGroupsInfo:
      'https://confluence.atlassian.com/x/9A0zDQ',
    mergeChecks:
      'https://support.atlassian.com/bitbucket-cloud/docs/suggest-or-require-checks-before-a-merge/',
    userPullRequestsRemoval:
      'https://community.atlassian.com/t5/Bitbucket-articles/Reminder-List-pull-requests-for-a-user-API-removal/ba-p/2935311',
  },
  ui: {
    createBranch: (
      issueKey?: string,
      issueType?: string,
      issueSummary?: string
    ): string => {
      const query = stringify({
        issueKey,
        issueType,
        issueSummary,
      });
      return `/branch/create${query}`;
    },
  },
};
