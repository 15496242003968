import _extends from "@babel/runtime/helpers/extends";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { useCallback } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import FocusLock from 'react-focus-lock';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import { usePlatformLeafEventHandler } from '@atlaskit/analytics-next';
import Blanket from '@atlaskit/blanket';
import noop from '@atlaskit/ds-lib/noop';
import { Layering } from '@atlaskit/layering';
import FadeIn from '@atlaskit/motion/fade-in';
import { fg } from '@atlaskit/platform-feature-flags';
import Portal from '@atlaskit/portal';
import { layers } from '@atlaskit/theme/constants';
import ModalDialog from './internal/components/modal-dialog';
import useModalStack from './internal/hooks/use-modal-stack';
import usePreventProgrammaticScroll from './internal/hooks/use-prevent-programmatic-scroll';
var fillScreenStyles = css({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  insetBlockStart: "var(--ds-space-0, 0)",
  insetInlineStart: "var(--ds-space-0, 0)",
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch'
});
var allowlistElements = function allowlistElements(element, callback) {
  var isAuiDialogAllowlisted = fg('platform_dst_allowlist-aui-dialog-for-ak-modal');
  // Allow focus to reach elements outside the modal:

  //  if those contain the data-atlas-extension attribute
  if (element.hasAttribute('data-atlas-extension')) {
    return false;
  }

  // if AUI dialog is allowListed and visible
  if (isAuiDialogAllowlisted && !!document.querySelector('.aui-blanket:not([hidden])')) {
    return false;
  }
  // allows to pass a callback function to allow elements be ignored by focus lock
  if (typeof callback === 'function') {
    return callback(element);
  }
  return true;
};

/**
 * __Modal wrapper__
 *
 * A modal wrapper displays content that requires user interaction, in a layer above the page.
 * This component is primary container for other modal components.
 *
 * - [Examples](https://atlassian.design/components/modal-dialog/examples)
 * - [Code](https://atlassian.design/components/modal-dialog/code)
 * - [Usage](https://atlassian.design/components/modal-dialog/usage)
 */
var ModalWrapper = function ModalWrapper(props) {
  var _props$autoFocus = props.autoFocus,
    autoFocus = _props$autoFocus === void 0 ? true : _props$autoFocus,
    focusLockAllowlist = props.focusLockAllowlist,
    _props$shouldCloseOnE = props.shouldCloseOnEscapePress,
    shouldCloseOnEscapePress = _props$shouldCloseOnE === void 0 ? true : _props$shouldCloseOnE,
    _props$shouldCloseOnO = props.shouldCloseOnOverlayClick,
    shouldCloseOnOverlayClick = _props$shouldCloseOnO === void 0 ? true : _props$shouldCloseOnO,
    _props$shouldScrollIn = props.shouldScrollInViewport,
    shouldScrollInViewport = _props$shouldScrollIn === void 0 ? false : _props$shouldScrollIn,
    _props$shouldReturnFo = props.shouldReturnFocus,
    shouldReturnFocus = _props$shouldReturnFo === void 0 ? true : _props$shouldReturnFo,
    stackIndexOverride = props.stackIndex,
    _props$onClose = props.onClose,
    onClose = _props$onClose === void 0 ? noop : _props$onClose,
    _props$onStackChange = props.onStackChange,
    onStackChange = _props$onStackChange === void 0 ? noop : _props$onStackChange,
    isBlanketHidden = props.isBlanketHidden,
    children = props.children,
    height = props.height,
    width = props.width,
    onCloseComplete = props.onCloseComplete,
    onOpenComplete = props.onOpenComplete,
    label = props.label,
    testId = props.testId;
  var calculatedStackIndex = useModalStack({
    onStackChange: onStackChange
  });
  var stackIndex = stackIndexOverride || calculatedStackIndex;
  var isForeground = stackIndex === 0;

  // When a user supplies a ref to focus we skip auto focus via react-focus-lock
  var autoFocusLock = typeof autoFocus === 'boolean' ? autoFocus : false;
  var onCloseHandler = usePlatformLeafEventHandler({
    fn: onClose,
    action: 'closed',
    componentName: 'modalDialog',
    packageName: "@atlaskit/modal-dialog",
    packageVersion: "12.20.3"
  });
  var onBlanketClicked = useCallback(function (e) {
    if (shouldCloseOnOverlayClick) {
      onCloseHandler(e);
    }
  }, [shouldCloseOnOverlayClick, onCloseHandler]);

  // This ensures to prevent additional re-renders while nothing is passed to focusLockAllowlist explicitly.
  var allowListCallback = useCallback(function (element) {
    return allowlistElements(element, focusLockAllowlist);
  }, [focusLockAllowlist]);
  usePreventProgrammaticScroll();
  var modalDialogWithBlanket = jsx(Blanket, {
    isTinted: !isBlanketHidden,
    onBlanketClicked: onBlanketClicked,
    testId: testId && "".concat(testId, "--blanket")
  }, jsx(ModalDialog, {
    testId: testId,
    label: label,
    autoFocus: autoFocus,
    stackIndex: stackIndex,
    onClose: onCloseHandler,
    shouldCloseOnEscapePress: shouldCloseOnEscapePress && isForeground,
    shouldScrollInViewport: shouldScrollInViewport,
    height: height,
    width: width,
    onCloseComplete: onCloseComplete,
    onOpenComplete: onOpenComplete
  }, children));
  var returnFocus = true;
  var onDeactivation;
  if ('boolean' === typeof shouldReturnFocus) {
    returnFocus = shouldReturnFocus;
  } else {
    onDeactivation = function onDeactivation() {
      window.setTimeout(function () {
        var _shouldReturnFocus$cu;
        (_shouldReturnFocus$cu = shouldReturnFocus.current) === null || _shouldReturnFocus$cu === void 0 || _shouldReturnFocus$cu.focus();
      }, 0);
    };
  }
  return jsx(Layering, {
    isDisabled: false
  }, jsx(Portal, {
    zIndex: layers.modal()
  }, jsx(FadeIn, null, function (fadeInProps) {
    return jsx("div", _extends({}, fadeInProps, {
      css: fillScreenStyles,
      "aria-hidden": !isForeground
    }), jsx(FocusLock, {
      autoFocus: autoFocusLock,
      returnFocus: returnFocus,
      onDeactivation: onDeactivation,
      whiteList: allowListCallback
    }, jsx(ScrollLock, null), shouldScrollInViewport ? jsx(TouchScrollable, null, modalDialogWithBlanket) : modalDialogWithBlanket));
  })));
};
export default ModalWrapper;