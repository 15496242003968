/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { Box, xcss } from '@atlaskit/primitives';
var navigationFooterStyles = xcss({
  display: 'block',
  padding: 'space.100',
  paddingBlockStart: 'space.300'
});
/**
 * __Navigation header__
 *
 * Allows for customisation of the header.
 *
 * - [Examples](https://atlassian.design/components/side-navigation/examples#header-and-footer)
 * - [Code](https://atlassian.design/components/side-navigation/code)
 */
var NavigationHeader = function NavigationHeader(props) {
  var children = props.children;
  return jsx(Box, {
    xcss: navigationFooterStyles,
    "data-navheader": true
  }, children);
};
export default NavigationHeader;