import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["children", "iconAfter", "title", "onClick", "cssFn", "isSelected", "id", "component", "testId"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right--arrow-right-circle';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import { N10 } from '@atlaskit/theme/colors';
import { overrideStyleFunction } from '../../common/styles';
import { ButtonItem, CustomItem, NavigationContent } from '../index';
import { ROOT_ID } from '../NestableNavigationContent';
import { NestedContext, useNestedContext } from '../NestableNavigationContent/context';
import { useChildIdsEffect } from '../utils/hooks';
import { nestingItemStyle } from './styles';
var iconContainerStyles = xcss({
  display: 'inline'
});

// Doesn't extend from ButtonItemProps because it blows ERT up.

/**
 * NestingItem will render itself differently depending in what context it is rendered in.
 * When not open - it will render itself as an item.
 * When open - it will render its children.
 */
var NestingItem = function NestingItem(props) {
  var children = props.children,
    iconAfter = props.iconAfter,
    title = props.title,
    onClick = props.onClick,
    cssFn = props.cssFn,
    isSelected = props.isSelected,
    id = props.id,
    component = props.component,
    testId = props.testId,
    rest = _objectWithoutProperties(props, _excluded);
  var _useNestedContext = useNestedContext(),
    currentStackId = _useNestedContext.currentStackId,
    onNest = _useNestedContext.onNest,
    onUnNest = _useNestedContext.onUnNest,
    contextualBackButton = _useNestedContext.backButton,
    stack = _useNestedContext.stack,
    childIds = _useNestedContext.childIds,
    forceShowTopScrollIndicator = _useNestedContext.forceShowTopScrollIndicator,
    activeParentId = _useNestedContext.activeParentId,
    goBackButtonRef = _useNestedContext.goBackButtonRef,
    isDefaultFocusControl = _useNestedContext.isDefaultFocusControl,
    focusGoBackButton = _useNestedContext.focusGoBackButton;
  var mergedStyles = overrideStyleFunction(nestingItemStyle, cssFn);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isInteracted = _useState2[0],
    setIsInteracted = _useState2[1];
  var parentItemRef = useRef(null);

  // To avoid error we need to make sure that the component is wrapped in ForwardRef
  var isForwardRefCheck = function isForwardRefCheck(component) {
    var _component$prototype;
    if (component !== null && component !== void 0 && (_component$prototype = component.prototype) !== null && _component$prototype !== void 0 && _component$prototype.isReactComponent) {
      return false;
    }
    return (component === null || component === void 0 ? void 0 : component.$$typeof) === Symbol.for('react.forward_ref');
  };
  var shouldFocus = isDefaultFocusControl && fg('platform-side-navigation-keyboard-focus');
  var backButtonRef = shouldFocus ? goBackButtonRef : null;
  var activeParentRef = shouldFocus ? parentItemRef : null;
  useEffect(function () {
    if (shouldFocus) {
      if (activeParentId === id) {
        var _parentItemRef$curren;
        parentItemRef === null || parentItemRef === void 0 || (_parentItemRef$curren = parentItemRef.current) === null || _parentItemRef$curren === void 0 || _parentItemRef$curren.focus();
      }
      if (focusGoBackButton) {
        var _goBackButtonRef$curr;
        goBackButtonRef === null || goBackButtonRef === void 0 || (_goBackButtonRef$curr = goBackButtonRef.current) === null || _goBackButtonRef$curr === void 0 || _goBackButtonRef$curr.focus();
      }
    }
  }, [activeParentId, id, goBackButtonRef, focusGoBackButton, isDefaultFocusControl, shouldFocus]);
  var backButton = props.overrides && props.overrides.GoBackItem && props.overrides.GoBackItem.render && props.overrides.GoBackItem.render({
    onClick: onUnNest,
    testId: testId && "".concat(testId, "--go-back-item"),
    ref: backButtonRef
  }) || contextualBackButton;
  var context = useMemo(function () {
    return {
      stack: stack,
      currentStackId: currentStackId,
      onNest: onNest,
      onUnNest: onUnNest,
      backButton: backButton,
      parentId: id,
      childIds: childIds,
      forceShowTopScrollIndicator: forceShowTopScrollIndicator,
      activeParentId: activeParentId,
      goBackButtonRef: goBackButtonRef,
      isDefaultFocusControl: isDefaultFocusControl,
      focusGoBackButton: focusGoBackButton
    };
  }, [stack, currentStackId, onNest, onUnNest, backButton, id, childIds, forceShowTopScrollIndicator, activeParentId, goBackButtonRef, isDefaultFocusControl, focusGoBackButton]);
  useChildIdsEffect(childIds, id);
  var isNormalClick = function isNormalClick(e) {
    return !(e.button || e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);
  };

  /**
   * We want both the on nest handler and the onclick handler to be called.
   * We create a wrapper function to call both.
   */
  var onClickHandler = useCallback(function (e) {
    if (isInteracted) {
      // We return early if this has been interacted with because its assumed
      // this will be already exiting - so we don't want to double up the click.
      return;
    }

    // Avoid a nesting transition if a modifier key is detected during click.
    // @ts-expect-error - Argument of type 'MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>' is not assignable to parameter of type 'MouseEvent'
    if (isNormalClick(e)) {
      setIsInteracted(true);
      onNest(id);
    }
    onClick && onClick(e);
  }, [isInteracted, onClick, onNest, id]);
  if (currentStackId === id) {
    return jsx(NestedContext.Provider, {
      value: context
    }, stack.length >= 1 && jsx(Box, {
      paddingBlock: "space.075",
      paddingInline: "space.100"
    }, backButton), jsx(NavigationContent, {
      testId: testId,
      showTopScrollIndicator: forceShowTopScrollIndicator || stack.length >= 1
    }, children));
  }
  if ([ROOT_ID].concat(_toConsumableArray(stack)).includes(id)) {
    return children;
  }
  var componentProps = _objectSpread(_objectSpread({
    iconAfter: jsx(Fragment, null, iconAfter ? jsx(Box, {
      xcss: iconContainerStyles,
      "data-custom-icon": true,
      as: "span"
    }, iconAfter) : null, jsx(Box, {
      "data-right-arrow": true,
      xcss: iconContainerStyles,
      as: "span"
    }, jsx(ArrowRightIcon, {
      testId: testId && "".concat(testId, "--item--right-arrow"),
      color: "currentColor",
      LEGACY_secondaryColor: "var(--ds-surface, ".concat(N10, ")"),
      label: ""
    }))),
    onClick: onClickHandler,
    isSelected: isSelected,
    testId: testId && "".concat(testId, "--item")
  }, rest), {}, {
    children: title,
    cssFn: mergedStyles
  });
  if (component) {
    return jsx(CustomItem, _extends({
      ref: isForwardRefCheck(component) ? parentItemRef : null
    }, componentProps, {
      //@ts-expect-error TODO Fix legit TypeScript 3.9.6 improved inference error
      component: component
    }));
  }
  return jsx(ButtonItem, _extends({
    ref: activeParentRef
  }, componentProps));
};
export default NestingItem;