function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
export var getCLS = function getCLS(start, stop, buffer) {
  var layoutShifts = buffer.getAll().filter(function (entry) {
    return entry.startTime >= start && entry.startTime <= stop;
  });
  var sessionWindows = [];
  var currentWindow = null;

  // Group layout shifts into session windows
  var _iterator = _createForOfIteratorHelper(layoutShifts),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var shift = _step.value;
      var startTime = shift.startTime;
      var endTime = shift.startTime + shift.duration;
      var score = shift.value;
      if (currentWindow === null || startTime - currentWindow.endTime > 1000 || endTime - currentWindow.startTime > 5000) {
        // Start a new session window
        currentWindow = {
          startTime: startTime,
          endTime: endTime,
          score: score
        };
        sessionWindows.push(currentWindow);
      } else {
        // Add layout shift to current session window
        currentWindow.endTime = endTime;
        currentWindow.score += score;
      }
    }

    // Find session window with highest cumulative score
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  var maxScore = sessionWindows.reduce(function (max, sessionWindow) {
    return sessionWindow.score > max ? sessionWindow.score : max;
  }, 0);

  // Return score of largest burst as CLS metric
  return Math.round(maxScore * 10000) / 10000;
};