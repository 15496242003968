import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { transformNestedTableNodeOutgoingDocument } from '@atlaskit/adf-utils/transforms';
import { traverse } from '@atlaskit/adf-utils/traverse';
import { fg } from '@atlaskit/platform-feature-flags';
import { removeMarks, removeNonAnnotationMarks } from './remove-marks';
var hasNestedTable = function hasNestedTable(tableCellNode) {
  var _tableCellNode$conten;
  return (_tableCellNode$conten = tableCellNode.content) === null || _tableCellNode$conten === void 0 ? void 0 : _tableCellNode$conten.some(function (node) {
    return (node === null || node === void 0 ? void 0 : node.type) === 'table';
  });
};
export function sanitizeNode(json) {
  var sanitizedJSON = traverse(json, {
    text: function text(node) {
      if (!node || !Array.isArray(node.marks)) {
        return node;
      }
      return _objectSpread(_objectSpread({}, node), {}, {
        marks: node.marks.filter(function (mark) {
          return mark.type !== 'typeAheadQuery';
        })
      });
    },
    status: function status(node) {
      if (node.attrs && !!node.attrs.text) {
        return removeNonAnnotationMarks(node);
      }
      return false; // empty status
    },
    caption: function caption(node) {
      if (node.content) {
        return node;
      }
      return false; // empty caption
    },
    tableCell: function tableCell(node) {
      if (hasNestedTable(node) && fg('platform_editor_use_nested_table_pm_nodes')) {
        return transformNestedTableNodeOutgoingDocument(node);
      }
    },
    tableHeader: function tableHeader(node) {
      if (hasNestedTable(node) && fg('platform_editor_use_nested_table_pm_nodes')) {
        return transformNestedTableNodeOutgoingDocument(node);
      }
    },
    emoji: removeNonAnnotationMarks,
    mention: removeNonAnnotationMarks,
    date: removeNonAnnotationMarks,
    hardBreak: removeMarks,
    inlineCard: removeNonAnnotationMarks
  });
  return sanitizedJSON;
}