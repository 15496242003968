import {
  Step,
  StepCommand,
  StepData,
  ChildPipelineStep,
} from 'src/components/pipelines/models';
import { LogState } from 'src/components/pipelines/redux/reducers/log';

export const fullLogToByteArray = (
  fullLog: string
): Uint8Array<ArrayBufferLike> => new TextEncoder().encode(fullLog);

export const getLogLinesFromByteArrayPositions = (
  byteArray: Uint8Array<ArrayBufferLike>,
  firstByte: number,
  lastByte: number
): string[] =>
  new TextDecoder().decode(byteArray.slice(firstByte, lastByte)).split('\n');

export const extractCommandsFromFullLog = (
  fullLog: string,
  stepCommands: StepCommand[]
): {
  commands: LogState['commands'][string];
  stepLogs: LogState['stepLogs'][string];
} => {
  const fullLogByteArray = fullLogToByteArray(fullLog);
  const stepLogs = new Map();
  const commands = [] as string[][];

  stepCommands.forEach((stepCommand, index) => {
    const logLines = getLogLinesFromByteArrayPositions(
      fullLogByteArray,
      stepCommand.log_range.first_byte_position,
      stepCommand.log_range.last_byte_position
    );
    commands[index] = logLines;
    stepLogs.set(index, logLines);
  });
  return { commands, stepLogs };
};

export const selectCurrentStepUuid = (
  stepData: StepData[],
  stepsMap: Map<string, Step | ChildPipelineStep>,
  actionStepUuid?: string
): string => {
  if (!stepData?.length) return '';

  if (actionStepUuid && stepData.some(step => step?.uuid === actionStepUuid)) {
    return actionStepUuid;
  } else {
    const firstFailedStepUuid = Array.from(stepsMap, ([_, step]) => step).find(
      x => x.isFailed
    )?.uuid;
    const firstStepUuid = stepData[0]?.uuid;
    return firstFailedStepUuid || firstStepUuid || '';
  }
};
