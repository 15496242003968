import { createResource } from 'react-resource-router';

import {
  clearSteps,
  setSteps,
} from 'src/components/pipelines/redux/actions/pipelines';
import { getStepsUrl } from 'src/components/pipelines/redux/sagas/steps';
import { ResourceContext } from 'src/router/types';

import { fetchData, hasFetchedStep } from './utils';

export type Params = {
  pipelineUuid: string;
  stepUuid: string;
  repositoryFullSlug: string;
  trigger: string;
  pipelineRunUuid: string;
};

export const getUrl = (params: Params): string => {
  return getStepsUrl(
    params.repositoryFullSlug,
    params.pipelineUuid,
    params.pipelineRunUuid
  );
};

export const loadStepsResource = createResource({
  type: 'steps',
  getKey: ({ match: { params } }) => getUrl(params as Params),
  maxAge: 0,
  getData: async ({ match: { params } }, { reduxStore }: ResourceContext) => {
    const { dispatch, getState } = reduxStore;

    // Avoid refetch when:
    // going from /result/X to /result/X/step/Y
    // going from /result/X/runs/Y to /result/X/runs/Y/steps/Z
    if (hasFetchedStep(getState(), params.stepUuid, params.pipelineRunUuid)) {
      return { status: 'success' };
    }

    dispatch(clearSteps());
    const data = await fetchData(
      getUrl(params as Params),
      'steps_resource_exception'
    );
    dispatch(setSteps(data, params.stepUuid, params.pipelineRunUuid));
    return { status: 'success' };
  },
});
