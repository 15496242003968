import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { ExitingPersistence } from '@atlaskit/motion';
import { fg } from '@atlaskit/platform-feature-flags';
import { GoBackItem as GoBackButton } from '../Item';
import { default as NestingItem } from '../NestingItem';
import { useChildIds } from '../utils/hooks';
import { NestedContext } from './context';
import { NestingMotion } from './nesting-motion';
// Named so ERT doesn't pick up the override name as a type.

export var ROOT_ID = 'ATLASKIT_NESTED_ROOT';
var nestableNavigationContentStyles = css({
  height: '100%',
  position: 'relative',
  outline: 'none'
});
var nestingRootStyles = css({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'absolute',
  flexDirection: 'column'
});

/**
 * __Nestable navigation content__
 *
 * The container for navigation items with nested views
 *
 * - [Examples](https://atlassian.design/components/side-navigation/examples#nested-navigation)
 * - [Code](https://atlassian.design/components/side-navigation/code)
 */
var NestableNavigationContent = function NestableNavigationContent(props) {
  var containerRef = useRef(null);
  var children = props.children,
    testId = props.testId,
    overrides = props.overrides,
    initialStack = props.initialStack,
    onChange = props.onChange,
    onUnknownNest = props.onUnknownNest,
    stack = props.stack,
    showTopScrollIndicator = props.showTopScrollIndicator,
    _props$isDefaultFocus = props.isDefaultFocusControl,
    isDefaultFocusControl = _props$isDefaultFocus === void 0 ? true : _props$isDefaultFocus;
  var goBackButtonRef = useRef(null);
  var _useState = useState(stack || initialStack || []),
    _useState2 = _slicedToArray(_useState, 2),
    committedStack = _useState2[0],
    setCommittedStack = _useState2[1];
  var controlledStack = stack || undefined;
  var currentStackId = committedStack[committedStack.length - 1] || ROOT_ID;
  var _useState3 = useState('nesting'),
    _useState4 = _slicedToArray(_useState3, 2),
    transition = _useState4[0],
    setTransition = _useState4[1];
  var backTestId = testId && "".concat(testId, "--go-back-item");
  var _useState5 = useState(''),
    _useState6 = _slicedToArray(_useState5, 2),
    activeParentId = _useState6[0],
    setActiveParentId = _useState6[1];
  var _useState7 = useState(false),
    _useState8 = _slicedToArray(_useState7, 2),
    focusGoBackButton = _useState8[0],
    setFocusGoBackButton = _useState8[1];
  var shouldFocus = isDefaultFocusControl && fg('platform-side-navigation-keyboard-focus');
  var renderGoBackItem = overrides && overrides.GoBackItem && overrides.GoBackItem.render ? overrides.GoBackItem.render : function (props) {
    return jsx(GoBackButton, props, "Go back");
  };
  var _useChildIds = useChildIds(currentStackId, committedStack, onUnknownNest),
    childIdsRef = _useChildIds.childIdsRef;
  var onNestHandler = useCallback(function (layerId) {
    onChange && onChange(committedStack.concat(layerId));
    setFocusGoBackButton(true);
    if (controlledStack) {
      // We are in controlled mode - ignore the steps.
      return;
    }
    // We need to split the state update into to parts.
    // First: Updating the direction of the motions.
    // Second: Actually updating the stack (which will cause elements to enter & leave).
    setTransition('nesting');
    requestAnimationFrame(function () {
      setCommittedStack(function (prev) {
        var newStack = prev.concat(layerId);
        return newStack;
      });
    });
  }, [controlledStack, onChange, committedStack]);
  var onUnNestHandler = useCallback(function () {
    onChange && onChange(committedStack.slice(0, committedStack.length - 1));
    setActiveParentId(currentStackId);
    setFocusGoBackButton(false);
    if (controlledStack) {
      // We are in controlled mode - ignore the steps.
      return;
    }

    // We need to split the state update into to parts.
    // First: Updating the direction of the motions.
    // Second: Actually updating the stack (which will cause elements to enter & leave).
    setTransition('unnesting');
    requestAnimationFrame(function () {
      setCommittedStack(function (prev) {
        var newStack = prev.slice(0, prev.length - 1);
        return newStack;
      });
    });
  }, [controlledStack, onChange, committedStack, setActiveParentId, currentStackId]);
  useEffect(function () {
    if (!controlledStack) {
      // We aren't in controlled mode - bail out.
      return;
    }
    if (JSON.stringify(committedStack) === JSON.stringify(controlledStack)) {
      // stacks are equal - do nothing!
      return;
    }

    // Controlled prop updated, let's figure out if we're nesting or unnesting.
    if (controlledStack.length < committedStack.length) {
      // We are unnesting (removing from the stack)
      setTransition('unnesting');
    } else {
      // We are nesting (adding to the stack)
      setTransition('nesting');
    }
    requestAnimationFrame(function () {
      setCommittedStack(controlledStack);
    });
  }, [committedStack, controlledStack]);
  var backButton = renderGoBackItem({
    onClick: onUnNestHandler,
    testId: backTestId,
    ref: shouldFocus ? goBackButtonRef : null
  });
  var context = useMemo(function () {
    return {
      currentStackId: currentStackId,
      backButton: backButton,
      stack: committedStack,
      onNest: onNestHandler,
      onUnNest: onUnNestHandler,
      parentId: ROOT_ID,
      childIds: childIdsRef,
      forceShowTopScrollIndicator: showTopScrollIndicator,
      activeParentId: activeParentId,
      goBackButtonRef: goBackButtonRef,
      isDefaultFocusControl: isDefaultFocusControl,
      focusGoBackButton: focusGoBackButton
    };
  }, [currentStackId, backButton, committedStack, onNestHandler, onUnNestHandler, childIdsRef, showTopScrollIndicator, activeParentId, goBackButtonRef, isDefaultFocusControl, focusGoBackButton]);
  var manageFocus = function manageFocus(event) {
    var triggeredByKeyboard = event.nativeEvent.detail === 0;
    if (triggeredByKeyboard) {
      containerRef.current && containerRef.current.focus();
    }
  };
  return jsx(Fragment, null, jsx("div", {
    "data-testid": testId,
    css: nestableNavigationContentStyles,
    ref: containerRef,
    tabIndex: -1,
    onClick: manageFocus
  }, jsx(ExitingPersistence, null, jsx(NestingMotion
  // Key is needed to have a unique react instance per stack name.
  // This enables us to easily animate it in & out with exiting persistence.
  , {
    key: currentStackId,
    enterFrom: transition === 'nesting' ? 'right' : 'left',
    exitTo: transition === 'nesting' ? 'left' : 'right',
    testId: testId && "".concat(testId, "-anim")
  }, function (motion) {
    return jsx("div", _extends({
      css: nestingRootStyles
    }, motion), jsx(NestedContext.Provider, {
      // This provider is inside the NestingMotion to ensure it keeps a stale
      // reference to the previous value.
      value: context
    }, jsx(NestingItem, {
      title: "",
      id: ROOT_ID
    }, children)));
  }))));
};
export default NestableNavigationContent;