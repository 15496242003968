import { width } from './constants';
export var dialogWidth = function dialogWidth(input) {
  if (!input) {
    return 'auto';
  }
  var isWidthName = width.values.indexOf(input.toString()) !== -1;
  var widthName = isWidthName && input;
  if (widthName) {
    return "".concat(width.widths[widthName], "px");
  }
  return typeof input === 'number' ? "".concat(input, "px") : input;
};
export var dialogHeight = function dialogHeight(input) {
  if (!input) {
    return 'auto';
  }
  return typeof input === 'number' ? "".concat(input, "px") : input;
};