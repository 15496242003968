/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { css, jsx } from '@emotion/react';
import __noop from '@atlaskit/ds-lib/noop';
var styles = css({
  width: '100%',
  position: 'absolute',
  insetBlockEnd: 0,
  insetInlineEnd: 0,
  insetInlineStart: 0,
  label: 'requiredInput',
  opacity: 0,
  pointerEvents: 'none'
});
var RequiredInput = function RequiredInput(_ref) {
  var name = _ref.name,
    onFocus = _ref.onFocus;
  return jsx("input", {
    required: true,
    name: name,
    tabIndex: -1,
    "aria-hidden": "true",
    onFocus: onFocus,
    css: styles
    // Prevent `Switching from uncontrolled to controlled` error
    ,
    value: "",
    onChange: __noop
  });
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default RequiredInput;