import {
  CheckRun,
  Checks,
  CheckSuite,
  Deployment,
} from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REQUEST_CHECKS_DEPLOYMENTS,
  REQUEST_CHECK_RUN,
  REQUEST_CHECK_RUNS,
  REQUEST_CHECK_SUITE,
  REQUEST_CHECK_SUITES,
} from '../actions/pipelines';

export type JsdChecksState = {
  checks: Checks;
  deployments: Deployment[];
};

export const initialState: JsdChecksState = {
  checks: new Checks(),
  deployments: [],
};

type CheckSuiteAction<T> = Action<T> & {
  meta: { accountUuid: string; repositoryUuid: string; revision: string };
};
type CheckRunAction<T> = Action<T> & {
  meta: {
    revision: string;
    checkSuiteUuid: string;
  };
};

export default createReducer(initialState, {
  [REQUEST_CHECK_SUITES.REQUEST](state: JsdChecksState) {
    return {
      ...state,
      checks: new Checks({
        ...state.checks?.toJS?.(),
        check_suites: {},
      }),
    };
  },
  [REQUEST_CHECK_SUITE.SUCCESS](
    state: JsdChecksState,
    action: CheckSuiteAction<{ uuid: string }>
  ) {
    if (!action.payload?.uuid) {
      return state;
    }
    const checkSuite = action.payload;
    const check_suites = { ...state.checks.check_suites };
    check_suites[checkSuite.uuid] = new CheckSuite({
      ...checkSuite,
      check_runs: check_suites[checkSuite.uuid]?.check_runs || [],
    });

    return {
      ...state,
      checks: new Checks({
        ...state.checks?.toJS?.(),
        check_suites,
      }),
    };
  },
  [REQUEST_CHECK_SUITES.SUCCESS](
    state: JsdChecksState,
    action: CheckSuiteAction<{ values: { uuid: string }[] }>
  ) {
    if (!action.payload?.values) {
      return state;
    }

    return {
      ...state,
      checks: new Checks({
        ...state.checks?.toJS?.(),
        check_suites: {
          ...state.checks.check_suites,
          ...action.payload.values.reduce((acc, curr) => {
            acc[curr.uuid] = new CheckSuite({
              check_runs: [],
              ...curr,
            });
            return acc;
          }, {} as any),
        },
      }),
    };
  },
  [REQUEST_CHECK_SUITES.ERROR](state: JsdChecksState) {
    return {
      ...state,
      checks: new Checks({
        ...state.checks?.toJS?.(),
        check_suites: {},
      }),
    };
  },
  [REQUEST_CHECK_RUN.SUCCESS](
    state: JsdChecksState,
    action: CheckRunAction<{ uuid: string; check_suite: { uuid: string } }>
  ) {
    if (!action.payload?.uuid) {
      return state;
    }
    const checkRun = action.payload;

    const check_runs = { ...state.checks.check_runs };
    const check_suites = { ...state.checks.check_suites };
    check_runs[checkRun.uuid] = new CheckRun(checkRun);
    const previousCheckSuite =
      check_suites[checkRun.check_suite.uuid] || new CheckSuite();
    check_suites[checkRun.check_suite.uuid] = new CheckSuite({
      ...previousCheckSuite.toJS(),
      check_runs: previousCheckSuite.check_runs.concat(checkRun.uuid),
    });
    return {
      ...state,
      checks: new Checks({
        ...state.checks?.toJS?.(),
        check_runs,
        check_suites,
      }),
    };
  },
  [REQUEST_CHECK_RUNS.SUCCESS](
    state: JsdChecksState,
    action: CheckRunAction<{
      values: { uuid: string; check_suite: { uuid: string } }[];
    }>
  ) {
    if (!action.payload?.values) {
      return state;
    }
    const payloadValues = action.payload.values;
    const check_runs = payloadValues.reduce(
      (checkRuns, nextCheckRun) => {
        checkRuns[nextCheckRun.uuid] = new CheckRun(nextCheckRun);
        return checkRuns;
      },
      { ...state.checks.check_runs } as any
    );

    const check_suites = payloadValues.reduce(
      (checkSuites, nextCheckRun) => {
        const previousCheckSuite =
          checkSuites[nextCheckRun.check_suite.uuid] || new CheckSuite();
        const checkRuns = Array.isArray(previousCheckSuite.check_runs)
          ? previousCheckSuite.check_runs
          : [];
        checkSuites[nextCheckRun.check_suite.uuid] = new CheckSuite({
          ...previousCheckSuite.toJS(),
          check_runs: checkRuns.concat(nextCheckRun.uuid),
        });
        return checkSuites;
      },
      { ...state.checks.check_suites } as any
    );

    return {
      ...state,
      checks: new Checks({
        ...state.checks?.toJS?.(),
        check_runs,
        check_suites,
      }),
    };
  },
  [REQUEST_CHECK_RUNS.ERROR](
    state: JsdChecksState,
    action: CheckRunAction<void>
  ) {
    if (!action.meta?.checkSuiteUuid) {
      return state;
    }
    const check_suites = { ...state.checks.check_suites };
    check_suites[action.meta.checkSuiteUuid] = new CheckSuite({
      ...check_suites[action.meta.checkSuiteUuid]?.toJS?.(),
      check_runs: [],
    });
    return {
      ...state,
      checks: new Checks({
        ...state.checks?.toJS?.(),
        check_suites,
      }),
    };
  },
  [REQUEST_CHECKS_DEPLOYMENTS.SUCCESS](
    state: JsdChecksState,
    action: Action<any[]>
  ) {
    if (!action?.payload) {
      return state;
    }
    return {
      ...state,
      deployments: action.payload.map(d => new Deployment(d)),
    };
  },
});
