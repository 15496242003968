import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { B400, B50, N30, N500 } from '@atlaskit/theme/colors';
/**
 * Allows chaining of style functions on top of base style functions
 * @param baseStyle the base custom cssFn
 * @param newStyle a new cssFn to be applied after the base style
 *
 * @deprecated Please avoid using this prop as we intend to remove the prop completely in a future release. See DSP-2682 for more information.
 */
export var overrideStyleFunction = function overrideStyleFunction(baseStyle) {
  var newStyle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {
    return {};
  };
  return function (state) {
    return [baseStyle(state), newStyle(state)];
  };
};
var defaultStyles = {
  '&:hover': {
    color: "var(--ds-text-subtle, ".concat(N500, ")"),
    backgroundColor: "var(--ds-background-neutral-subtle-hovered, ".concat(N30, ")")
  },
  '&:active': {
    color: "var(--ds-text-subtle, ".concat(B400, ")"),
    backgroundColor: "var(--ds-background-neutral-subtle-pressed, ".concat(B50, ")")
  }
};
var selectedStyles = {
  backgroundColor: "var(--ds-background-selected, ".concat(N30, ")"),
  color: "var(--ds-text-selected, ".concat(B400, ")"),
  ':visited': {
    color: "var(--ds-text-selected, ".concat(B400, ")")
  },
  '&:hover': {
    backgroundColor: "var(--ds-background-selected-hovered, ".concat(N30, ")"),
    color: "var(--ds-text-selected, ".concat(N500, ")")
  },
  '&:active': {
    backgroundColor: "var(--ds-background-selected-pressed, ".concat(B50, ")"),
    color: "var(--ds-text-selected, ".concat(B400, ")")
  }
};
export var baseSideNavItemStyle = function baseSideNavItemStyle(_ref) {
  var isSelected = _ref.isSelected,
    isDisabled = _ref.isDisabled;
  return _objectSpread(_objectSpread(_objectSpread({
    // This padding is set to ensure that the center of the left icon
    // is approximately center aligned with the horizontal app switcher.
    paddingBlock: "var(--ds-space-100, 8px)",
    paddingInline: "var(--ds-space-100, 8px)",
    borderRadius: "var(--ds-border-radius, 3px)",
    // -- TODO: DELETE THESE COLOR OVERRIDES WHEN CLEANING UP FALLBACK THEMING --
    // Menu and side navigation are now color aligned so they do not need this!
    // See: https://product-fabric.atlassian.net/browse/DSP-1684
    backgroundColor: "var(--ds-background-neutral-subtle, transparent)"
  }, !isDisabled && !isSelected && defaultStyles), !isDisabled && isSelected && selectedStyles), {}, _defineProperty({}, '& [data-item-elem-before]', {
    height: '1.5rem',
    width: '1.5rem'
  }));
};
export var sectionHeaderSpacingStyles = function sectionHeaderSpacingStyles() {
  return {
    paddingInline: "var(--ds-space-100, 8px)"
  };
};