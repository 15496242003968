import { PerformanceObserverEntryTypes } from '../../const';
import { EntriesBuffer } from '../buffer';
var pe = null;
var getObserver = function getObserver() {
  if (pe !== null) {
    return pe;
  }
  pe = new PerformanceObserver(function (list) {
    list.getEntries().forEach(function (entry) {
      if (entry.entryType === PerformanceObserverEntryTypes.LayoutShift) {
        EntriesBuffer[PerformanceObserverEntryTypes.LayoutShift].push(entry);
      }
      if (entry.entryType === PerformanceObserverEntryTypes.LongTask) {
        EntriesBuffer[PerformanceObserverEntryTypes.LongTask].push(entry);
      }
    });
  });
  return pe;
};
export var startLSObserver = function startLSObserver() {
  getObserver().observe({
    type: PerformanceObserverEntryTypes.LayoutShift,
    buffered: true
  });
};
export var startLTObserver = function startLTObserver() {
  getObserver().observe({
    type: PerformanceObserverEntryTypes.LongTask,
    buffered: true
  });
};