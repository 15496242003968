/**
 * Extracts the URL from a JSON-LD Link object or array of link objects.
 *
 * If the link is a string, it is assumed to be a URL and is returned as is.
 * If the link is an array, returns the URL in the first link object.
 * Otherwise the href property is returned.
 *
 * @param link - The JSON-LD link object or array of link objects.
 * @returns URL string if one is found, otherwise undefined.
 */
var _extractUrlFromLinkJsonLd = function extractUrlFromLinkJsonLd(link) {
  if (typeof link === 'string') {
    return link;
  } else if (Array.isArray(link)) {
    if (link.length > 0) {
      return _extractUrlFromLinkJsonLd(link[0]);
    }
  } else {
    return link.href;
  }
};
export { _extractUrlFromLinkJsonLd as extractUrlFromLinkJsonLd };