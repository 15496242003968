import { useEffect, useRef } from 'react';

/**
 * Returns the previous value in a given render cycle.
 *
 * ```js
 * const [currentValue] = useState(1);
 * const previousValue = usePreviousValue(currentValue);
 *
 * previousValue; // undefined
 * currentValue; // 1
 * ```
 *
 * @param value
 */
export default function usePreviousValue(value) {
  var ref = useRef();
  useEffect(function () {
    ref.current = value;
  }, [value]);
  return ref.current;
}