var delayId = null;
export function clearScheduled() {
  if (delayId != null) {
    window.clearTimeout(delayId);
    delayId = null;
  }
}
export function scheduleTimeout(fn, delay) {
  clearScheduled();
  delayId = window.setTimeout(function () {
    delayId = null;
    fn();
  }, delay);
}