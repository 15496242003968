import * as colors from '@atlaskit/theme/colors';
export var MSThemeColors = {
  Background: 'Canvas',
  Text: 'CanvasText',
  SelectedBackground: 'Highlight',
  SelectedText: 'HighlightText'
};
export var arrow = {
  defaultColor: "var(--ds-icon-disabled, ".concat(colors.N40, ")"),
  selectedColor: "var(--ds-icon-subtle, ".concat(colors.N300, ")")
};
export var row = {
  focusOutline: "var(--ds-border-focused, ".concat(colors.B100, ")"),
  highlightedBackground: "var(--ds-background-selected, ".concat(colors.B50, ")"),
  hoverBackground: "var(--ds-background-neutral-subtle-hovered, ".concat(colors.N10, ")"),
  hoverHighlightedBackground: "var(--ds-background-selected-hovered, ".concat(colors.B75, ")")
};
export var head = {
  textColor: "var(--ds-text-subtlest, ".concat(colors.N300, ")")
};
export var tableBorder = {
  borderColor: "var(--ds-border, ".concat(colors.N40, ")")
};