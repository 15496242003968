import { useContext } from 'react';
import { ScrollContext } from '../context';

/**
 * This returns whether or not scrolling is allowed based on the existing
 * scrolling context.
 */
export default function useScroll() {
  var shouldScrollInViewport = useContext(ScrollContext);
  if (shouldScrollInViewport == null) {
    throw Error('@atlaskit/modal-dialog: Scroll context unavailable – this component needs to be a child of ModalDialog.');
  }
  return shouldScrollInViewport;
}