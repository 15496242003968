/* eslint-disable @repo/internal/fs/filename-pattern-match */

export var formatGroupLabel = function formatGroupLabel(group) {
  return group.label;
};
export var getOptionLabel = function getOptionLabel(option) {
  return option.label;
};
export var getOptionValue = function getOptionValue(option) {
  return option.value;
};
export var isOptionDisabled = function isOptionDisabled(option) {
  return !!option.isDisabled;
};