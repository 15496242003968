import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';
import {
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
gridSize as getGridSize } from '@atlaskit/theme/constants';
import colors from './colors';
var gridSize = getGridSize();

// ## Button layout
//
// /------------------------------------------------------------------------------------------------------------------\
// |       →  |  ←      |          |      →  |  ←      |           |      →  |  ←      |         |      →  |  ←       |
// |  10px →  |  ← 2px  |   icon   |  2px →  |  ← 2px  |           |  2px →  |  ← 2px  |  icon   |  2px →  |  ← 10px  |
// |  padding |  margin |  before  |  margin |  margin |  content  |  margin |  margin |  after  |  margin |  padding |
// |    (12px total)    |          |    (4px total)    |           |    (4px total)    |         |    (12px total)    |
// |       →  |  ←      |          |      →  |  ←      |           |      →  |  ←      |         |      →  |  ←       |
// \------------------------------------------------------------------------------------------------------------------/
//                                           ↑                               ↑
//                                        Margins don't collapse with inline-flex
//
var heights = {
  default: "".concat(32 / 14, "em"),
  // 32px
  compact: "".concat(24 / 14, "em"),
  none: 'auto'
};
var lineHeights = {
  default: heights.default,
  compact: heights.compact,
  none: 'inherit'
};
var padding = {
  // 10px gutter
  default: "0 ".concat(gridSize + gridSize / 4, "px"),
  compact: "0 ".concat(gridSize + gridSize / 4, "px"),
  none: '0'
};
var singleIconPadding = {
  // 2px gutter
  compact: "0 ".concat(gridSize / 4, "px"),
  default: "0 ".concat(gridSize / 4, "px"),
  none: '0'
};
var verticalAlign = {
  default: 'middle',
  compact: 'middle',
  none: 'baseline'
};
var innerMargin = {
  content: "0 ".concat(gridSize / 4, "px"),
  icon: "0 ".concat(gridSize / 4, "px")
};
function getColor(_ref) {
  var group = _ref.group,
    key = _ref.key,
    mode = _ref.mode;
  var rule = group[key] || group.default;
  return rule[mode];
}
function getColors(_ref2) {
  var appearance = _ref2.appearance,
    key = _ref2.key,
    mode = _ref2.mode;
  return {
    background: getColor({
      group: colors.background[appearance],
      key: key,
      mode: mode
    }),
    // Needing to add !important to overcome specificity issue caused by deprecated AtlaskitThemeProvider
    color: "".concat(getColor({
      group: colors.color[appearance],
      key: key,
      mode: mode
    }), " !important")
  };
}
export function getCss(_ref3) {
  var appearance = _ref3.appearance,
    spacing = _ref3.spacing,
    mode = _ref3.mode,
    isSelected = _ref3.isSelected,
    shouldFitContainer = _ref3.shouldFitContainer,
    isOnlySingleIcon = _ref3.isOnlySingleIcon;
  var baseColors = getColors({
    appearance: appearance,
    key: isSelected ? 'selected' : 'default',
    mode: mode
  });
  return _objectSpread(_objectSpread({
    // 0px margin added to css-reset
    alignItems: 'baseline',
    borderWidth: 0,
    borderRadius: "var(--ds-border-radius, 3px)",
    boxSizing: 'border-box',
    display: 'inline-flex',
    fontSize: 'inherit',
    fontStyle: 'normal',
    // Chrome recently changed button so that they use 'arial' as the font family
    fontFamily: 'inherit',
    fontWeight: 500,
    // margin for button has been applied to css reset
    maxWidth: '100%',
    // Needed to position overlay
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
    whiteSpace: 'nowrap'
  }, baseColors), {}, {
    cursor: 'pointer',
    height: heights[spacing],
    lineHeight: lineHeights[spacing],
    padding: isOnlySingleIcon ? singleIconPadding[spacing] : padding[spacing],
    verticalAlign: verticalAlign[spacing],
    width: shouldFitContainer ? '100%' : 'auto',
    // justifyContent required for shouldFitContainer buttons with an icon inside
    justifyContent: 'center',
    // Note: we cannot disable pointer events when there is an overlay.
    // That would be easy for styling, but it would start letting events through on disabled buttons

    // Disabling visited styles (just using the base colors)
    '&:visited': _objectSpread({}, baseColors),
    '&:hover': _objectSpread(_objectSpread({}, getColors({
      appearance: appearance,
      key: isSelected ? 'selected' : 'hover',
      mode: mode
    })), {}, {
      textDecoration: !isSelected && (appearance === 'link' || appearance === 'subtle-link') ? 'underline' : 'inherit',
      // background, box-shadow
      transitionDuration: '0s, 0.15s'
    }),
    // giving active styles preference by listing them after focus
    '&:active': _objectSpread(_objectSpread({}, getColors({
      appearance: appearance,
      key: isSelected ? 'selected' : 'active',
      mode: mode
    })), {}, {
      // background, box-shadow
      transitionDuration: '0s, 0s'
    }),
    // preventDefault prevents regular active styles from applying in Firefox
    '&[data-firefox-is-active="true"]': _objectSpread(_objectSpread({}, getColors({
      appearance: appearance,
      key: isSelected ? 'selected' : 'active',
      mode: mode
    })), {}, {
      // background, box-shadow
      transitionDuration: '0s, 0s'
    }),
    // Giving disabled styles preference over active by listing them after.
    // Not using '&:disabled' because :disabled is not a valid state for all element types
    // so we are targeting the attribute
    // Attributes have the same specificity a pseudo classes so we are overriding :disabled here
    '&[disabled]': _objectSpread(_objectSpread({}, getColors({
      appearance: appearance,
      key: 'disabled',
      mode: mode
    })), {}, {
      cursor: 'not-allowed',
      textDecoration: 'none'
    }),
    '&[data-has-overlay="true"]': {
      cursor: 'default',
      textDecoration: 'none'
    },
    // disabling hover and active color changes when there is an overlay, but the button is not disabled
    '&[data-has-overlay="true"]:not([disabled]):hover, &[data-has-overlay="true"]:not([disabled]):active': _objectSpread({}, getColors({
      appearance: appearance,
      key: isSelected ? 'selected' : 'default',
      mode: mode
    })),
    '&::-moz-focus-inner': {
      border: 0,
      margin: 0,
      padding: 0
    }
  });
}

// inline-flex child
export function getIconStyle(_ref4) {
  var spacing = _ref4.spacing;
  return css({
    display: 'flex',
    // icon size cannot grow and shrink
    // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    margin: spacing === 'none' ? 0 : innerMargin.icon,
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'center',
    fontSize: 0,
    lineHeight: 0,
    userSelect: 'none'
  });
}

// inline-flex child
export function getContentStyle(_ref5) {
  var spacing = _ref5.spacing;
  return css({
    // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    margin: spacing === 'none' ? 0 : innerMargin.content,
    // content can grow and shrink
    flexGrow: 1,
    flexShrink: 1,
    // ellipsis for overflow text
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  });
}
export function getFadingCss(_ref6) {
  var hasOverlay = _ref6.hasOverlay;
  return css({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    opacity: hasOverlay ? 0 : 1,
    transition: 'opacity 0.3s'
  });
}
export var overlayCss = {
  // stretching to full width / height of button
  // this is important as we need it to still block
  // event if clicking in the button's own padding
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  // Putting all children in the center
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};