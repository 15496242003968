import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react';
import { UNSAFE_useMediaQuery as useMediaQuery } from '@atlaskit/primitives/responsive';

/**
 * **usePopupAppearance()**
 *
 * Abstracts away calculating the appearance for a popup including if it should
 * be portalled or not, this is done to ensure that if the popup needs to render
 * as a modal that it is also forcibly portalled. If it's not portalled when rendering
 * as a modal it will appear below the top bar.
 */
export function usePopupAppearance(_ref) {
  var _appearance = _ref.appearance,
    _shouldRenderToParent = _ref.shouldRenderToParent;
  var mq = useMediaQuery('below.sm', function (e) {
    setIsSmallViewport(!!e.matches);
  });
  var _useState = useState(!!(mq !== null && mq !== void 0 && mq.matches)),
    _useState2 = _slicedToArray(_useState, 2),
    isSmallViewport = _useState2[0],
    setIsSmallViewport = _useState2[1];
  var appearance = _appearance === 'UNSAFE_modal-below-sm' && isSmallViewport ? 'UNSAFE_modal-below-sm' : 'default';
  var shouldRenderToParent = _shouldRenderToParent && appearance === 'default';
  return {
    appearance: appearance,
    shouldRenderToParent: shouldRenderToParent
  };
}