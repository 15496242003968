import { bind } from 'bind-event-listener';
var timings = [];
var wasHiddenFlag;
var setupFlag = false;
function isPageHidden() {
  if ('visibilityState' in document) {
    return document.visibilityState === 'hidden';
  } else {
    // @ts-expect-error - Property 'hidden' does not exist on type 'never'.
    return document.hidden;
  }
}
var SIZE = 50;
var insertIndex = 0;
function pushHidden(isPageHiddenFlag, time) {
  timings[insertIndex] = {
    time: time !== null && time !== void 0 ? time : performance.now(),
    hidden: isPageHiddenFlag
  };
  insertIndex = (insertIndex + 1) % SIZE;
  wasHiddenFlag = isPageHiddenFlag;
}
function handleChange() {
  var isPageHiddenFlag = isPageHidden();
  if (isPageHiddenFlag) {
    if (!wasHiddenFlag) {
      pushHidden(isPageHiddenFlag);
    }
    wasHiddenFlag = true;
  } else {
    if (wasHiddenFlag) {
      pushHidden(isPageHiddenFlag);
    }
    wasHiddenFlag = false;
  }
}
function setup() {
  bind(window, {
    type: 'pageshow',
    listener: handleChange
  });
  bind(window, {
    type: 'pagehide',
    listener: handleChange
  });
  bind(document, {
    type: 'visibilitychange',
    listener: handleChange
  });
}
export function setupHiddenTimingCapture() {
  if (!setupFlag) {
    var isPageHiddenFlag = isPageHidden();
    pushHidden(isPageHiddenFlag, 0);
    setup();
    setupFlag = true;
  }
}
export function getPageVisibilityState(start, end) {
  // Desirable default value is visible
  if (timings.length === 0) {
    return 'visible';
  }
  var currentSize = timings.length;
  var hiddenState = 'mixed';
  var startIdx = insertIndex;
  var endIdx = insertIndex;

  // currentSize is capped at SIZE.
  for (var i = 0; i < currentSize; i++) {
    var tmpIdx = (insertIndex + i) % currentSize;
    if (timings[tmpIdx].time <= end) {
      endIdx = tmpIdx;
      if (timings[tmpIdx].time <= start) {
        startIdx = tmpIdx;
      }
    }
  }
  if (endIdx - startIdx === 0) {
    if (timings[startIdx].hidden) {
      hiddenState = 'hidden';
    } else {
      hiddenState = 'visible';
    }
  }
  return hiddenState;
}