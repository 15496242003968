import React, { FC, useEffect } from 'react';

import { RouteComponent, useRouter } from 'react-resource-router';

import { StatsigFeatureKeys } from '@atlassian/bitbucket-features';

import HistoryWatcher from 'src/components/performance-metrics/history-watcher';
import { useStatsigGate } from 'src/contexts/feature-provider';
import { useFlag } from 'src/hooks/flag';
import { RouteContext, ROUTE_NAME } from 'src/router/routes/types';
import { USER_PR_DEPRECATION_FLAG_ID } from 'src/sections/global/components/flags/userpullrequests-removal-flag';
import store from 'src/utils/store';

/* eslint @typescript-eslint/ban-types: "warn" */
export const UniversalRoutes: FC = () => {
  const [routerState] = useRouter();
  const { route } = routerState as RouteContext;

  // Remove this after the user pull requests API endpoint is fully removed, likely March 2025
  const { showFlagComponent } = useFlag();
  const showUserPullRequestRemovalFlag = useStatsigGate(
    StatsigFeatureKeys.userPullRequestRemovalFlag
  );
  const dismissedUserPullRequestsRemovalFlag = store.get(
    USER_PR_DEPRECATION_FLAG_ID
  );
  useEffect(() => {
    if (
      showUserPullRequestRemovalFlag &&
      !dismissedUserPullRequestsRemovalFlag
    ) {
      showFlagComponent(USER_PR_DEPRECATION_FLAG_ID);
    }
  }, [
    showUserPullRequestRemovalFlag,
    showFlagComponent,
    dismissedUserPullRequestsRemovalFlag,
  ]);

  if (
    // The root route "/" is a redirect, so we don't want to render any additional components yet
    route.name === ROUTE_NAME.ROOT
  ) {
    return <RouteComponent />;
  }

  const Wrapper = route.layout;

  return (
    <>
      <HistoryWatcher />
      {Wrapper ? (
        <Wrapper>
          <RouteComponent />
        </Wrapper>
      ) : (
        <RouteComponent />
      )}
    </>
  );
};
