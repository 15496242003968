// @ts-ignore TODO: fix noImplicitAny error here
import globToRegExp from 'glob-to-regexp';

import { Repository } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import checkLanguage from '../../utils/check-language';
import flattenPaths, { FileTreeItem } from '../../utils/flatten-paths';
import {
  REQUEST_REPOSITORY_BRANCH_RESTRICTIONS,
  REQUEST_REPOSITORY_USER_IS_MEMBER,
  SET_REPOSITORY,
  REQUEST_REPOSITORY_FILES,
} from '../actions/pipelines';

interface BranchRestrictionsByBranch {
  kind: {
    push?: {
      users?: { uuid: string }[];
    };
  };
  pattern: string;
}

export default createReducer(new Repository(), {
  [SET_REPOSITORY](state: Repository, action: Action<any>) {
    if (!action.payload) {
      return state;
    }
    const repoData = action.payload;
    return new Repository({
      ...state?.toJS?.(),
      ...repoData,
      hasFetched: true,
      mainbranch: repoData.mainbranch?.name,
      link: repoData.links?.html?.href,
      path: repoData.full_name,
      displayName: repoData.owner?.display_name || repoData.full_name,
    });
  },
  ['repository/FETCH_REPOSITORY_DETAILS_SUCCESS'](
    // from loadRepositoryDetailsResource
    state: Repository,
    action: Action<{
      result: {
        access_level: string;
      };
    }>
  ) {
    if (!action.payload) return state;

    return new Repository({
      ...state?.toJS?.(),
      canWrite: action.payload.result.access_level !== 'none',
      hasFetchedUserIsAdmin: true,
      userIsAdmin: action.payload.result.access_level === 'admin',
    });
  },
  [REQUEST_REPOSITORY_USER_IS_MEMBER.SUCCESS](
    state: Repository,
    action: Action<{
      values: {
        full_name: string;
      }[];
    }> & { meta: { role: string } }
  ) {
    let perms = {};

    if (action.meta?.role === 'admin') {
      perms = {
        hasFetchedUserIsAdmin: true,
        userIsAdmin: action.payload?.values.some(
          repository => repository.full_name === state.path
        ),
      };
    } else if (action.meta?.role === 'contributor') {
      perms = {
        canWrite: action.payload?.values.some(
          repository => repository.full_name === state.path
        ),
      };
    }

    return new Repository({
      ...state?.toJS?.(),
      ...perms,
    });
  },
  [REQUEST_REPOSITORY_BRANCH_RESTRICTIONS.SUCCESS](
    state: Repository,
    action: Action<BranchRestrictionsByBranch[]> & {
      meta: { userUuid: string };
    }
  ) {
    if (!action.payload || !action.meta?.userUuid) {
      return state;
    }
    const canWriteMainBranch = !action.payload?.some(restriction => {
      return (
        !!restriction.kind.push &&
        globToRegExp(restriction.pattern).test(state.mainbranch) &&
        !restriction.kind.push?.users?.some(
          (user: any) => user?.uuid === action.meta?.userUuid
        )
      );
    });
    return new Repository({
      ...state?.toJS?.(),
      hasFetchedCanWriteMainBranch: true,
      canWriteMainBranch,
    });
  },
  [REQUEST_REPOSITORY_BRANCH_RESTRICTIONS.ERROR]: (state: Repository) => {
    return new Repository({
      ...state?.toJS?.(),
      hasFetchedCanWriteMainBranch: true,
      canWriteMainBranch: true,
    });
  },
  [REQUEST_REPOSITORY_FILES.SUCCESS]: (
    state: Repository,
    action: Action<{ contents: FileTreeItem[] }[]>
  ) => {
    if (!action.payload?.[0]?.contents) {
      return state;
    }
    const files = flattenPaths(action.payload[0].contents);
    return new Repository({
      ...state?.toJS?.(),
      language: state.language || checkLanguage(files),
      files,
    });
  },
});
