/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { R400 } from '@atlaskit/theme/colors';
import { fontFamily as getFontFamily } from '@atlaskit/theme/constants';
var fontFamily = getFontFamily();
var requiredIndicatorStyles = css({
  paddingLeft: "var(--ds-space-025, 2px)",
  color: "var(--ds-text-danger, ".concat(R400, ")"),
  fontFamily: fontFamily
});
export default function RequiredAsterisk() {
  return jsx("span", {
    css: requiredIndicatorStyles,
    "aria-hidden": "true",
    title: "required"
  }, "*");
}