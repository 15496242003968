import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react';
import memoizeOne from 'memoize-one';

/**
 * Here setting ref is not dependent on isOpen flag which is failing in React 18 strict mode
 * Implemented behind ff `platform-design-system-popup-ref`
 * @returns Function to set trigger ref
 */
export var useGetMemoizedMergedTriggerRefNew = function useGetMemoizedMergedTriggerRefNew() {
  var _useState = useState(function () {
      return memoizeOne(function (ref, setTriggerRef) {
        return function (node) {
          if (node) {
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
            setTriggerRef(node);
          }
        };
      });
    }),
    _useState2 = _slicedToArray(_useState, 1),
    getMemoizedMergedTriggerRefNew = _useState2[0];
  return getMemoizedMergedTriggerRefNew;
};