export var SET_MESSAGE_MAPPING = 'SET_MESSAGE_MAPPING';
export var SET_INITIALIZING = 'SET_INITIALIZING';
export var RESET_STORE = 'RESET_STORE';
export var CLEAR_CURRENT = 'CLEAR_CURRENT';
export var SET_CURRENT = 'SET_CURRENT';
export var resetStore = function resetStore() {
  return {
    type: RESET_STORE
  };
};
export var initialize = function initialize(config) {
  return {
    type: SET_INITIALIZING,
    config: config
  };
};
export var setMessageMapping = function setMessageMapping(messageMapping) {
  return {
    type: SET_MESSAGE_MAPPING,
    messageMapping: messageMapping
  };
};
export function setCurrent(eventMapping) {
  return {
    type: SET_CURRENT,
    eventMapping: eventMapping
  };
}
export function clearCurrent() {
  return {
    type: CLEAR_CURRENT
  };
}