import { createResource } from 'react-resource-router';

import {
  fetchAction as fetchActionCreator,
  FetchAction,
  fetchDataFromResource,
} from 'src/redux/actions';
import { hasTransitioned } from 'src/router/history';
import { ResourceContext } from 'src/router/types';
import { FetchRepositoryDetails } from 'src/sections/repository/actions';
import { query } from 'src/sections/repository/actions/fetch-repository-details';
import { repository as repoSchema } from 'src/sections/repository/schemas';
import urls from 'src/sections/repository/urls';
import { getRepositoryAccessLevel } from 'src/selectors/repository-selectors';
import { Action, Thunk } from 'src/types/state';

import { getRepoFullSlug } from './utils/get-full-repo-slug';
import { isSameRepository } from './utils/is-same-repository';

export const loadRepositoryDetailsResource = createResource({
  type: 'repository-details',
  getKey: ({ match }) => `details:${getRepoFullSlug(match.params)}`,
  maxAge: 0,
  getData: async (
    routerStoreContext,
    { reduxStore, csrftoken }: ResourceContext
  ) => {
    const { dispatch } = reduxStore as {
      dispatch: (x: Action | Thunk) => Action<FetchAction> | null;
    };
    const { repositoryOwner, repositorySlug, repositoryFullSlug } =
      routerStoreContext.match.params;
    const repoPath =
      repositoryFullSlug || `${repositoryOwner}/${repositorySlug}`;

    // Only fetch data if the repository has changed, or on initial load
    const state = reduxStore.getState();
    if (
      isSameRepository(repoPath, state) &&
      // check to make sure we have details in state
      getRepositoryAccessLevel(state) &&
      // The redux store has already been populated by the time we reach here on initial load,
      // (possibly by a previous resource?) so we wouldn't be fetching any data at that point
      // without this additional check
      hasTransitioned()
    ) {
      return { status: 'success' };
    }

    const action = fetchActionCreator(FetchRepositoryDetails, {
      url: `${urls.api.internal.details(repoPath)}?${query}`,
      csrftoken,
      schema: {
        parent: repoSchema,
      },
    });

    const result = await dispatch(fetchDataFromResource(action));

    if (result?.type === FetchRepositoryDetails.ERROR) {
      return null;
    }
    return { status: 'success' };
  },
});
