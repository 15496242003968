import { KnownHost } from 'src/components/pipelines/models';
import { getKnownHostsUrl } from 'src/components/pipelines/utils/links';

import {
  HOSTNAME_PORT_INBOUND_REGEX,
  HOSTNAME_PORT_OUTBOUND_REGEX,
} from '../constants';

import envBaseUrl from './env-base-url';

export type Params = {
  repositoryFullSlug?: string;
  repositoryOwner?: string; // legacyPath
  repositorySlug?: string; // legacyPath
};

type Options = {
  page?: string | number;
  pagelen?: string | number;
  uuid?: string;
};

export const getKnownHostsRepoUrl = ({
  repositoryFullSlug,
  repositoryOwner,
  repositorySlug,
  page,
  pagelen,
  uuid,
}: Params & Options) => {
  const repoPath = repositoryFullSlug || `${repositoryOwner}/${repositorySlug}`;
  return getKnownHostsUrl(
    `${envBaseUrl()}/repositories/${repoPath}`,
    page,
    pagelen,
    uuid
  );
};

export const getPublicKeyUrl = (params: Params, hostname: string) => {
  const {
    repositoryFullSlug = `${params.repositoryOwner}/${params.repositorySlug}`,
  } = params;
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines_config/ssh/public_keys/${formatHostname(
    hostname
  )}`;
};

export function parseKnownHost(knownHostData: any): KnownHost {
  let { hostname } = knownHostData;

  const matches = knownHostData.hostname.match(HOSTNAME_PORT_INBOUND_REGEX);
  if (matches !== null && matches.length === 3) {
    // matches[0] is the whole match without groups
    const hostAddress = matches[1];
    const port = matches[2];
    hostname = `${hostAddress}:${port}`;
  }

  return new KnownHost({ ...knownHostData, hostname });
}

export function formatHostname(hostname: string): string {
  let formattedHostname = hostname;

  const matches = hostname.match(HOSTNAME_PORT_OUTBOUND_REGEX);
  if (matches && matches.length === 3) {
    const hostAddress = matches[1];
    const port = matches[2];
    if (!(hostAddress.startsWith('[') && hostAddress.endsWith(']'))) {
      formattedHostname = `[${hostAddress}]:${port}`;
    }
  }

  return formattedHostname;
}

export function getFilteredKnownHostsSelector(
  knownHosts: KnownHost[],
  filter?: 'visible' | 'created'
): KnownHost[] | KnownHost {
  switch (filter) {
    case 'visible':
      return knownHosts.filter(
        ({ uuid, isSyncing }) => !!uuid || (!uuid && isSyncing)
      );
    case 'created':
      return knownHosts[0];
    default:
      return knownHosts;
  }
}
