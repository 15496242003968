import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { useEffect, useMemo } from 'react';
import { css, jsx } from '@emotion/react';
import mergeRefs from '@atlaskit/ds-lib/merge-refs';
import useAutoFocus from '@atlaskit/ds-lib/use-auto-focus';
import { useId } from '@atlaskit/ds-lib/use-id';
import FocusRing from '@atlaskit/focus-ring';
import { useCloseOnEscapePress, useLayering } from '@atlaskit/layering';
import FadeIn from '@atlaskit/motion/fade-in';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { media } from '@atlaskit/primitives';
import { N0, N30A, N60A } from '@atlaskit/theme/colors';
import { CURRENT_SURFACE_CSS_VAR } from '@atlaskit/tokens';
import { borderRadius, textColor } from '../constants';
import { ModalContext, ScrollContext } from '../context';
import useOnMotionFinish from '../hooks/use-on-motion-finish';
import { disableDraggingToCrossOriginIFramesForElement } from '../pragmatic-drag-and-drop/disable-dragging-to-cross-origin-iframes/element';
import { disableDraggingToCrossOriginIFramesForExternal } from '../pragmatic-drag-and-drop/disable-dragging-to-cross-origin-iframes/external';
import { disableDraggingToCrossOriginIFramesForTextSelection } from '../pragmatic-drag-and-drop/disable-dragging-to-cross-origin-iframes/text-selection';
import { dialogHeight, dialogWidth } from '../utils';
import Positioner from './positioner';
var dialogStyles = css(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
  display: 'flex',
  width: '100%',
  maxWidth: '100vw',
  height: '100%',
  minHeight: 0,
  maxHeight: '100vh',
  // Flex-grow set to 0 to prevent this element from filling its parent flexbox container
  flex: '0 1 auto',
  flexDirection: 'column',
  backgroundColor: "var(--ds-surface-overlay, ".concat(N0, ")"),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  color: textColor
}, CURRENT_SURFACE_CSS_VAR, "var(--ds-surface-overlay, ".concat(N0, ")")), "pointerEvents", 'auto'), media.above.xs, {
  width: 'var(--modal-dialog-width)',
  maxWidth: 'inherit',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: borderRadius,
  boxShadow: "var(--ds-shadow-overlay, ".concat("0 0 0 1px ".concat(N30A, ", 0 2px 1px ").concat(N30A, ", 0 0 20px -6px ").concat(N60A), ")"),
  marginInlineEnd: 'inherit',
  marginInlineStart: 'inherit'
}), '& > form:only-child', {
  display: 'inherit',
  maxHeight: 'inherit',
  flexDirection: 'inherit'
}));
var viewportScrollStyles = css(_defineProperty({
  /**
   * This ensures that the element fills the viewport on mobile
   * while also allowing it to overflow if its height is larger than
   * the viewport.
   */
  minHeight: '100vh',
  maxHeight: 'none'
}, media.above.xs, {
  minHeight: 'var(--modal-dialog-height)'
}));
var bodyScrollStyles = css(_defineProperty({}, media.above.xs, {
  height: 'var(--modal-dialog-height)',
  maxHeight: 'inherit'
}));
var ModalDialog = function ModalDialog(props) {
  var _props$width = props.width,
    width = _props$width === void 0 ? 'medium' : _props$width,
    _props$shouldScrollIn = props.shouldScrollInViewport,
    shouldScrollInViewport = _props$shouldScrollIn === void 0 ? false : _props$shouldScrollIn,
    shouldCloseOnEscapePress = props.shouldCloseOnEscapePress,
    autoFocus = props.autoFocus,
    stackIndex = props.stackIndex,
    onClose = props.onClose,
    onCloseComplete = props.onCloseComplete,
    onOpenComplete = props.onOpenComplete,
    height = props.height,
    children = props.children,
    label = props.label,
    testId = props.testId;
  var id = useId();
  var titleId = "modal-dialog-title-".concat(id);
  useEffect(function () {
    // Modal dialogs can appear on top of iframe elements that are on another domain.
    // There is a Chrome bug where drag and drop in an element on top of a cross domain
    // iframe is not working. We are applying the workaround for this bug in modal so
    // that consumers of our modal don't have to worry about this bug and are free to
    // create whatever drag and drop experience they like inside a modal
    //
    // Chrome bug: https://issues.chromium.org/issues/362301053

    return combine(disableDraggingToCrossOriginIFramesForElement(), disableDraggingToCrossOriginIFramesForTextSelection(), disableDraggingToCrossOriginIFramesForExternal());
  }, []);
  useAutoFocus(_typeof(autoFocus) === 'object' ? autoFocus : undefined,
  // When a user supplies  a ref to focus we enable this hook
  _typeof(autoFocus) === 'object');
  var _useOnMotionFinish = useOnMotionFinish({
      onOpenComplete: onOpenComplete,
      onCloseComplete: onCloseComplete
    }),
    _useOnMotionFinish2 = _slicedToArray(_useOnMotionFinish, 2),
    motionRef = _useOnMotionFinish2[0],
    onMotionFinish = _useOnMotionFinish2[1];
  var modalDialogContext = useMemo(function () {
    return {
      testId: testId,
      titleId: titleId,
      onClose: onClose
    };
  }, [testId, titleId, onClose]);
  useCloseOnEscapePress({
    onClose: onClose,
    isDisabled: !shouldCloseOnEscapePress
  });
  var _useLayering = useLayering(),
    currentLevel = _useLayering.currentLevel;
  return jsx(Positioner, {
    stackIndex: stackIndex,
    shouldScrollInViewport: shouldScrollInViewport,
    testId: testId
  }, jsx(ModalContext.Provider, {
    value: modalDialogContext
  }, jsx(ScrollContext.Provider, {
    value: shouldScrollInViewport
  }, jsx(FadeIn, {
    entranceDirection: "bottom",
    onFinish: onMotionFinish
  }, function (bottomFadeInProps) {
    return jsx(FocusRing, null, jsx("section", _extends({}, bottomFadeInProps, {
      "aria-label": label,
      ref: mergeRefs([bottomFadeInProps.ref, motionRef])
      // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
      ,
      style: {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
        '--modal-dialog-width': dialogWidth(width),
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
        '--modal-dialog-height': dialogHeight(height)
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
      },
      css: [dialogStyles, shouldScrollInViewport ? viewportScrollStyles : bodyScrollStyles],
      role: "dialog",
      "aria-labelledby": label ? undefined : titleId,
      "data-testid": testId,
      "data-modal-stack": stackIndex,
      tabIndex: -1,
      "aria-modal": true,
      "data-ds--level": currentLevel
    }), children));
  }))));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default ModalDialog;