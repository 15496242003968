import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { COMMERCIAL, DEV, FEDRAMP_FEDEX, FEDRAMP_MODERATE, PRODUCTION, STAGING } from './index';
/**
 * Creates a mapping from perimeter to environment to domain, allowing simply substitutions
 * to minimize the amount of data we have to ship to the browser.
 */
export var domainMap = _defineProperty(_defineProperty(_defineProperty({}, COMMERCIAL, _defineProperty(_defineProperty({}, STAGING, 'stg.atlassian.com'), PRODUCTION, 'atlassian.com')), FEDRAMP_FEDEX, _defineProperty({}, STAGING, 'atlassian-fex.com')), FEDRAMP_MODERATE, _defineProperty(_defineProperty({}, STAGING, 'stg.atlassian-us-gov-mod.com'), PRODUCTION, 'atlassian-us-gov-mod.com'));

// Contains overrides for subdomains where the DomainKey is different from the subdomain
export var subdomainOverride = {};

/**
 * Contains overrides for domains where the pattern of subdomain only doesn't work.
 * Useful in situations where this is a global domain that won't vary per environment.
 * Priority is exact match > fallback to commercial production
 *
 * IF YOU ADD SOMETHING HERE YOU MUST EXPLICITLY ADD EVERY PERIMETER AND EVERY ENVIRONMENT
 * ANYTHING NOT SET EXPLICITLY WILL DEFEAULT TO COMMERCIAL/PRODUCTION
 */
export var fullDomainOverride = {
  analytics: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'analytics.atlassian.com')),
  // Marketing site
  confluence: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'confluence.atlassian.com')),
  // Atlassian design system documentation
  design: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'design.atlassian.com')),
  // Old staff intranet (now hello.atlassian.net)
  extranet: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'extranet.atlassian.com')),
  // Go links
  go: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'go.atlassian.com')),
  my: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'my.atlassian.com')),
  schema: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'schema.atlassian.com')),
  start: _defineProperty(_defineProperty({}, COMMERCIAL, _defineProperty(_defineProperty({}, PRODUCTION, 'start.atlassian.com'), STAGING, 'start.stg.atlassian.com')), FEDRAMP_MODERATE, _defineProperty(_defineProperty({}, PRODUCTION, 'start.atlassian-us-gov-mod.com'), STAGING, 'start.stg.atlassian-us-gov-mod.com')),
  surveys: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'surveys.atlassian.com')),
  'wac-cdn': _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'wac-cdn.atlassian.com')),
  integrations: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'integrations.atlassian.com')),
  permalink: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'permalink.atlassian.com')),
  id: _defineProperty(_defineProperty({}, COMMERCIAL, _defineProperty(_defineProperty(_defineProperty({}, PRODUCTION, 'id.atlassian.com'), STAGING, 'id.stg.internal.atlassian.com'), DEV, 'id.dev.internal.atlassian.com')), FEDRAMP_MODERATE, _defineProperty(_defineProperty({}, PRODUCTION, 'id.atlassian-us-gov-mod.com'), STAGING, 'id.stg.atlassian-us-gov-mod.com')),
  support: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'support.atlassian.com'))
};