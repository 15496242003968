/* eslint-disable @repo/internal/react/require-jsdoc */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
var fixedHeightStyles = css({
  height: '9rem'
});
export var EmptyViewWithFixedHeight = function EmptyViewWithFixedHeight(_ref) {
  var children = _ref.children,
    testId = _ref.testId;
  return jsx("div", {
    css: fixedHeightStyles,
    "data-testid": testId && "".concat(testId, "--empty-view-with-fixed-height")
  }, children);
};
var emptyViewContainerStyles = css({
  width: '50%',
  margin: 'auto',
  // eslint-disable-next-line @atlaskit/design-system/use-tokens-space
  padding: '10px',
  textAlign: 'center'
});
export var EmptyViewContainer = function EmptyViewContainer(props) {
  var children = props.children,
    testId = props.testId;
  return jsx("div", {
    css: emptyViewContainerStyles,
    "data-testid": testId && "".concat(testId, "--empty-view-container")
  }, children);
};