/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { useModal } from './hooks';
import { iconColor } from './internal/constants';
var titleStyles = css({
  display: 'flex',
  minWidth: 0,
  margin: "var(--ds-space-0, 0px)",
  gap: "var(--ds-space-100, 8px)",
  font: "var(--ds-font-heading-medium, normal 500 20px/24px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
});
var textStyles = css({
  minWidth: 0,
  /**
   * This ensures that the element fills the whole header space
   * and its content does not overflow (since flex items don't
   * shrink past its content size by default).
   */
  flex: '1 1 auto',
  wordWrap: 'break-word'
});
var iconStyles = css({
  flex: '0 0 auto',
  // The following properties have been added purely to avoid a global style override in Jira breaking alignment between the icon and title text.
  // https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-legacy-frontend/jira-atlaskit-module/src/main/resources/jira-atlaskit-module/css/adg3-general-overrides.less?at=master#24
  // When the override is removed, these can be removed.
  color: 'inherit',
  // eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
  fontSize: 'inherit',
  fontStyle: 'inherit',
  // eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
  fontWeight: 'inherit',
  // eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
  letterSpacing: 'inherit',
  // eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
  lineHeight: 'inherit'
});
var truncatedTextStyles = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});
var TitleIcon = function TitleIcon(_ref) {
  var appearance = _ref.appearance;
  var Icon = appearance === 'danger' ? ErrorIcon : WarningIcon;
  return jsx("span", {
    css: iconStyles
  }, jsx(Icon, {
    label: appearance,
    color: iconColor[appearance],
    spacing: "spacious"
  }));
};
/**
 * __Modal title__
 *
 * A modal title is used to display a title within a modal.
 *
 * - [Examples](https://atlassian.design/components/modal-dialog/examples)
 * - [Code](https://atlassian.design/components/modal-dialog/code)
 * - [Usage](https://atlassian.design/components/modal-dialog/usage)
 */
var ModalTitle = function ModalTitle(props) {
  var appearance = props.appearance,
    children = props.children,
    _props$isMultiline = props.isMultiline,
    isMultiline = _props$isMultiline === void 0 ? true : _props$isMultiline,
    userDefinedTestId = props.testId;
  var _useModal = useModal(),
    titleId = _useModal.titleId,
    modalTestId = _useModal.testId;
  var testId = userDefinedTestId || modalTestId && "".concat(modalTestId, "--title");
  return (
    // eslint-disable-next-line @atlaskit/design-system/use-heading
    jsx("h1", {
      css: titleStyles,
      "data-testid": testId
    }, appearance && jsx(TitleIcon, {
      appearance: appearance
    }), jsx("span", {
      id: titleId,
      css: [textStyles, !isMultiline && truncatedTextStyles],
      "data-testid": testId && "".concat(testId, "-text")
    }, children))
  );
};
export default ModalTitle;