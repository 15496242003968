/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import SuccessIcon from '@atlaskit/icon/glyph/editor/success';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { N200 } from '@atlaskit/theme/colors';
import { useGlobalTheme } from '@atlaskit/theme/components';
import { fontFamily as getFontFamily } from '@atlaskit/theme/constants';
import { h200 } from '@atlaskit/theme/typography';
import { FieldId } from './field-id-context';

/**
 * API for the internal `<Message />` component. This is not public API.
 */

/**
 * Public API of the various message components.
 */

var fontFamily = getFontFamily();

// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
var lightH200Styles = css(h200({
  theme: {
    mode: 'light'
  }
}));
// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
var darkH200Styles = css(h200({
  theme: {
    mode: 'dark'
  }
}));
var messageStyles = css({
  display: 'flex',
  marginTop: "var(--ds-space-050, 4px)",
  justifyContent: 'baseline',
  fontFamily: "".concat(fontFamily),
  fontWeight: 'normal'
});
var messageAppearanceStyles = {
  default: css({
    color: "var(--ds-text-subtlest, ".concat(N200, ")")
  }),
  error: css({
    color: "var(--ds-text-danger, #AE2A19)"
  }),
  valid: css({
    color: "var(--ds-text-success, #216E4E)"
  })
};
var iconWrapperStyles = css({
  display: 'flex'
});
var IconWrapper = function IconWrapper(_ref) {
  var children = _ref.children;
  return jsx("span", {
    css: iconWrapperStyles
  }, children);
};
var messageIcons = {
  error: jsx(ErrorIcon, {
    size: "small",
    label: "error"
  }),
  valid: jsx(SuccessIcon, {
    size: "small",
    label: "success"
  })
};
var Message = function Message(_ref2) {
  var children = _ref2.children,
    _ref2$appearance = _ref2.appearance,
    appearance = _ref2$appearance === void 0 ? 'default' : _ref2$appearance,
    fieldId = _ref2.fieldId,
    testId = _ref2.testId;
  var _useGlobalTheme = useGlobalTheme(),
    mode = _useGlobalTheme.mode;
  var icon = messageIcons[appearance];

  /**
   * The wrapping span is necessary to preserve spaces between children.
   * Otherwise the flex layout of the message will remove any whitespace
   * between children.
   *
   * If the child is just a string, this is not required and we can use one
   * less DOM element.
   */
  var content = typeof children === 'string' ? children : jsx("span", null, children);
  return jsx("div", {
    css: [mode === 'light' ? lightH200Styles : darkH200Styles, messageStyles, messageAppearanceStyles[appearance]],
    "data-testid": testId,
    id: fieldId,
    "aria-live": "polite"
  }, icon && jsx(IconWrapper, null, icon), content);
};

/**
 * __Helper message__
 *
 * A helper message tells the user what kind of input the field takes. For example, a helper message could be
 * 'Password should be more than 4 characters'
 *
 */
export var HelperMessage = function HelperMessage(_ref3) {
  var children = _ref3.children,
    testId = _ref3.testId;
  return jsx(FieldId.Consumer, null, function (fieldId) {
    return jsx(Message, {
      fieldId: fieldId ? "".concat(fieldId, "-helper") : undefined,
      testId: testId
    }, children);
  });
};

/**
 * __Error message__
 *
 * An error message is used to tell a user that the field input is invalid. For example, an error message could be
 * 'Invalid username, needs to be more than 4 characters'.
 *
 */
export var ErrorMessage = function ErrorMessage(_ref4) {
  var children = _ref4.children,
    testId = _ref4.testId;
  return jsx(FieldId.Consumer, null, function (fieldId) {
    return jsx(Message, {
      appearance: "error",
      fieldId: fieldId ? "".concat(fieldId, "-error") : undefined,
      testId: testId
    }, children);
  });
};

/**
 * __Valid message__
 *
 * A valid message is used to tell a user that the field input is valid. For example,
 * a helper message could be 'Nice one, this username is available'.
 *
 */
export var ValidMessage = function ValidMessage(_ref5) {
  var children = _ref5.children,
    testId = _ref5.testId;
  return jsx(FieldId.Consumer, null, function (fieldId) {
    return jsx(Message, {
      appearance: "valid",
      fieldId: fieldId ? "".concat(fieldId, "-valid") : undefined,
      testId: testId
    }, children);
  });
};