import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _excluded = ["api", "disableCurrentPageLogging", "loggingIntervalMillis", "loggingBufferMaxSize", "localMode", "eventLoggingApi", "eventLoggingApiForRetries", "disableLocalStorage", "ignoreWindowUndefined", "disableAllLogging", "initTimeoutMs", "disableNetworkKeepalive", "overrideStableID", "disableErrorLogging", "disableAutoMetricsLogging"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { isFedRamp } from '@atlaskit/atlassian-context';
import { EvaluationReason } from './compat/types';
import { PerimeterType } from './types';
export var getOptionsWithDefaults = function getOptionsWithDefaults(options) {
  return _objectSpread({
    /**
     * If more federal PerimeterTypes are added in the future, this should be updated so
     * that isFedRamp() === true always returns the strictest perimeter.
     */
    perimeter: isFedRamp() ? PerimeterType.FEDRAMP_MODERATE : PerimeterType.COMMERCIAL
  }, options);
};
export var shallowEquals = function shallowEquals(objectA, objectB) {
  if (!objectA && !objectB) {
    return true;
  }
  if (!objectA || !objectB) {
    return false;
  }
  var aEntries = Object.entries(objectA);
  var bEntries = Object.entries(objectB);
  if (aEntries.length !== bEntries.length) {
    return false;
  }
  var ascendingKeyOrder = function ascendingKeyOrder(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
      key1 = _ref3[0];
    var _ref4 = _slicedToArray(_ref2, 1),
      key2 = _ref4[0];
    return key1.localeCompare(key2);
  };
  aEntries.sort(ascendingKeyOrder);
  bEntries.sort(ascendingKeyOrder);
  for (var i = 0; i < aEntries.length; i++) {
    var _aEntries$i = _slicedToArray(aEntries[i], 2),
      aValue = _aEntries$i[1];
    var _bEntries$i = _slicedToArray(bEntries[i], 2),
      bValue = _bEntries$i[1];
    if (aValue !== bValue) {
      return false;
    }
  }
  return true;
};

/**
 * This method creates an instance of StatsigUser from the given set of identifiers and
 * attributes.
 */
export var toStatsigUser = function toStatsigUser(identifiers, customAttributes) {
  var user = {
    customIDs: identifiers,
    custom: customAttributes
  };
  if (identifiers.atlassianAccountId) {
    user.userID = identifiers.atlassianAccountId;
  }
  return user;
};
export var migrateInitializationOptions = function migrateInitializationOptions(options) {
  var api = options.api,
    disableCurrentPageLogging = options.disableCurrentPageLogging,
    loggingIntervalMillis = options.loggingIntervalMillis,
    loggingBufferMaxSize = options.loggingBufferMaxSize,
    localMode = options.localMode,
    eventLoggingApi = options.eventLoggingApi,
    eventLoggingApiForRetries = options.eventLoggingApiForRetries,
    disableLocalStorage = options.disableLocalStorage,
    ignoreWindowUndefined = options.ignoreWindowUndefined,
    disableAllLogging = options.disableAllLogging,
    _initTimeoutMs = options.initTimeoutMs,
    _disableNetworkKeepalive = options.disableNetworkKeepalive,
    _overrideStableID = options.overrideStableID,
    _disableErrorLogging = options.disableErrorLogging,
    _disableAutoMetricsLogging = options.disableAutoMetricsLogging,
    rest = _objectWithoutProperties(options, _excluded);
  return _objectSpread(_objectSpread({}, rest), {}, {
    networkConfig: {
      api: api,
      logEventUrl: eventLoggingApi ? eventLoggingApi + 'rgstr' : undefined,
      logEventFallbackUrls: eventLoggingApiForRetries ? [eventLoggingApiForRetries] : undefined,
      preventAllNetworkTraffic: localMode || !ignoreWindowUndefined && typeof window === 'undefined'
    },
    includeCurrentPageUrlWithEvents: !disableCurrentPageLogging,
    loggingIntervalMs: loggingIntervalMillis,
    loggingBufferMaxSize: loggingBufferMaxSize,
    disableStorage: disableLocalStorage,
    disableLogging: disableAllLogging
  });
};
var evaluationReasonMappings = Object.entries(EvaluationReason).map(function (_ref5) {
  var _ref6 = _slicedToArray(_ref5, 2),
    key = _ref6[0],
    value = _ref6[1];
  return [key.toLowerCase(), value];
});
export var migrateEvaluationDetails = function migrateEvaluationDetails(details) {
  var _evaluationReasonMapp, _evaluationReasonMapp2, _details$receivedAt;
  var reasonLower = details.reason.toLowerCase();
  return {
    reason: (_evaluationReasonMapp = (_evaluationReasonMapp2 = evaluationReasonMappings.find(function (_ref7) {
      var _ref8 = _slicedToArray(_ref7, 1),
        key = _ref8[0];
      return reasonLower.includes(key);
    })) === null || _evaluationReasonMapp2 === void 0 ? void 0 : _evaluationReasonMapp2[1]) !== null && _evaluationReasonMapp !== void 0 ? _evaluationReasonMapp : EvaluationReason.Unknown,
    time: (_details$receivedAt = details.receivedAt) !== null && _details$receivedAt !== void 0 ? _details$receivedAt : Date.now()
  };
};