/* eslint-disable no-console */
// TODO fix this linter and pull the console logs out when we get closer
import React from 'react';

import {
  Content,
  LeftSidebar,
  Main,
  PageLayout,
  TopNavigation,
} from '@atlaskit/page-layout';
import { Box, xcss } from '@atlaskit/primitives';

import { ContainerNavigation } from './container-navigation-v3';
import { HorizontalOrMobileNav } from './horizontal-or-mobile-nav';
import { NavigationV3Props } from './index-next';

export const IndexV3 = (props: NavigationV3Props): JSX.Element => {
  const {
    children,
    useContainerNavigationProps,
    containerLogo,
    containerName,
    containerHref,
    isPrivate,
    onLeftSidebarExpand,
    onLeftSidebarCollapse,
    onResizeStart,
    onResizeEnd,
  } = props;
  const containerNavigationProps = useContainerNavigationProps();

  // The "container" navigation is the route-specific navigation i.e. the "gray" nav area.
  // It is always rendered inside the mobile nav.
  // Outside of the mobile nav, it is always rendered unless the horizontal nav feature is
  // enabled AND the user is on a /dashboard, /, /account/settings/ and /account/workspaces/ routes.
  const shouldRenderContainerNav = (): boolean => {
    if (props.isMobileHeaderActive) {
      return false;
    } else if (props.navigationType === 'product') {
      return false;
    } else {
      return (
        window.location.pathname !== '/' &&
        window.location.pathname.indexOf('/dashboard/') !== 0 &&
        window.location.pathname.indexOf('/account/workspaces/') !== 0
      );
    }
  };

  return (
    <PageLayout
      onLeftSidebarExpand={onLeftSidebarExpand}
      onLeftSidebarCollapse={onLeftSidebarCollapse}
    >
      <Box xcss={xcss({ position: 'fixed', zIndex: 'dialog' })}>
        <TopNavigation height={56} isFixed>
          <HorizontalOrMobileNav {...props} />
        </TopNavigation>
      </Box>
      <Content testId="content">
        {shouldRenderContainerNav() && (
          <Box xcss={xcss({ position: 'relative', zIndex: 'navigation' })}>
            <LeftSidebar
              testId="left-sidebar"
              id="left-sidebar"
              skipLinkTitle="Project Navigation"
              resizeGrabAreaLabel="Resize Current project sidebar"
              resizeButtonLabel="Current project sidebar"
              valueTextLabel="Width"
              onResizeStart={onResizeStart}
              onResizeEnd={onResizeEnd}
            >
              <ContainerNavigation
                // TODO need to get these props pulled in somehow
                isGlobalContext={false}
                isPrivate={isPrivate || false}
                containerHref={containerHref || '/'}
                containerLogo={containerLogo || ''}
                containerName={containerName || ''}
                containerType=""
                {...containerNavigationProps}
              />
            </LeftSidebar>
          </Box>
        )}
        <Main id="main" skipLinkTitle="Main">
          {children}
        </Main>
      </Content>
    </PageLayout>
  );
};
