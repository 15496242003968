import { createSelector } from 'reselect';

import { DashboardState } from 'src/redux/dashboard/reducers';
import { getDashboard } from 'src/selectors/state-slicing-selectors';

import { DashboardPullRequestsState } from '../reducers/pull-requests';

export const getPullRequestsSlice = createSelector(
  getDashboard,
  (dashboard: DashboardState) => dashboard.pullRequests
);

export const getPullRequestsFilters = createSelector(
  getPullRequestsSlice,
  (slice: DashboardPullRequestsState) => slice.pullRequestFilters
);
