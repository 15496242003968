import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { fg } from '@atlaskit/platform-feature-flags';
import { ContextualConsentProperty, PreferenceCategory } from './types';
export var LEGACY_ATLASSIAN_GDPR_COOKIE_KEY = 'atl-gdpr-consent';
/**
 * @private
 * @deprecated Use versionedConsentCookieKey or get the value directly through
 * getConsentTokenForSchemaVersion, which will fallback to this key value if the
 * versioned cookie doesn't exist yet.
 */
export var ATLASSIAN_CONSENT_COOKIE_KEY = 'atl-bsc-consent-token';
export var ATLASSIAN_SHOW_BANNER_COOKIE_KEY = 'atl-bsc-show-banner';
// TODO: DEPRECATED: Locale no longer supported, but to ensure backwards compatibility, we'll keep this exported const
export var ATLASSIAN_CURRENT_LOCALE_COOKIE_KEY = 'atl-bsc-current-locale';
export var ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY = 'atl-bsc-consent-token-fallback';
export var TESTING_TOKEN_OVERRIDE_COOKIE_KEY = 'atl-testing-consents';
export var ATLASSIAN_BANNER_LAST_DISMISSED_TIME_COOKIE_KEY = 'atl-bsc-banner-dismissed-time';
export var ACCEPT_ALL_PREFERENCES = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, PreferenceCategory.StrictlyNecessary, true), PreferenceCategory.Functional, true), PreferenceCategory.Marketing, true), PreferenceCategory.Analytics, true), PreferenceCategory.Unknown, true);
export var ONLY_NECESSARY_PREFERENCES = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, PreferenceCategory.StrictlyNecessary, true), PreferenceCategory.Functional, false), PreferenceCategory.Marketing, false), PreferenceCategory.Analytics, false), PreferenceCategory.Unknown, false);

// Default preferences are now strict/"Only Necessary" by default for all users
// and for sake of ease, we'll classify them as "logged out"
export var DEFAULT_PREFERENCES = _objectSpread(_objectSpread({}, ONLY_NECESSARY_PREFERENCES), {}, _defineProperty(_defineProperty(_defineProperty({}, ContextualConsentProperty.ConsentsAreDefault, true), ContextualConsentProperty.UserIsAuthenticated, false), ContextualConsentProperty.ConsentDataUnavailable, true));
export var V001_SCHEMA_KEY = '001';
export var V002_SCHEMA_KEY = '002';
export var V003_SCHEMA_KEY = '003';
export var LATEST_SCHEMA_KEY = V003_SCHEMA_KEY;
export var versionedConsentCookieKey = function versionedConsentCookieKey(version) {
  return fg('platform_moonjelly_bsc_versioned_consent_key') ? "atl-bsc-consent-token-".concat(version) : ATLASSIAN_CONSENT_COOKIE_KEY;
};