// @ts-nocheck

var createCustomEvent = function createCustomEvent(_ref) {
  var eventName = _ref.eventName,
    canBubble = _ref.canBubble,
    cancellable = _ref.cancellable,
    detail = _ref.detail;
  var customEvent = document.createEvent('CustomEvent');
  customEvent.initCustomEvent(eventName, canBubble, cancellable, detail);
  return customEvent;
};
export default createCustomEvent;