import { ROUTE_NAME, RouteFeatures, SupportedPath } from '../types';

export const getSupportedPaths = (
  _features: RouteFeatures
): SupportedPath[] => {
  const paths = [
    {
      name: ROUTE_NAME.ROOT,
      path: '/',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_OVERVIEW,
      path: '/:workspaceSlug/workspace/overview',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_CREATE,
      path: '/workspace/create',
    },
    {
      name: ROUTE_NAME.WORKSPACE_ROOT,
      path: '/:workspaceSlug',
      exact: true,
    },
    {
      name: ROUTE_NAME.PROFILE_REPOSITORIES,
      path: '/:workspaceSlug/profile/repositories',
      exact: false,
    },
    {
      name: ROUTE_NAME.WORKSPACE_REPOSITORIES,
      path: '/:workspaceSlug/workspace/repositories',
      exact: false,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_ROOT,
      path: '/:workspaceSlug/workspace/settings',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_PERMISSIONS,
      path: '/:workspaceSlug/workspace/settings/permissions',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_GROUP,
      path: '/:workspaceSlug/workspace/settings/groups/:groupSlug',
      exact: false,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_GROUPS,
      path: '/:workspaceSlug/workspace/settings/groups',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_USER_DIRECTORY,
      path: '/:workspaceSlug/workspace/settings/user-directory',
      exact: false,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_ATLASSIAN_INTELLIGENCE,
      path: '/:workspaceSlug/workspace/settings/atlassian-intelligence',
      exact: false,
    },
    {
      // this is currently gated behind the feature flag workspace-admin-compass-pageß
      name: ROUTE_NAME.WORKSPACE_SETTINGS_COMPASS,
      path: '/:workspaceSlug/workspace/settings/compass',
      exact: false,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_MERGE_CHECKS,
      path: '/:workspaceSlug/workspace/settings/merge-checks',
      exact: false,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_REDIRECT_SETTINGS,
      path: '/:workspaceSlug/workspace/settings/addon/admin/:appKey(pipelines)/:moduleKey(account-variables)',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_REDIRECT_RUNNERS,
      path: '/:workspaceSlug/workspace/settings/addon/admin/:appKey(pipelines)/:moduleKey(account-runners)',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_CONNECT_ADMIN,
      path: '/:workspaceSlug/workspace/settings/addon/admin/:appKey/:moduleKey',
      exact: false,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_CONNECT_CONFIGURE,
      path: '/:workspaceSlug/workspace/settings/addon/configure/:appKey/:moduleKey',
      exact: false,
    },
  ];

  paths.push(
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_FORGE_PAGE,
      path: '/:workspaceSlug/workspace/settings/forge/:appId/:moduleKey',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_FORGE_PAGE,
      path: '/:workspaceSlug/workspace/settings/forge/:appId/:envKey/:moduleKey/:modulePath*',
      exact: true,
    }
  );

  paths.push(
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_ACCESS_TOKENS,
      path: '/:workspaceSlug/workspace/settings/access-tokens',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_VARIABLES,
      path: '/:workspaceSlug/workspace/settings/pipelines/:path(account-variables)',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_RUNNERS,
      path: '/:workspaceSlug/workspace/settings/pipelines/:path(account-runners)',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_RUNNERS,
      path: '/:workspaceSlug/workspace/settings/pipelines/runners',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_PIPELINES_DYNAMIC_PIPELINES,
      path: '/:workspaceSlug/workspace/settings/pipelines/dynamic-pipelines',
      exact: true,
    },
    {
      name: ROUTE_NAME.WORKSPACE_SETTINGS_IFRAME,
      path: '/:workspaceSlug/workspace/settings/:any',
      exact: false,
    },
    // this is behind `workspace-view` FF on BE
    {
      name: ROUTE_NAME.WORKSPACE_SEARCH,
      path: '/:workspaceSlug/workspace/search',
      exact: false,
    },
    {
      name: ROUTE_NAME.WORKSPACE_PULL_REQUESTS,
      path: '/:workspaceSlug/workspace/pull-requests',
      exact: false,
    },
    {
      name: ROUTE_NAME.WORKSPACE_PROJECTS,
      path: '/:workspaceSlug/workspace/projects',
      exact: true,
    }
  );

  return paths;
};
