import _defineProperty from "@babel/runtime/helpers/defineProperty";
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';
var CSS_VAR_WIDTH = '--local-dynamic-table-width';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var truncationWidthStyles = css({
  width: "var(".concat(CSS_VAR_WIDTH, ")")
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var fixedSizeTruncateStyles = css({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var overflowTruncateStyles = css({
  overflow: 'hidden'
});
export var getTruncationStyleVars = function getTruncationStyleVars(_ref) {
  var width = _ref.width;
  return typeof width !== 'undefined' ? _defineProperty({}, CSS_VAR_WIDTH, "".concat(width, "%")) : undefined;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var cellStyles = css({
  padding: "var(--ds-space-050, 4px)".concat(" ", "var(--ds-space-100, 8px)"),
  border: 'none',
  textAlign: 'left',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&:first-of-type': {
    paddingInlineStart: "var(--ds-space-0, 0px)"
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&:last-child': {
    paddingInlineEnd: "var(--ds-space-0, 0px)"
  }
});