import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'frontbucket.global.userpullrequestsremoval.title',
    description: 'User pull requests handler removal warning message title',
    defaultMessage: 'API endpoint removal',
  },
  description: {
    id: 'frontbucket.global.userpullrequestsremoval.description',
    description:
      'User pull requests handler removal warning message description',
    defaultMessage:
      "On Feb 20, 2025, Bitbucket will be removing the REST API that lists a user's pull requests. To learn about the replacement API endpoint and how to use it, {userPullRequestsRemovalLink}.",
  },
  userPullRequestsRemovalLink: {
    id: 'frontbucket.global.userpullrequestsremoval.supportPageLink',
    description: 'Text for link to deprecation notice',
    defaultMessage: 'read the deprecation notice',
  },
  dismiss: {
    id: 'frontbucket.global.userpullrequestsremoval.dismiss',
    description: 'Dismiss button label',
    defaultMessage: 'Dismiss',
  },
});
