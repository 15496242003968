import _extends from "@babel/runtime/helpers/extends";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { jsx } from '@emotion/react';
import { getStyleProps } from '../utils';
export var css = function css(_ref) {
  var isDisabled = _ref.isDisabled,
    isFocused = _ref.isFocused,
    isInvalid = _ref.isInvalid,
    isCompact = _ref.isCompact,
    appearance = _ref.appearance;
  var borderColor = isFocused ? "var(--ds-border-focused, #388BFF)" : "var(--ds-border-input, #8590A2)";
  var backgroundColor = isFocused ? "var(--ds-background-input-pressed, #FFFFFF)" : "var(--ds-background-input, #FFFFFF)";
  var backgroundColorHover = isFocused ? "var(--ds-background-input-pressed, #FFFFFF)" : "var(--ds-background-input-hovered, #F7F8F9)";
  var borderColorHover = isFocused ? "var(--ds-border-focused, #388BFF)" : "var(--ds-border-input, #8590A2)";
  if (isDisabled) {
    backgroundColor = "var(--ds-background-disabled, #091E4208)";
    borderColor = "var(--ds-background-disabled, #091E4208)";
  }
  if (isInvalid) {
    borderColor = "var(--ds-border-danger, #E2483D)";
    borderColorHover = "var(--ds-border-danger, #E2483D)";
  }
  var transitionDuration = '200ms';
  if (appearance === 'subtle') {
    borderColor = isFocused ? "var(--ds-border-focused, #388BFF)" : 'transparent';
    backgroundColor = isFocused ? "var(--ds-surface, #FFFFFF)" : 'transparent';
    backgroundColorHover = isFocused ? "var(--ds-background-input-pressed, #FFFFFF)" : "var(--ds-background-input-hovered, #F7F8F9)";
  }
  if (appearance === 'none') {
    borderColor = 'transparent';
    backgroundColor = 'transparent';
    backgroundColorHover = 'transparent';
    borderColorHover = 'transparent';
  }
  return {
    label: 'control',
    alignItems: 'center',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    outline: '0 !important',
    position: 'relative',
    // Turn pointer events off when disabled - this makes it so hover etc don't work.
    pointerEvents: isDisabled ? 'none' : undefined,
    backgroundColor: backgroundColor,
    borderColor: borderColor,
    borderStyle: 'solid',
    borderRadius: "var(--ds-border-radius-100, 3px)",
    borderWidth: "var(--ds-border-width, 1px)",
    boxShadow: isInvalid ? "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", borderColor) : 'none',
    '&:focus-within': {
      boxShadow: "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", borderColor)
    },
    minHeight: isCompact ? 32 : 40,
    padding: 0,
    transition: "background-color ".concat(transitionDuration, " ease-in-out,\nborder-color ").concat(transitionDuration, " ease-in-out"),
    '::-webkit-scrollbar': {
      height: 8,
      width: 8
    },
    '::-webkit-scrollbar-corner': {
      display: 'none'
    },
    ':hover': {
      '::-webkit-scrollbar-thumb': {
        // scrollbars occur only if the user passes in a custom component with overflow: scroll
        backgroundColor: 'rgba(0,0,0,0.2)'
      },
      cursor: 'pointer',
      backgroundColor: backgroundColorHover,
      borderColor: borderColorHover
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(0,0,0,0.4)'
    }
  };
};
var Control = function Control(props) {
  var children = props.children,
    isDisabled = props.isDisabled,
    isFocused = props.isFocused,
    innerRef = props.innerRef,
    innerProps = props.innerProps,
    menuIsOpen = props.menuIsOpen;
  return jsx("div", _extends({
    ref: innerRef
  }, getStyleProps(props, 'control', {
    control: true,
    'control--is-disabled': isDisabled,
    'control--is-focused': isFocused,
    'control--menu-is-open': menuIsOpen
  }), innerProps, {
    "aria-disabled": isDisabled || undefined
  }), children);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Control;