/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import useScrollCapture from './use-scroll-capture';
import useScrollLock from './use-scroll-lock';
var styles = css({
  position: 'fixed',
  insetBlockEnd: 0,
  insetBlockStart: 0,
  insetInlineEnd: 0,
  insetInlineStart: 0
});
var blurSelectInput = function blurSelectInput(event) {
  var element = event.target;
  return element.ownerDocument.activeElement && element.ownerDocument.activeElement.blur();
};
export default function ScrollManager(_ref) {
  var children = _ref.children,
    lockEnabled = _ref.lockEnabled,
    _ref$captureEnabled = _ref.captureEnabled,
    captureEnabled = _ref$captureEnabled === void 0 ? true : _ref$captureEnabled,
    onBottomArrive = _ref.onBottomArrive,
    onBottomLeave = _ref.onBottomLeave,
    onTopArrive = _ref.onTopArrive,
    onTopLeave = _ref.onTopLeave;
  var setScrollCaptureTarget = useScrollCapture({
    isEnabled: captureEnabled,
    onBottomArrive: onBottomArrive,
    onBottomLeave: onBottomLeave,
    onTopArrive: onTopArrive,
    onTopLeave: onTopLeave
  });
  var setScrollLockTarget = useScrollLock({
    isEnabled: lockEnabled
  });
  var targetRef = function targetRef(element) {
    setScrollCaptureTarget(element);
    setScrollLockTarget(element);
  };
  return jsx(Fragment, null, lockEnabled &&
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  jsx("div", {
    onClick: blurSelectInput,
    css: styles
  }), children(targetRef));
}