import { fg } from '@atlaskit/platform-feature-flags';
import { ATLASSIAN_CONSENT_COOKIE_KEY, versionedConsentCookieKey } from '../../../constants';
import { getCookie } from '../get-cookie';
import { setStrictlyNecessaryCookie } from '../set-strictly-necessary-cookie';
import { checkTokenVersionValidity } from '../transformer';

/**
 * Starting with schema version 003, append the version number to the end of the
 * cookie key. This is in response to prior issues caused by different apps on
 * the same domain using different versions of the schema. See this HOT:
 * https://ops.internal.atlassian.com/jira/browse/PIR-21254
 */
export var getConsentTokenForSchemaVersion = function getConsentTokenForSchemaVersion(version) {
  // If the feature flag is off, we should always use the non-versioned cookie key.
  if (!fg('platform_moonjelly_bsc_versioned_consent_key')) {
    return getCookie(ATLASSIAN_CONSENT_COOKIE_KEY);
  }
  var versionedToken = getCookie(versionedConsentCookieKey(version));
  if (versionedToken) {
    return versionedToken;
  } else {
    // Copy the non-versioned cookie key value to the versioned cookie key value.
    var nonVersionedToken = getCookie(ATLASSIAN_CONSENT_COOKIE_KEY);
    if (nonVersionedToken) {
      var _checkTokenVersionVal = checkTokenVersionValidity(nonVersionedToken),
        isV003 = _checkTokenVersionVal.isV003;
      if (isV003) {
        setStrictlyNecessaryCookie(versionedConsentCookieKey(version), nonVersionedToken);
      }
    }
    return nonVersionedToken;
  }
};