import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useRef, useState } from 'react';

/**
 * Get the scrollbar width of the container that the ref is assigned.
 *
 * ```js
 * const { width, ref } = useScrollbarWidth();
 * ```
 *
 * @returns Scrollbar width of the container and the element ref.
 */
export default function useScrollbarWidth() {
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    scrollbarWidth = _useState2[0],
    setScrollbarWidth = _useState2[1];
  var elementRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(function () {
    if (!elementRef.current) {
      return;
    }
    var width = elementRef.current.offsetWidth - elementRef.current.scrollWidth;
    if (width === scrollbarWidth) {
      return;
    }
    setScrollbarWidth(width);
  });
  return {
    width: scrollbarWidth,
    ref: elementRef
  };
}