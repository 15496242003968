import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/extends";
var _excluded = ["innerProps", "isRtl", "size"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { jsx } from '@emotion/react';
import DownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import CrossIcon from '@atlaskit/icon/utility/migration/cross-circle--select-clear';
import { Inline, Pressable, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { getStyleProps } from '../utils';

// ==============================
// Dropdown & Clear Icons
// ==============================

var iconContainerStyles = xcss({
  all: 'unset',
  outline: 'revert',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 'space.025'
});
var dropdownWrapperStyles = xcss({
  padding: 'space.075'
});

// ==============================
// Dropdown & Clear Buttons
// ==============================

export var dropdownIndicatorCSS = function dropdownIndicatorCSS(_ref) {
  var isCompact = _ref.isCompact,
    isDisabled = _ref.isDisabled;
  return {
    label: 'indicatorContainer',
    display: 'flex',
    transition: 'color 150ms',
    color: isDisabled ? "var(--ds-text-disabled, #091E424F)" : "var(--ds-text-subtle, #44546F)",
    padding: "".concat(isCompact ? 0 : "var(--ds-space-075, 6px)", " ", "var(--ds-space-025, 2px)"),
    ':hover': {
      color: "var(--ds-text-subtle, #44546F)"
    }
  };
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var DropdownIndicator = function DropdownIndicator(props) {
  var innerProps = props.innerProps,
    children = props.children;
  return jsx("div", _extends({}, getStyleProps(props, 'dropdownIndicator', {
    indicator: true,
    'dropdown-indicator': true
  }), innerProps), children ? children : jsx(Inline, {
    as: "span",
    xcss: dropdownWrapperStyles
  }, jsx(DownIcon, {
    color: "currentColor",
    label: "open",
    LEGACY_margin: "var(--ds-space-negative-075, -0.375rem)"
  })));
};
export var clearIndicatorCSS = function clearIndicatorCSS(_ref2) {
  var isCompact = _ref2.isCompact;
  return {
    label: 'indicatorContainer',
    display: 'flex',
    transition: 'color 150ms',
    color: "var(--ds-text-subtlest, #626F86)",
    padding: "".concat(isCompact ? 0 : "var(--ds-space-075, 6px)", " ", "var(--ds-space-025, 2px)"),
    ':hover': {
      color: "var(--ds-text-subtle, #44546F)"
    }
  };
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var ClearIndicator = function ClearIndicator(props) {
  var innerProps = props.innerProps,
    _props$clearControlLa = props.clearControlLabel,
    clearControlLabel = _props$clearControlLa === void 0 ? 'clear' : _props$clearControlLa;
  return jsx("div", _extends({}, getStyleProps(props, 'clearIndicator', {
    indicator: true,
    'clear-indicator': true
  }), innerProps), jsx(Pressable, {
    xcss: iconContainerStyles,
    tabIndex: -1,
    "aria-label": clearControlLabel
  }, jsx(CrossIcon, {
    label: "",
    color: "currentColor",
    LEGACY_size: "small",
    LEGACY_margin: "var(--ds-space-negative-025, -0.125rem)"
  })));
};

// ==============================
// Loading
// ==============================

export var loadingIndicatorCSS = function loadingIndicatorCSS(_ref3) {
  var isCompact = _ref3.isCompact;
  return {
    label: 'loadingIndicator',
    padding: "".concat(isCompact ? 0 : "var(--ds-space-075, 6px)", " ", "var(--ds-space-100, 8px)")
  };
};
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var LoadingIndicator = function LoadingIndicator(_ref4) {
  var innerProps = _ref4.innerProps,
    isRtl = _ref4.isRtl,
    _ref4$size = _ref4.size,
    size = _ref4$size === void 0 ? 4 : _ref4$size,
    restProps = _objectWithoutProperties(_ref4, _excluded);
  return jsx("div", _extends({}, getStyleProps(_objectSpread(_objectSpread({}, restProps), {}, {
    innerProps: innerProps,
    isRtl: isRtl,
    size: size
  }), 'loadingIndicator', {
    indicator: true,
    'loading-indicator': true
  }), innerProps), jsx(Spinner, {
    size: "small"
  }));
};