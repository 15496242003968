import { createResource, RouterContext } from 'react-resource-router';

import { AppData } from 'src/app/data';
import { ResourceContext } from 'src/router/types';
import { PRFilterState } from 'src/sections/repository/sections/pull-request-list/types';
import { PullRequest } from 'src/types/pull-requests-table';
import { stringify } from 'src/urls/utils';
import urls from 'src/urls/workspaces';
import escapeSearchString from 'src/utils/escape-bbql-string';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import {
  FilterType,
  PRUserFilterType,
  Query,
  WorkspacePullRequestsQuery,
} from './types';

const FIELDS = [
  '-values.closed_by',
  '-values.description',
  '-values.summary',
  '-values.rendered',
  '-values.properties',
  '-values.reason',
  '-values.reviewers',
  '-values.participants.user.nickname',
  '+values.destination.branch.name',
  '+values.destination.repository.full_name',
  '+values.destination.repository.name',
  '+values.destination.repository.uuid',
  '+values.destination.repository.full_name',
  '+values.destination.repository.name',
  '+values.destination.repository.links.self.href',
  '+values.destination.repository.links.html.href',
  '+values.source.branch.name',
  '+values.source.repository.full_name',
  '+values.source.repository.name',
  '+values.source.repository.uuid',
  '+values.source.repository.full_name',
  '+values.source.repository.name',
  '+values.source.repository.links.self.href',
  '+values.source.repository.links.html.href',
  '+values.source.commit.hash',
].join(',');

const buildBBQL = (
  {
    search: inputSearch,
    prState,
    prUserFilter,
    authorsAaid,
    project,
  }: FilterType,
  user?: AppData['user']
) => {
  const bbql: string[] = [];

  const validStates = ['MERGED', 'DECLINED', 'OPEN', 'DRAFT'];

  const states: string[] = [];
  if (prState.indexOf('ALL') === -1) {
    prState.forEach(state => {
      if (validStates.indexOf(state) !== -1) {
        if (state === 'DRAFT') {
          states.push('( state="OPEN" AND draft=true )');
        } else if (state === 'OPEN') {
          states.push('( state="OPEN" AND draft=false )');
        } else {
          states.push(`state="${state}"`);
        }
      }
    });
    bbql.push(`(${states.join(' OR ')})`);
  }

  if (user) {
    if (prUserFilter === 'REVIEWING') {
      bbql.push(`reviewers.uuid="${user.uuid}"`);
    } else if (prUserFilter === 'WATCHING') {
      bbql.push(`followers.uuid="${user.uuid}"`);
    }
    // For the third/last case, if `prUserFilter` is `ALL` then we do not filter.
  }

  if (project) {
    bbql.push(`source.repository.project.uuid="${project}"`);
  }

  const search = escapeSearchString(inputSearch || '');

  if (search) {
    const conditions = [`description~"${search}"`, `title~"${search}"`];

    // Extract numbers from the search
    const numerics = search.match(/\d+/g) || [];
    // Try to match them to a pull request ID
    const pullRequestIds = [...new Set(numerics)].map(id => `id=${id}`);
    conditions.push(...pullRequestIds);

    bbql.push(`(${conditions.join(' OR ')})`);
  }

  if (authorsAaid) {
    const aaids = authorsAaid.map(
      (aaid: string) => `author.account_id="${aaid}"`
    );
    bbql.push(`(${aaids.join(' OR ')})`);
  }

  return bbql.join(' AND ');
};

const PAGE_LEN = 20;

type ResponseData = {
  values?: PullRequest[];
  pagelen: number;
  size: number;
  page: number;
};

export const parseQuery = (query: Query): WorkspacePullRequestsQuery => {
  const parsedPage = parseInt(query.page, 10);

  const prUserFilter = (query.user_filter as PRUserFilterType) || 'REVIEWING';

  return {
    page: !isNaN(parsedPage) ? Math.max(parsedPage, 1) : 1,
    search: query.query,
    prState: (query.state?.split('+') as PRFilterState[]) || ['OPEN', 'DRAFT'],
    prUserFilter,
    authorsAaid: query.author ? query.author.split('+') : undefined,
    project: query.project,
  };
};

export const workspacePullRequestsResource = createResource({
  type: 'WORKSPACE_PULL_REQUESTS',
  getKey: ({ match, query }: RouterContext) => {
    // Strip out query params unrelated to fetching the pull request list
    const parsedQuery = parseQuery(query);
    const filteredQueryString = stringify(parsedQuery);
    return `workspace:${match.params.workspaceSlug}::pullRequests:${filteredQueryString}`;
  },
  maxAge: 30 * 1000, // 30 seconds
  getData: async (
    { match, query },
    { csrftoken, appData }: ResourceContext
  ) => {
    const { workspaceSlug } = match.params;

    // If we can't match the URL to a workspace slug, this resource is being used on a route
    // it shouldn't be.
    if (!workspaceSlug) {
      return null;
    }

    const { page, search, prState, prUserFilter, authorsAaid, project } =
      parseQuery(query);

    const filter = { search, prState, prUserFilter, authorsAaid, project };

    const { user } = appData;

    const q = buildBBQL(filter, user);

    const params = stringify({
      fields: FIELDS,
      page,
      pagelen: PAGE_LEN,
      q,
    });

    const response: Response = await fetch(
      authRequest(
        `${urls.api.internal.pullRequests(workspaceSlug)}/${params}`,
        { headers: jsonHeaders },
        csrftoken
      )
    );

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const responseData: ResponseData = await response.json();
    const { size, pagelen } = responseData;
    const pullRequests: PullRequest[] | undefined = responseData.values;

    return { size, pagelen, pullRequests };
  },
});
