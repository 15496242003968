import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export function getMaxSizeModifiers(_ref) {
  var viewportPadding = _ref.viewportPadding;
  return [{
    /**
     * Performing DOM measurements in the 'read' phase,
     * which is the convention for popper modifiers
     */
    name: 'maxSizeData',
    enabled: true,
    phase: 'read',
    fn: function fn(_ref2) {
      var state = _ref2.state,
        name = _ref2.name;
      if (!window.visualViewport) {
        return;
      }
      state.modifiersData[name] = {
        viewport: {
          width: window.visualViewport.width,
          height: window.visualViewport.height
        }
      };
    }
  }, {
    /**
     * Applying max size CSS
     */
    name: 'maxSize',
    enabled: true,
    phase: 'beforeWrite',
    requiresIfExists: ['offset', 'preventOverflow', 'flip'],
    fn: function fn(_ref3) {
      var _data$viewport, _data$viewport2, _state$modifiersData$2, _state$modifiersData;
      var state = _ref3.state;
      var data = state.modifiersData.maxSizeData;
      if (typeof (data === null || data === void 0 || (_data$viewport = data.viewport) === null || _data$viewport === void 0 ? void 0 : _data$viewport.width) !== 'number' || typeof (data === null || data === void 0 || (_data$viewport2 = data.viewport) === null || _data$viewport2 === void 0 ? void 0 : _data$viewport2.height) !== 'number') {
        // This shouldn't occur in a real browser but might in non-browser test environments
        return;
      }
      var viewport = data.viewport;
      var _state$modifiersData$ = state.modifiersData.popperOffsets,
        popperOffsets = _state$modifiersData$ === void 0 ? {
          x: 0,
          y: 0
        } : _state$modifiersData$;
      var _state$placement$spli = state.placement.split('-'),
        _state$placement$spli2 = _slicedToArray(_state$placement$spli, 1),
        basePlacement = _state$placement$spli2[0];
      var placementOffset = (_state$modifiersData$2 = (_state$modifiersData = state.modifiersData) === null || _state$modifiersData === void 0 || (_state$modifiersData = _state$modifiersData.offset) === null || _state$modifiersData === void 0 ? void 0 : _state$modifiersData[state.placement]) !== null && _state$modifiersData$2 !== void 0 ? _state$modifiersData$2 : {
        x: 0,
        y: 0
      };

      // By default we set these to the entire viewport (minus padding)
      // Each placement direction will overwrite one of these
      var maxWidth = viewport.width - 2 * viewportPadding;
      var maxHeight = viewport.height - 2 * viewportPadding;
      if (basePlacement === 'top') {
        maxHeight = state.rects.reference.y +
        // Viewport-relative position of reference element
        placementOffset.y -
        // Space between popper and reference
        viewportPadding;
      }
      if (basePlacement === 'bottom') {
        maxHeight = viewport.height - popperOffsets.y -
        // Viewport-relative position of popper
        viewportPadding;
      }
      if (basePlacement === 'left') {
        maxWidth = state.rects.reference.x +
        // Viewport-relative position of reference element
        placementOffset.x -
        // Space between popper and reference
        viewportPadding;
      }
      if (basePlacement === 'right') {
        maxWidth = viewport.width - popperOffsets.x -
        // Viewport-relative position of popper
        viewportPadding;
      }
      state.styles.popper.maxWidth = "".concat(maxWidth, "px");
      state.styles.popper.maxHeight = "".concat(maxHeight, "px");
    }
  }];
}