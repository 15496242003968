import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import chromatism from 'chromatism';
export var hexToRGBA = function hexToRGBA(hex) {
  var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var rgba = _objectSpread(_objectSpread({}, chromatism.convert(hex).rgb), {
    a: opacity
  });
  return "rgba(".concat(Object.values(rgba).join(', '), ")");
};
export var getBoxShadow = function getBoxShadow(color) {
  return "0 0 0 2px ".concat(color);
};
var hex = '[a-z0-9]';
var shortHandHexColorPattern = new RegExp("#(".concat(hex, ")(").concat(hex, ")(").concat(hex, ")"), 'i');
var repeat = function repeat(str, times) {
  return _toConsumableArray(Array(times)).map(function () {
    return str;
  }).join('');
};
var isShortHexColor = function isShortHexColor(color) {
  return color && color.length === 4;
};
var completeHexColor = function completeHexColor(colors) {
  var hex = colors.map(function (color) {
    return repeat(color, 2);
  }).join('');
  return "#".concat(hex);
};
var completeTriplet = function completeTriplet(colors) {
  return "#".concat(repeat(colors[1], 6));
};
var isTriplet = function isTriplet(colors) {
  return colors[0] === colors[1] && colors[1] === colors[2];
};
export var convertHexShorthand = function convertHexShorthand(color) {
  if (isShortHexColor(color)) {
    // when color = '#ccc', matches is structured as ['#ccc', 'c', 'c', 'c', ...]
    var matches = color.match(shortHandHexColorPattern);
    if (matches) {
      var colors = matches.slice(1, 4);
      if (isTriplet(colors)) {
        return completeTriplet(colors);
      } else {
        return completeHexColor(colors);
      }
    }
  }

  // return the color as is when it's not hex color shorthand
  return color;
};
export var generateTextColor = function generateTextColor(color) {
  var converted = convertHexShorthand(color);
  return chromatism.contrastRatio(converted).hex;
};
export var getContrastColor = function getContrastColor(contrastValue, opacityValue, color) {
  return hexToRGBA(chromatism.contrast(contrastValue, color).hex, opacityValue);
};