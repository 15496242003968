import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { DashboardState } from 'src/redux/dashboard/reducers';
import { repository as repositorySchema } from 'src/sections/repository/schemas';
import {
  getDashboard,
  getEntities,
} from 'src/selectors/state-slicing-selectors';

import { DashboardRepositoriesState } from '../reducers/repositories';

const getRepositoryListSlice = createSelector(
  getDashboard,
  (dashboard: DashboardState) => dashboard.repositories
);

const getRepositoryKeys = createSelector(
  getRepositoryListSlice,
  (slice: DashboardRepositoriesState) => slice.repositories
);

export const getRepositories = createSelector(
  getRepositoryKeys,
  getEntities,
  (keys, entities) => denormalize(keys, [repositorySchema], entities)
);
