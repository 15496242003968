import React from 'react';

import { FormattedMessage } from 'react-intl';

import Flag from '@atlaskit/flag';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';
import atlassianIntelligenceUrls from 'src/sections/workspace/sections/settings/components/atlassian-intelligence/urls';
import store from 'src/utils/store';

import messages from './user-ai-onboarding-flag.i18n';
import { AILogoWrapper } from './user-ai-onboarding-flag.style';

const userAiOnboardingFlagDismissedKey = 'user-ai-onboarding-flag-dismissed';

export const getUserAiOnboardingFlagDismissed = (): boolean => {
  return store.get(userAiOnboardingFlagDismissedKey);
};

type Props = {
  id: ComponentFlagId;
} & HandleDismissedType;

export const UserAiOnboardingFlag = ({
  id,
  handleDismissed,
}: Props): JSX.Element => {
  const intl = useIntl();
  const dismiss = () => {
    store.set(userAiOnboardingFlagDismissedKey, true);
    handleDismissed(id);
  };

  const actions = [
    {
      content: intl.formatMessage(messages.userAiOnboardingFlagLearnMore),
      onClick: () => dismiss(),
      href: atlassianIntelligenceUrls.ui.learnMore(),
      target: '_blank',
    },
  ];

  const description = (
    <FormattedMessage
      {...messages.userAiOnboardingFlagDescription}
      tagName="span"
      values={{
        editorToolbar: (
          <b>
            <FormattedMessage {...messages.userAiOnboardingFlagEditorToolbar} />
          </b>
        ),
        aiShortcut: <b>`/ai`</b>,
      }}
    />
  );

  return (
    <Flag
      id={id}
      description={description}
      icon={
        <AILogoWrapper>
          <AtlassianIntelligenceIcon label="user-ai-onboarding" />
        </AILogoWrapper>
      }
      title={intl.formatMessage(messages.userAiOnboardingFlagTitle)}
      actions={actions}
      onDismissed={() => dismiss()}
    />
  );
};
