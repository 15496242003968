import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/extends";
import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
var _excluded = ["data"];
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { jsx } from '@emotion/react';
import { cleanCommonProps, getStyleProps } from '../utils';
export var groupCSS = function groupCSS(_ref) {
  _objectDestructuringEmpty(_ref);
  return {
    paddingBottom: "var(--ds-space-100, 8px)",
    paddingTop: "var(--ds-space-100, 8px)"
  };
};
var Group = function Group(props) {
  var children = props.children,
    cx = props.cx,
    getStyles = props.getStyles,
    getClassNames = props.getClassNames,
    Heading = props.Heading,
    headingProps = props.headingProps,
    innerProps = props.innerProps,
    label = props.label,
    selectProps = props.selectProps;
  return jsx("div", _extends({}, getStyleProps(props, 'group', {
    group: true
  }), innerProps), jsx(Heading, _extends({}, headingProps, {
    selectProps: selectProps,
    getStyles: getStyles,
    getClassNames: getClassNames,
    cx: cx
  }), label), jsx("div", null, children));
};
export var groupHeadingCSS = function groupHeadingCSS(_ref2) {
  _objectDestructuringEmpty(_ref2);
  return {
    label: 'group',
    cursor: 'default',
    display: 'block',
    marginBottom: '0.25em',
    paddingLeft: "var(--ds-space-150, 12px)",
    paddingRight: "var(--ds-space-150, 12px)",
    font: "var(--ds-font-body-small, normal 400 11px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
    color: "var(--ds-text-subtle, #44546F)",
    fontWeight: "var(--ds-font-weight-bold, 700)",
    textTransform: 'none'
  };
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var GroupHeading = function GroupHeading(props) {
  var _cleanCommonProps = cleanCommonProps(props),
    data = _cleanCommonProps.data,
    innerProps = _objectWithoutProperties(_cleanCommonProps, _excluded);
  return jsx("div", _extends({}, getStyleProps(props, 'groupHeading', {
    'group-heading': true
  }), innerProps));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Group;