import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
var ignoredTriggerLatestEvents = new Set(['editor-context-payload', 'agent-changed']);
var createPubSub = function createPubSub() {
  var subscribedEvents = {};
  var publishQueue = {};
  var wildcardEvents = [];
  var subscribe = function subscribe(_ref, callback) {
    var _subscribedEvents$top;
    var topic = _ref.topic,
      triggerLatest = _ref.triggerLatest;
    var events = (_subscribedEvents$top = subscribedEvents[topic]) !== null && _subscribedEvents$top !== void 0 ? _subscribedEvents$top : [];
    var subId = events.length.toString();
    var subExists = events.some(function (_ref2) {
      var id = _ref2.id;
      return id === subId;
    });

    // Push to Topic stack if not already there
    if (!subExists) {
      subscribedEvents = _objectSpread(_objectSpread({}, subscribedEvents), {}, _defineProperty({}, topic, [].concat(_toConsumableArray(events), [{
        callback: callback,
        id: subId
      }])));
      // If this Topic already has a published event and `triggerLatest` is true, trigger the callback then clear the publishQueue for that Topic
      if (triggerLatest && !!publishQueue[topic]) {
        var _payload = publishQueue[topic];
        callback(_payload);
        delete publishQueue[topic];
      }
    }
    return function () {
      // Remove from Topic stack
      subscribedEvents = _objectSpread(_objectSpread({}, subscribedEvents), {}, _defineProperty({}, topic, (subscribedEvents[topic] || []).filter(function (_ref3) {
        var id = _ref3.id;
        return id !== subId;
      })));
    };
  };
  var subscribeAll = function subscribeAll(callback) {
    var subId = wildcardEvents.length.toString();
    wildcardEvents = [].concat(_toConsumableArray(wildcardEvents), [{
      callback: callback,
      id: subId
    }]);
    return function () {
      wildcardEvents = wildcardEvents.filter(function (_ref4) {
        var id = _ref4.id;
        return id !== subId;
      });
    };
  };
  var publish = function publish(topic, payload) {
    /**
     * Log that this Topic received a published event, regardless of whether it has subscribers or not.
     * This ensures new subscribers can trigger their callback if `triggerLatest` is true, and the event hasn't already been triggered.
     */
    // This `ignoredTriggerLatestEvents` is a quick fix to prevent triggering the latest event for certain events
    if (!ignoredTriggerLatestEvents.has(payload.type)) {
      publishQueue[topic] = payload;
    }

    // Notify `subscribeAll` subscribers as they are Topic agnostic
    wildcardEvents.forEach(function (_ref5) {
      var callback = _ref5.callback;
      return callback(payload);
    });
    var topicSubs = subscribedEvents[topic] || [];

    // If there are no subscribers for this Topic, nothing to do.
    if (!topicSubs.length) {
      return;
    }

    // Notify all Topic subscribers of this event
    topicSubs.forEach(function (_ref6) {
      var callback = _ref6.callback;
      return callback(payload);
    });
  };
  var flushQueue = function flushQueue() {
    publishQueue = {};
  };
  return {
    subscribe: subscribe,
    subscribeAll: subscribeAll,
    publish: publish,
    flushQueue: flushQueue
  };
};
var pubSub = createPubSub();
var usePubSub = function usePubSub() {
  return pubSub;
};
export var useSubscribe = function useSubscribe(_ref7, callback) {
  var topic = _ref7.topic,
    triggerLatest = _ref7.triggerLatest;
  var _usePubSub = usePubSub(),
    subscribe = _usePubSub.subscribe;
  var callbackRef = useRef(callback);
  callbackRef.current = callback;
  useEffect(function () {
    var unsubscribe = subscribe({
      topic: topic,
      triggerLatest: triggerLatest
    }, function () {
      return callbackRef.current.apply(callbackRef, arguments);
    });
    return unsubscribe;
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [topic]);
};
export var useSubscribeAll = function useSubscribeAll(callback) {
  var _usePubSub2 = usePubSub(),
    subscribeAll = _usePubSub2.subscribeAll;
  var callbackRef = useRef(callback);
  callbackRef.current = callback;
  useEffect(function () {
    var unsubscribe = subscribeAll(function () {
      return callbackRef.current.apply(callbackRef, arguments);
    });
    return unsubscribe;
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
};
var useFlushOnUnmount = function useFlushOnUnmount() {
  var active = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var _usePubSub3 = usePubSub(),
    flushQueue = _usePubSub3.flushQueue;
  useLayoutEffect(function () {
    return function () {
      if (active) {
        flushQueue();
      }
    };
  }, [active, flushQueue]);
};
export var usePublish = function usePublish(topic) {
  var _usePubSub4 = usePubSub(),
    publish = _usePubSub4.publish;
  var publishFn = useCallback(function (payload) {
    return publish(topic, payload);
  }, [publish, topic]);
  return publishFn;
};
export var Subscriber = function Subscriber(_ref8) {
  var topic = _ref8.topic,
    triggerLatest = _ref8.triggerLatest,
    onEvent = _ref8.onEvent,
    flushQueueOnUnmount = _ref8.flushQueueOnUnmount;
  useSubscribe({
    topic: topic,
    triggerLatest: triggerLatest
  }, onEvent);
  useFlushOnUnmount(flushQueueOnUnmount);
  return null;
};