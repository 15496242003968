export function sendScreenEvent(clientOrPromise, payload) {
  Promise.resolve(clientOrPromise).then(function (client) {
    client.sendScreenEvent(payload);
  });
}
export function sendUIEvent(clientOrPromise, payload) {
  Promise.resolve(clientOrPromise).then(function (client) {
    client.sendUIEvent(payload);
  });
}
export function sendOperationalEvent(clientOrPromise, payload) {
  Promise.resolve(clientOrPromise).then(function (client) {
    client.sendOperationalEvent(payload);
  });
}