/**
 * Forge menu item urls are constructed by bitbucket backend
  (see https://bitbucket.org/bitbucket/core/src/0fec99b2b692b306adfa1033bc224348f6aa5795/apps/forge/menus.py#lines-45)
  We support a Forge app page module to have subpaths, so we first check if the path name is an 
  exact match with the item URL. Then check if the path name starts with the item URL with a 
  trailing slash, to ensure that the correct menu item is highlighted when the user navigates to a 
  subpath of the Forge app page module.
 * @param pathname The current pathname without the trailing slash
 * @param menuItemUrl The URL of the Forge menu item without the trailing slash
 * @returns Whether the pathname matches the Forge menu item URL
 */
export const pathnameMatchesForgeMenuItemUrl = (
  pathname: string,
  menuItemUrl: string
): boolean => {
  const itemUrlWithTrailingSlash = `${menuItemUrl}/`;
  return (
    pathname === menuItemUrl || pathname.startsWith(itemUrlWithTrailingSlash)
  );
};
