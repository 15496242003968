import _extends from "@babel/runtime/helpers/extends";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { jsx } from '@emotion/react';
import { getStyleProps } from '../utils';
export var css = function css(_ref) {
  var isDisabled = _ref.isDisabled;
  return {
    label: 'singleValue',
    gridArea: '1 / 1 / 2 / 3',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: "0 ".concat("var(--ds-space-025, 2px)"),
    color: isDisabled ? "var(--ds-text-disabled, #091E424F)" : "var(--ds-text, #172B4D)"
  };
};
var SingleValue = function SingleValue(props) {
  var children = props.children,
    isDisabled = props.isDisabled,
    innerProps = props.innerProps;
  return jsx("div", _extends({}, getStyleProps(props, 'singleValue', {
    'single-value': true,
    'single-value--is-disabled': isDisabled
  }), innerProps), children);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default SingleValue;