import { useCallback, useRef } from 'react';
export var useAnimationFrame = function useAnimationFrame() {
  var animationsRef = useRef([]);
  var requestFrame = useCallback(function (callback) {
    var id = requestAnimationFrame(callback);
    animationsRef.current.push(id);
    return id;
  }, []);
  var cancelFrame = useCallback(function (id) {
    cancelAnimationFrame(id);
    animationsRef.current = animationsRef.current.filter(function (frameId) {
      return frameId !== id;
    });
  }, []);
  var cancelAllFrames = useCallback(function () {
    animationsRef.current.forEach(function (id) {
      return cancelAnimationFrame(id);
    });
    animationsRef.current = [];
  }, []);
  return {
    requestFrame: requestFrame,
    cancelFrame: cancelFrame,
    cancelAllFrames: cancelAllFrames
  };
};