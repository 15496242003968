import React from 'react';

import { useDispatch } from 'react-redux';

import { SharedProps } from 'src/components/settings/create-settings-hoc';
import { SettingsIframe as SettingsIframeBase } from 'src/components/settings/settings-iframe';
import { useParams } from 'src/router/hooks';
import loadRepoPage from 'src/sections/repository/actions/load-repository-page';

import withSettings from './settings-hoc';

export const SettingsIframe = ({
  selectedMenuItem,
  topOffset,
  url,
}: SharedProps) => {
  const dispatch = useDispatch();
  const { repositoryOwner, repositorySlug } = useParams<{
    repositoryOwner: string;
    repositorySlug: string;
  }>();
  return (
    <SettingsIframeBase
      url={url || selectedMenuItem?.url}
      title={selectedMenuItem?.label}
      topOffset={topOffset}
      showLoadingOnUrlChange
      onIframeLoad={setIsLoading => setIsLoading(false)}
      onIframeReadyStateChangeComplete={({ isLoading, setIsLoading }) => {
        if (isLoading) {
          setIsLoading(false);
        }
        if (
          selectedMenuItem &&
          (selectedMenuItem.tab_name === 'wiki' ||
            selectedMenuItem.tab_name === 'issues')
        ) {
          if (repositoryOwner && repositorySlug) {
            dispatch(loadRepoPage(`${repositoryOwner}/${repositorySlug}`));
          }
        }
      }}
    />
  );
};

export default withSettings(SettingsIframe, {
  noLayout: true,
});
