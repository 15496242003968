import { Commit, Issue, PullRequest } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REMOVE_DEPLOYMENT_DATA,
  REQUEST_DEPLOYMENT_CHANGES,
  REQUEST_DEPLOYMENT_COMMITS,
  REQUEST_DEPLOYMENT_PULL_REQUESTS,
} from '../actions/deployments';

export type ChangesState = {
  commitDiff: Commit[];
  fileDiff: any[];
  jiraIssues: Issue[];
  pullRequests: PullRequest[];
  isJiraConnected: boolean;
  fetchedCommitsPageStatus: LoadingStatus;
  fetchedStatus: LoadingStatus;
  fetchedPullRequests: LoadingStatus;
};

export const initialState: ChangesState = {
  commitDiff: [],
  fileDiff: [],
  jiraIssues: [],
  pullRequests: [],
  isJiraConnected: true,
  fetchedCommitsPageStatus: LoadingStatus.Before,
  fetchedStatus: LoadingStatus.Before,
  fetchedPullRequests: LoadingStatus.Before,
};

export default createReducer(initialState, {
  [REMOVE_DEPLOYMENT_DATA]() {
    return initialState;
  },
  [REQUEST_DEPLOYMENT_CHANGES.REQUEST]() {
    return { ...initialState, fetchedStatus: LoadingStatus.Fetching };
  },
  [REQUEST_DEPLOYMENT_CHANGES.SUCCESS](
    state: ChangesState,
    action: Action<
      Pick<
        ChangesState,
        'commitDiff' | 'jiraIssues' | 'fileDiff' | 'isJiraConnected'
      >
    >
  ) {
    return {
      ...state,
      commitDiff: action.payload?.commitDiff || [],
      jiraIssues: action.payload?.jiraIssues || [],
      fileDiff: action.payload?.fileDiff || [],
      fetchedStatus: LoadingStatus.Success,
      isJiraConnected: !!action.payload?.isJiraConnected,
    };
  },
  [REQUEST_DEPLOYMENT_COMMITS.REQUEST](state: ChangesState) {
    return { ...state, fetchedCommitsPageStatus: LoadingStatus.Fetching };
  },
  [REQUEST_DEPLOYMENT_COMMITS.SUCCESS](
    state: ChangesState,
    action: Action<Commit[]>
  ) {
    const commitDiff = action.payload || [];
    return {
      ...state,
      commitDiff: state.commitDiff
        .filter(
          commit =>
            !commitDiff.some(newCommit => newCommit.hash === commit.hash)
        )
        .concat(commitDiff),
      fetchedCommitsPageStatus: LoadingStatus.Success,
    };
  },
  [REQUEST_DEPLOYMENT_PULL_REQUESTS.SUCCESS](
    state: ChangesState,
    action: Action<PullRequest[]>
  ) {
    const pullRequests = action.payload || [];
    return {
      ...state,
      pullRequests,
      fetchedPullRequests: LoadingStatus.Success,
    };
  },
});
