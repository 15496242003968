import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export function validateVisitors(_visitors) {
  return true;
}

/**
 * Provides recursive, depth-first search document traversal. Use visitors collection to define nodes of interest.
 * If no visitor for given node is defined, no-op happens.
 * If visitor returns new node, the old node is replaced.
 * If visitor returns false, node is dropped from the document.
 * If visitor returns null/undefined/void, original node is used.
 * @param adf Document to traverse.
 * @param visitors Collection of visitors.
 */
export function traverse(adf, visitors) {
  if (!validateVisitors(visitors)) {
    throw new Error("Visitors are not valid: \"".concat(Object.keys(visitors).join(', '), "\""));
  }
  return traverseNode(adf, {
    node: undefined
  }, visitors, 0, 0);
}
function traverseNode(adfNode, parent, visitors, index, depth) {
  var visitor = visitors[adfNode.type] || visitors['any'];
  var newNode = _objectSpread({}, adfNode);
  if (visitor) {
    var processedNode = visitor(_objectSpread({}, newNode), parent, index, depth);
    if (processedNode === false) {
      return false;
    }
    newNode = processedNode || adfNode;
  }
  if (newNode.content) {
    newNode.content = newNode.content.reduce(function (acc, node, idx) {
      if (!node) {
        return acc;
      }
      var processedNode = traverseNode(node, {
        node: newNode,
        parent: parent
      }, visitors, idx, depth + 1);
      if (processedNode !== false) {
        acc.push(processedNode);
      }
      return acc;
    }, []);
  }
  return newNode;
}