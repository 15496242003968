/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const FieldWrapper = styled.div`
  padding-top: ${token('space.100')};
`;

export const FormLabel = styled.label`
  font: ${token('font.body.UNSAFE_small')};
  font-weight: ${token('font.weight.semibold')};
  color: ${token('color.text.subtlest')};
  display: inline-block;
  margin-bottom: ${token('space.050')};
  margin-top: 0;
  margin-right: ${token('space.050')};
`;

export const FieldRefSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FieldRefSelectorDropdown = styled.div`
  flex-grow: 1;
`;

export const FieldRefSelectorBuildStatus = styled.div`
  margin-left: ${token('space.050')};
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: relative;
`;

export const BranchTypeSelectorTooltipContent = styled.div`
  max-width: 264px;
`;

export const BranchTypeSelectorIconWrapper = styled.div`
  display: inline-block;
`;

export const CursorPointer = styled.button`
  background-color: transparent;
  border: transparent;
  color: currentColor;
  cursor: pointer;
  padding: 0;
  position: relative;
  bottom: ${token('space.negative.025')};
`;

export const BranchTypeSelector = styled.div`
  width: 128px;
`;

export const BranchTypeOptionOther = styled.span`
  font-style: italic;
`;

export const BranchNamePrefix = styled.div`
  align-items: center;
  justify-content: center;
  max-width: 152px;
  margin: 0 ${token('space.025')};

  /* AK Tooltip inserts an empty div and we need to target it
     to properly add ellipsis to the branch name prefix */
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const BranchNameError = styled.div`
  max-width: 200px;
`;

export const DisabledOption = styled.span`
  color: ${token('color.text.subtlest', colors.N80)};
`;
