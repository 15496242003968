import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["isLoading", "maxWidth", "minWidth", "minHeight", "maxHeight", "testId", "role", "spacing", "className"];
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { SELECTION_STYLE_CONTEXT_DO_NOT_USE, SpacingContext } from '../internal/components/menu-context';
var baseStyles = css({
  display: 'flex',
  position: 'static',
  flexDirection: 'column',
  overflow: 'auto'
});

/**
 * __Menu group__
 *
 * A menu group includes all the actions or items in a menu.
 *
 * - [Examples](https://atlaskit.atlassian.com/packages/design-system/menu/docs/menu-group)
 * - [Code](https://atlaskit.atlassian.com/packages/design-system/menu)
 */
var MenuGroup = function MenuGroup(_ref) {
  var isLoading = _ref.isLoading,
    maxWidth = _ref.maxWidth,
    minWidth = _ref.minWidth,
    minHeight = _ref.minHeight,
    maxHeight = _ref.maxHeight,
    testId = _ref.testId,
    role = _ref.role,
    _ref$spacing = _ref.spacing,
    spacing = _ref$spacing === void 0 ? 'cozy' : _ref$spacing,
    UNSAFE_className = _ref.className,
    rest = _objectWithoutProperties(_ref, _excluded);
  return jsx(SpacingContext.Provider, {
    value: spacing
  }, jsx(SELECTION_STYLE_CONTEXT_DO_NOT_USE.Provider, {
    value: "border"
  }, jsx("div", _extends({
    "aria-busy": isLoading,
    style: {
      minWidth: minWidth,
      maxWidth: maxWidth,
      minHeight: minHeight,
      maxHeight: maxHeight
    },
    css: baseStyles,
    "data-testid": testId,
    role: role
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
    ,
    className: UNSAFE_className
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, rest))));
};
export default MenuGroup;