/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { DN300, N200 } from '@atlaskit/theme/colors';
import { themed, useGlobalTheme } from '@atlaskit/theme/components';
import { fontFamily as getFontFamily } from '@atlaskit/theme/constants';
import { h200 } from '@atlaskit/theme/typography';
var fontFamily = getFontFamily();
var fieldsetLabelStyles = css({
  display: 'inline-block',
  marginTop: 0,
  marginBottom: "var(--ds-space-050, 4px)",
  fontFamily: fontFamily
});
var getFieldsetLabelDynamicStyles = function getFieldsetLabelDynamicStyles(mode) {
  return css([h200({
    theme: {
      mode: mode
    }
  }), {
    color: themed({
      dark: "var(--ds-text-subtle, ".concat(DN300, ")"),
      light: "var(--ds-text-subtle, ".concat(N200, ")")
    })({
      theme: {
        mode: mode
      }
    })
  }]);
};

// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
var lightH200Styles = getFieldsetLabelDynamicStyles('light');

// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
var darkH200Styles = getFieldsetLabelDynamicStyles('dark');

/**
 * __Label__
 *
 * A label represents a caption for an item in a user interface.
 *
 * It's recommended that a label has a `4px` spacing above its associated
 * control element.
 */
export var Label = function Label(_ref) {
  var children = _ref.children,
    htmlFor = _ref.htmlFor,
    id = _ref.id,
    testId = _ref.testId;
  var _useGlobalTheme = useGlobalTheme(),
    mode = _useGlobalTheme.mode;
  return jsx("label", {
    css: [mode === 'light' ? lightH200Styles : darkH200Styles, fieldsetLabelStyles],
    id: id,
    htmlFor: htmlFor,
    "data-testid": testId
  }, children);
};

/**
 * __Legend__
 *
 * A Legend represents a caption for a fieldset in a user interface.
 */
export var Legend = function Legend(_ref2) {
  var children = _ref2.children;
  var _useGlobalTheme2 = useGlobalTheme(),
    mode = _useGlobalTheme2.mode;
  return jsx("legend", {
    css: [mode === 'light' ? lightH200Styles : darkH200Styles, fieldsetLabelStyles]
  }, children);
};
export default Label;