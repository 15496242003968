import { AtlBrowserStorageLocal } from '@atlassian/browser-storage-controls';

// Use the same namespace from original store expirePlugin library for compatabilities
const EXPIRE_NAMESPACE = '__storejs_expire_mixin';

// Use the same method from original store library for compatabilities
// reference: https://github.com/marcuswestin/store.js/blob/master/src/store-engine.js#L144
function serialize(obj: JSON | number) {
  return JSON.stringify(obj);
}

// Use the same method from original store library for compatabilities
// reference: https://github.com/marcuswestin/store.js/blob/master/src/store-engine.js#L148
function deserialize(strVal: string | null, defaultVal: any) {
  if (!strVal) {
    return defaultVal;
  }
  // It is possible that a raw string value has been previously stored
  // in a storage without using store.js, meaning it will be a raw
  // string value instead of a JSON serialized string. By defaulting
  // to the raw string value in case of a JSON parse error, we allow
  // for past stored values to be forwards-compatible with store.js
  let val = '';
  try {
    val = JSON.parse(strVal);
  } catch (e) {
    val = strVal;
  }

  return val !== undefined ? val : defaultVal;
}

const store = {
  set: (key: string, data: any, expiration?: number) => {
    const serializedData = serialize(data);

    AtlBrowserStorageLocal.setItem(key, serializedData);

    if (expiration) {
      AtlBrowserStorageLocal.setItem(
        `${EXPIRE_NAMESPACE}_${key}`,
        serialize(expiration)
      );
    }
  },
  setStrictlyNecessaryItem: (key: string, data: any) => {
    const serializedData = serialize(data);
    AtlBrowserStorageLocal.setStrictlyNecessaryItem(key, serializedData);
  },
  // get returns the value of the given key. If that value is undefined, it returns optionalDefaultValue instead.
  get: (key: string, optionalDefaultValue?: any) => {
    const expirationKey = `${EXPIRE_NAMESPACE}_${key}`;
    const expiration = deserialize(
      AtlBrowserStorageLocal.getItem(expirationKey),
      Number.MAX_VALUE
    );
    if (expiration <= new Date().getTime()) {
      AtlBrowserStorageLocal.removeItem(key);
      AtlBrowserStorageLocal.removeItem(expirationKey);
      return optionalDefaultValue;
    }

    const data = AtlBrowserStorageLocal.getItem(key);
    return deserialize(data, optionalDefaultValue);
  },
  remove: (key: string) => {
    const expirationKey = `${EXPIRE_NAMESPACE}_${key}`;
    AtlBrowserStorageLocal.removeItem(expirationKey);
    AtlBrowserStorageLocal.removeItem(key);
  },
  clearAll: () => {
    AtlBrowserStorageLocal.clear();
  },
  enabled: AtlBrowserStorageLocal.isStorageAvailable(),
};

export default store;
