/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { StylesConfig } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// @atlaskit/modal-dialog adds style rules for overflow to the css class generated by emotion here.
// Added !important to ensure these rules are followed over the ones added by @atlaskit.
export const ModalContainer = styled.div`
  overflow-x: visible !important;
  overflow-y: visible !important;
`;

// like ModalContainer but with extra padding to be compatible with the
// next version of atlaskit/modal-dialog (v12)
export const ModalContainerNext = styled.div`
  overflow-x: visible !important;
  overflow-y: visible !important;
  padding: ${token('space.025', '2px')} ${token('space.300', '24px')};
`;

export const FormContainer = styled.div`
  max-width: 420px;
`;

export const SelectLabel = styled.div`
  font: ${token('font.body.UNSAFE_small')};
  font-weight: ${token('font.weight.semibold')};
  color: ${token('color.text.subtlest')};
  display: inline-block;
  margin-top: 28px;
  margin-bottom: ${token('space.050', '4px')};
`;

export const NoticeContainer = styled.p`
  margin: ${token('space.200', '16px')} 0;
`;

export const SearchResultOption = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionAvatar = styled.div`
  margin-top: 1px;
  margin-right: ${token('space.100', '8px')};
`;

export const OptionName = styled.span`
  margin-right: ${token('space.100', '8px')};
`;

export const SearchGroupOption = styled(SearchResultOption)`
  padding: 1px 0;
  & > div {
    background: ${token('color.background.accent.gray.subtler', colors.N50)};
    border-radius: 50%;
    border: ${token('space.025', '2px')} solid
      ${token('color.border.inverse', colors.N0)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const HasAccessInfo = styled.span`
  margin-left: auto;
  color: ${token('color.text.subtlest', 'gray')};
  font: ${token('font.body.small')};
`;

export const SelectDropdownContainer = styled.div<{ topMarginFactor?: number }>`
  margin-top: ${({ topMarginFactor = 2 }) => 8 * topMarginFactor}px;
  width: 360px;
`;

export function getModalSelectStyles<
  T extends { [key: string]: any },
  K extends boolean
>(options: {
  marginLeft?: number;
  maxWidth?: number;
  paddingBottom?: number;
}): StylesConfig<T, K> {
  const { marginLeft, maxWidth, paddingBottom } = options;
  const modalSelectStyles: StylesConfig<T, K> = {
    container: base => ({
      ...base,
      maxWidth: maxWidth || 420,
      marginLeft: marginLeft || 0,
      paddingBottom: paddingBottom || 0,
    }),
    menuPortal: base => ({
      ...base,
      zIndex: 9999,
    }),
    control: base => ({
      ...base,
      backgroundColor: token(
        'color.background.accent.gray.subtlest',
        colors.N10
      ),
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      ...(state.data.isFixed ? { display: 'none' } : {}),
      ':hover': {
        backgroundColor: token(
          'color.background.accent.gray.subtlest.hovered',
          colors.N20
        ),
      },
    }),
    multiValue: (base, state) => ({
      ...base,
      alignItems: 'center',
      height: token('space.300', '24px'),
      backgroundColor: state.data.isFixed
        ? token('color.background.accent.gray.subtlest.hovered', 'gray')
        : token('color.background.accent.gray.subtlest', colors.N20),
      paddingRight: state.data.isFixed ? 11 : base.paddingRight,
      paddingLeft: state.data.isFixed ? 7 : base.paddingLeft,
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      color: state.data.isFixed
        ? token('color.text.inverse', 'white')
        : base.color,
    }),
  };
  return modalSelectStyles;
}

export const modalSelectStyles: StylesConfig<any, boolean> =
  getModalSelectStyles({
    maxWidth: 420,
  });
