import React from 'react';

import Flag from '@atlaskit/flag';
import Warning from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { HandleDismissedType } from 'src/redux/flags/types';

import messages from './repo-maintenance-flag.i18n';

type Props = {
  id: string;
} & HandleDismissedType;

export const RepoMaintenanceFlag = (props: Props) => {
  const intl = useIntl();
  return (
    <Flag
      appearance="warning"
      title={intl.formatMessage(messages.title)}
      description={intl.formatMessage(messages.description)}
      icon={
        <Warning
          label=""
          primaryColor={token('color.icon.warning', colors.Y300)}
        />
      }
      {...props}
    />
  );
};
