import React, { PureComponent } from 'react';

import { ProfilecardTriggerPosition } from '@atlaskit/profilecard';

import { deletedUserProfileClient } from './deleted-user-profile-client';
import UserName from './user-name';
import UserProfileCardTrigger from './user-profile-card-trigger';
import { getName } from './utils';

type Props = {
  /** A flag indicating whether or not to use the name as a trigger for a user profile card. */
  hasProfileCard?: boolean;
  /** The position where the profile card should appear (if enabled), relative to the contents of the name. */
  profileCardPosition?: ProfilecardTriggerPosition;
  /** The type of interaction that will trigger the user profile card. */
  profileCardTrigger?: 'click' | 'hover';
};

export default class DeletedUserName extends PureComponent<Props> {
  render() {
    const { hasProfileCard, profileCardPosition, profileCardTrigger } =
      this.props;

    const name = <UserName user={undefined} />;
    const fullName = getName(undefined);

    if (!hasProfileCard) {
      return name;
    }

    return (
      <UserProfileCardTrigger
        position={profileCardPosition}
        profileClient={deletedUserProfileClient}
        trigger={profileCardTrigger}
        // The underlying Fabric component expects a non-empty userId string
        userId="null"
        fullName={fullName}
      >
        <span>{name}</span>
      </UserProfileCardTrigger>
    );
  }
}
