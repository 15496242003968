/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { getCurrentRepository } from 'src/selectors/repository-selectors';
import { getTargetUserKey } from 'src/selectors/user-selectors';
import authRequest from 'src/utils/fetch';

import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import {
  CHECK_RUN_UPDATED,
  REQUEST_CHECKS_DEPLOYMENTS,
  REQUEST_CHECK_RUN,
  REQUEST_CHECK_RUNS,
  REQUEST_CHECK_SUITE,
  REQUEST_CHECK_SUITES,
} from '../actions/pipelines';
import { getCheckSuite } from '../selectors/pipelines';

import { getDeploymentUrl } from './deployments';

export const getCheckSuitesUrl = (
  accountUuid: string,
  repositoryUuid: string,
  revision: string
) => {
  return `${envBaseUrl(
    '/!api/internal'
  )}/accounts/${accountUuid}/repositories/${repositoryUuid}/commit/${revision}/check-suites`;
};

export const getCheckRunsUrl = (
  accountUuid: string,
  repositoryUuid: string,
  revision: string,
  checkSuiteUuid: string
) => {
  return `${envBaseUrl(
    '/!api/internal'
  )}/accounts/${accountUuid}/repositories/${repositoryUuid}/commit/${revision}/check-suites/${checkSuiteUuid}/check-runs`;
};

export function* requestCheckSuitesSaga(action: {
  meta: { revision: string };
  type: string;
}): any {
  const targetUserKey = yield select(getTargetUserKey);
  const { uuid } = yield select(getCurrentRepository);
  try {
    const url = getCheckSuitesUrl(targetUserKey, uuid, action.meta.revision);
    const res: Response = yield call(fetch, authRequest(url));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_CHECK_SUITES.SUCCESS,
      meta: action.meta,
      payload,
    });
    yield all(
      payload.values.map((suite: any) =>
        put({
          type: REQUEST_CHECK_RUNS.REQUEST,
          meta: { ...action.meta, checkSuiteUuid: suite.uuid },
        })
      )
    );
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_CHECK_SUITES.ERROR,
    });
    yield put({
      type: REQUEST_CHECK_SUITES.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestCheckSuiteSaga(action: {
  meta: { revision: string; checkSuiteUuid: string };
}): any {
  const targetUserKey = yield select(getTargetUserKey);
  const { uuid } = yield select(getCurrentRepository);
  try {
    const url = `${getCheckSuitesUrl(
      targetUserKey,
      uuid,
      action.meta.revision
    )}/${action.meta.checkSuiteUuid}`;
    const res: Response = yield call(fetch, authRequest(url));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_CHECK_SUITE.SUCCESS,
      meta: action.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_CHECK_SUITE.ERROR,
    });
    yield put({
      type: REQUEST_CHECK_SUITE.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestCheckRunsSaga(action: {
  meta: { revision: string; checkSuiteUuid: string };
}): any {
  const targetUserKey = yield select(getTargetUserKey);
  const { uuid } = yield select(getCurrentRepository);
  try {
    const url = getCheckRunsUrl(
      targetUserKey,
      uuid,
      action.meta.revision,
      action.meta.checkSuiteUuid
    );
    const res: Response = yield call(fetch, authRequest(url));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);

    yield put({
      type: REQUEST_CHECK_RUNS.SUCCESS,
      meta: action.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_CHECK_RUNS.ERROR,
    });
    yield put({
      type: REQUEST_CHECK_RUNS.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestCheckRunSaga(action: {
  meta: { revision: string; checkSuiteUuid: string; checkRunUuid: string };
}): any {
  const targetUserKey = yield select(getTargetUserKey);
  const { uuid } = yield select(getCurrentRepository);
  try {
    const url = `${getCheckRunsUrl(
      targetUserKey,
      uuid,
      action.meta.revision,
      action.meta.checkSuiteUuid
    )}/${action.meta.checkRunUuid}`;
    const res: Response = yield call(fetch, authRequest(url));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);

    yield put({
      type: REQUEST_CHECK_RUN.SUCCESS,
      meta: action.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_CHECK_RUN.ERROR,
    });
    yield put({
      type: REQUEST_CHECK_RUN.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestCheckRunUpdatedSaga(action: any): any {
  const { commitHash: revision, checkSuiteUuid, uuid: checkRunUuid } = action;
  if (!checkSuiteUuid || !checkRunUuid || !revision) {
    return;
  }

  const checkSuite = (yield select(getCheckSuite))(checkSuiteUuid);

  if (!checkSuite) {
    yield put({
      type: REQUEST_CHECK_SUITE.REQUEST,
      meta: { revision, checkSuiteUuid },
    });
  }

  yield put({
    type: REQUEST_CHECK_RUN.REQUEST,
    meta: { revision, checkSuiteUuid, checkRunUuid },
  });
}

export function* requestDeploymentsChecksSaga(action: {
  meta: {
    deploymentUuids: string[];
  };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);

  try {
    const res = yield all(
      action.meta.deploymentUuids.map(deploymentUuid =>
        call(fetch, authRequest(getDeploymentUrl(full_name, deploymentUuid)))
      )
    );

    for (const response of res) {
      if (!response.ok) {
        throw new Error(yield createErrorMessage(response));
      }
    }

    const payload = yield all(res.map((r: any) => call([r, 'json'])));

    yield put({
      type: REQUEST_CHECKS_DEPLOYMENTS.SUCCESS,
      meta: action.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_CHECKS_DEPLOYMENTS.ERROR,
    });
    yield put({
      type: REQUEST_CHECKS_DEPLOYMENTS.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(REQUEST_CHECK_SUITES.REQUEST, requestCheckSuitesSaga),
    takeEvery(REQUEST_CHECK_RUNS.REQUEST, requestCheckRunsSaga),
    takeLatest(CHECK_RUN_UPDATED, requestCheckRunUpdatedSaga),
    takeLatest(
      REQUEST_CHECKS_DEPLOYMENTS.REQUEST,
      requestDeploymentsChecksSaga
    ),
  ]);
}
