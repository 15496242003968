/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { easeOut } from '@atlaskit/motion';
import { useIsSidebarDragging } from '../../common/hooks';
var colorStops = "\n    rgba(0, 0, 0, 0.2) 0px,\n    rgba(0, 0, 0, 0.2) 1px,\n    rgba(0, 0, 0, 0.1) 1px,\n    rgba(0, 0, 0, 0) 100%\n  ";
var direction = 'to left';
var transitionDuration = '0.22s';
var shadowStyles = css({
  width: 3,
  position: 'absolute',
  background: "var(--ds-border, ".concat("linear-gradient(".concat(direction, ", ").concat(colorStops, ")"), ")"),
  insetBlockEnd: 0,
  insetBlockStart: 0,
  insetInlineStart: -1,
  opacity: 0.5,
  pointerEvents: 'none',
  transitionDuration: transitionDuration,
  transitionProperty: 'left, opacity, width',
  transitionTimingFunction: easeOut
});
var draggingStyles = css({
  width: 6,
  background: "var(--ds-background-neutral-subtle, ".concat("linear-gradient(".concat(direction, ", ").concat(colorStops, ")"), ")"),
  insetInlineStart: "var(--ds-space-negative-075, -6px)",
  opacity: 0.8
});
var Shadow = function Shadow(_ref) {
  var testId = _ref.testId;
  var isDragging = useIsSidebarDragging();
  return jsx("div", {
    "data-testid": testId,
    css: [shadowStyles, isDragging && draggingStyles]
  });
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Shadow;