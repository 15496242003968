import { differenceInMinutes, isSameDay } from 'date-fns';
import { isEmpty, cloneDeep } from 'lodash-es';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { StatsigFeatureKeys } from '@atlassian/bitbucket-features';

import {
  isUpdateActivity,
  isApprovalActivity,
  isChangesRequestedActivity,
  isTaskActivity,
  isAttachmentActivity,
  isAttachmentEntry,
  AttachmentEntry,
  CommitEntry,
  isCommitEntry,
  ActivityApi,
  isReviewersAddedEntry,
  OverviewCommentEntry,
  ActivityOverviewEntry,
  isTasksCreatedEntry,
  TasksCreatedEntry,
  TasksResolvedEntry,
  isTasksResolvedEntry,
  ReviewersAddedEntry,
  isDescriptionChangeEntry,
  DescriptionChangeEntry,
} from 'src/components/activity/types';
import {
  ApiCommentOrPlaceholder,
  isApiComment,
} from 'src/components/conversation-provider/types';
import { apiCommentId } from 'src/components/conversation-provider/utils/to-conversation';
import {
  CommentThread,
  CommentTree,
} from 'src/components/conversation/src/components/comment-tree';
import { shortHash } from 'src/utils/short-hash';

export const findLatestComment = (
  commentThread: CommentThread
): ApiCommentOrPlaceholder => {
  if (!isApiComment(commentThread.topComment)) {
    // It's a placeholder comment with no children, so must be the latest
    return commentThread.topComment;
  }
  return Array.from(
    commentThread.commentTree.descendentsOf(commentThread.topComment.id)
  ).reduce(
    (
      latest: ApiCommentOrPlaceholder,
      current: ApiCommentOrPlaceholder
    ): ApiCommentOrPlaceholder => {
      if (latest === null) {
        return current;
      }
      if (new Date(latest.created_on) > new Date(current.created_on)) {
        return latest;
      }
      return current;
    },
    commentThread.topComment
  );
};

export const breakoutOverviewCommentEntries = (
  commentTree: CommentTree,
  threadsLatestComment: { [key: number | string]: ApiCommentOrPlaceholder }
): OverviewCommentEntry[] => {
  const isActivityFeedCommentsPreventReorderEnabled = FeatureGates.checkGate(
    StatsigFeatureKeys.activityFeedCommentsPreventReorder
  );
  const commentEntries: Array<OverviewCommentEntry> = [];
  commentTree.allThreads().forEach(thread => {
    const isDeleted =
      'deleted' in thread.topComment && thread.topComment.deleted;
    if (!isDeleted) {
      const latestComment =
        threadsLatestComment[apiCommentId(thread.topComment)];
      const overviewHeaderComment =
        isActivityFeedCommentsPreventReorderEnabled && latestComment
          ? latestComment
          : findLatestComment(thread);
      commentEntries.push({
        type: 'comment-start',
        event: thread,
        actor: overviewHeaderComment.user,
        timestamp: new Date(overviewHeaderComment.created_on),
        latestComment: overviewHeaderComment,
      });
    }
  });
  return commentEntries;
};

/** Simple builder for title change activity feed entries */
const titleChanged = (event: ActivityApi['Update']) => ({
  type: 'title-change' as const,
  actor: event.update.author,
  event,
  lastTitle: event.update.changes.title!.old,
  timestamp: new Date(event.update.date),
});

/** Simple builder for description change activity feed entries */
const descriptionChanged = (event: ActivityApi['Update']) => ({
  type: 'description-change' as const,
  actor: event.update.author,
  event,
  timestamp: new Date(event.update.date),
});

/** Simple builder for commit activity feed entries */
export const updated = (event: ActivityApi['Update']): CommitEntry => {
  const { commit } = event.update.source;
  return {
    type: 'update' as const,
    actor: event.update.author,
    event,
    hashes: [
      {
        hash: commit ? shortHash(commit.hash) : null,
        parentHash:
          commit && commit.parents && commit.parents[0]
            ? shortHash(commit.parents[0].hash)
            : null,
        url: commit ? commit.links.html.href : null,
      },
    ],
    timestamp: new Date(event.update.date),
  };
};

/** Simple builder for status change activity feed entries */
const statusChanged = (event: ActivityApi['Update']) => ({
  type: 'status-change' as const,
  actor: event.update.author,
  event,
  timestamp: new Date(event.update.date),
});

/** Simple builder for the reviewers added activity feed entires.
 * Technically this event contains added and removed reviewers, so the name
 * "reviewers-added" is a bit of a misnomer.
 */
const reviewersAdded = (event: ActivityApi['Update']) => ({
  type: 'reviewers-added' as const,
  actor: event.update.author,
  event,
  timestamp: new Date(event.update.date),
});

/** Simple builder for approval activity feed entries */
const approved = (event: ActivityApi['Approval']) => ({
  type: 'approval' as const,
  event,
  actor: event.approval.user,
  timestamp: new Date(event.approval.date),
});

const changesRequested = (event: ActivityApi['ChangesRequested']) => ({
  type: 'changes-requested' as const,
  event,
  actor: event.changes_requested.user,
  timestamp: new Date(event.changes_requested.date),
});

/** Simple builder for the task created activity feed entries */
const tasksCreated = (
  event: ActivityApi['TaskActivity']
): TasksCreatedEntry => ({
  type: 'tasks-created' as const,
  actor: event.task.actor,
  event: [event],
  timestamp: new Date(event.task.action_on),
});

/** Simple builder for the task resolved activity feed entries */
const tasksResolved = (
  event: ActivityApi['TaskActivity']
): TasksResolvedEntry => ({
  type: 'tasks-resolved' as const,
  actor: event.task.actor,
  event: [event],
  timestamp: new Date(event.task.action_on),
});

/** Simple builder for the attachment added activity feed entries */
const attachmentAdded = (
  event: ActivityApi['AttachmentActivity']
): AttachmentEntry => {
  const { attachment } = event;
  return {
    type: 'attachment-added' as const,
    actor: attachment.uploaded_by,
    event,
    items: [
      {
        name: attachment.name,
        uuid: attachment.uuid,
      },
    ],
    timestamp: new Date(attachment.created_on),
  };
};

// COREX-1733 Activity feed shows user updating commits when only the destination commit changed
const isSourceCommitChanged = (
  openEvent: ActivityApi['Update'] | undefined,
  event: ActivityApi['Update']
) =>
  openEvent?.update?.source?.commit?.hash !==
  event?.update?.source?.commit?.hash;

/** Simple builder for draft change activity feed entries */
const draftChanged = (event: ActivityApi['Update']) => ({
  type: 'draft-change' as const,
  actor: event.update.author,
  event,
  timestamp: new Date(event.update.date),
});

/**
 * This turns Activity Endpoint events into Overview Activity Feed Entries.
 * Updates can become TitleChanges, DescriptionChanges, StatusChanges, or Commits.
 * Approval events become Approval Entries.
 *
 * Behavior changes if we have all events. Represented by the second parameter.
 * When a PR is first created the backend creates two UPDATE activity events that both represent the OPENing of the PR.
 * If we have all events then we know we can turn the original event into a StatusChange->OPEN and drop the second event.
 * The rest of the items can be processed as normal.
 *
 * @param chronologicalEvents Should be in chronological order from oldest to newest
 * @param hasAllEvents Whether or not we have every event, the endpoint can be paginated
 */
export const transformOverviewActivities = (
  chronologicalEvents: Array<
    | ActivityApi['Approval']
    | ActivityApi['Update']
    | ActivityApi['TaskActivity']
    | ActivityApi['AttachmentActivity']
    | ActivityApi['ChangesRequested']
  >,
  hasAllEvents?: boolean
): ActivityOverviewEntry[] => {
  let openEvent: ActivityApi['Update'] | undefined;
  let updateEventsSeen = 0;
  const areDraftPRsEnabled = FeatureGates.checkGate(
    StatsigFeatureKeys.bbcDraftPRs
  );

  // The first two update events from the API will be duplicate OPEN events
  // Capture the values of 2nd and discard the 1st
  const result: ActivityOverviewEntry[] = chronologicalEvents
    .filter(activityEvent => {
      if (
        hasAllEvents &&
        updateEventsSeen < 2 &&
        isUpdateActivity(activityEvent)
      ) {
        openEvent = activityEvent;
        updateEventsSeen += 1;
        return false;
      }
      return true;
    })
    .reduce((events, event) => {
      if (isUpdateActivity(event)) {
        const changes = event.update.changes ? event.update.changes : {};
        if (isEmpty(changes) && isSourceCommitChanged(openEvent, event)) {
          events.push(updated(event));
        } else {
          if ('title' in changes) {
            events.push(titleChanged(event));
          }
          if ('description' in changes) {
            events.push(descriptionChanged(event));
          }
          if ('status' in changes) {
            events.push(statusChanged(event));
          }
          if (
            'reviewers' in changes &&
            ('added' in changes.reviewers! || 'removed' in changes.reviewers!)
          ) {
            events.push(reviewersAdded(event));
          }
          if (areDraftPRsEnabled && 'draft' in changes) {
            events.push(draftChanged(event));
          }
        }
      } else if (isApprovalActivity(event)) {
        events.push(approved(event));
      } else if (isChangesRequestedActivity(event)) {
        events.push(changesRequested(event));
      } else if (
        // The check for the actor is specifically to exclude default tasks
        // This ensures that default tasks, which lack an actor, are hidden
        isTaskActivity(event) &&
        !event.task.task.pending &&
        event.task.actor
      ) {
        if (event.task.action === 'CREATED') {
          events.push(tasksCreated(event));
        } else if (event.task.action === 'RESOLVED') {
          events.push(tasksResolved(event));
        }
      } else if (isAttachmentActivity(event)) {
        events.push(attachmentAdded(event));
      }
      return events;
    }, [] as ActivityOverviewEntry[]);

  if (openEvent && isUpdateActivity(openEvent)) {
    // These are supposed to be sorted oldest -> newest so we put the OPEN in front
    result.unshift(statusChanged(openEvent));
  }

  return result;
};

/**
 * Accepts a list of Activity Feed Entries and condenses contiguous sequences of
 * commits performed by the same author into single Entries with hashes of each commit they made.
 * Any activity in between the commits disqualifies the commits from being condensed. This mimics
 * the behavior of the old pull request activity tab.
 *
 * A consequence of the sort direction is that UPDATEs with the same commit hash will only show the
 * first one encountered in the list. So if sorted old-to-new then the commit entry will appear in
 * the oldest place it first occurred, and vice versa.
 *
 * @param entries Activity Feed Entries, sorted chronologically in either direction
 */
export const collapseCommits = (
  entries: ActivityOverviewEntry[]
): ActivityOverviewEntry[] => {
  return entries.reduce((previousEntries, currentEntry) => {
    if (!isCommitEntry(currentEntry)) {
      // We aren't concerned with non-commits here
      return [...previousEntries, currentEntry];
    }

    const { commit } = currentEntry.event.update.source;

    const newHash = commit ? shortHash(commit.hash) : null;
    const isContainedInAnyPreviousCommitEntry =
      newHash &&
      previousEntries.filter(
        entry =>
          isCommitEntry(entry) &&
          entry.hashes.find(({ hash }) => hash === shortHash(newHash))
      ).length > 0;

    if (isContainedInAnyPreviousCommitEntry) {
      // If the commit hash has already been added to any commit entry then
      // this return ensures that we don't add it as a new event either.
      return previousEntries;
    }

    const previousEntry = previousEntries[previousEntries.length - 1];
    if (isCommitEntry(previousEntry)) {
      const isSameAuthor = currentEntry.actor.uuid === previousEntry.actor.uuid;

      if (
        isSameAuthor &&
        isSameDay(currentEntry.timestamp, previousEntry.timestamp)
      ) {
        previousEntry.hashes.push({
          hash: commit ? shortHash(commit.hash) : null,
          parentHash:
            commit && commit.parents && commit.parents[0]
              ? shortHash(commit.parents[0].hash)
              : null,
          url: commit ? commit.links.html.href : null,
        });
        return previousEntries;
      }
    }

    // This return is the result of it being a brand new commit entry
    return [...previousEntries, currentEntry];
  }, [] as ActivityOverviewEntry[]);
};

export const collapseAttachments = (
  entries: ActivityOverviewEntry[]
): ActivityOverviewEntry[] => {
  return entries.reduce((previousEntries, currentEntry) => {
    if (!isAttachmentEntry(currentEntry)) {
      return [...previousEntries, currentEntry];
    }

    const { attachment } = currentEntry.event;
    const previousEntry = previousEntries[previousEntries.length - 1];
    if (isAttachmentEntry(previousEntry)) {
      const isSameAuthor = currentEntry.actor.uuid === previousEntry.actor.uuid;
      const TIME_GAP = 1000 * 60 * 30; // 30 minutes
      const isRecent =
        new Date(currentEntry.timestamp).getTime() -
          new Date(previousEntry.timestamp).getTime() <=
        TIME_GAP;

      if (isSameAuthor && isRecent) {
        // most recent items go first in array
        previousEntry.items.unshift({
          name: attachment.name,
          uuid: attachment.uuid,
        });
        return previousEntries;
      }
    }
    // This return is the result of it being a brand new attachment entry
    return [...previousEntries, currentEntry];
  }, [] as ActivityOverviewEntry[]);
};

/**
 * This is an internal helper function which collapses down the tasks entries
 */
const combineTasks = (
  previousEntries: ActivityOverviewEntry[],
  previousEntry: TasksCreatedEntry | TasksResolvedEntry,
  currentEntry: TasksCreatedEntry | TasksResolvedEntry
) => {
  // make sure we don't accidentally combine two different types of tasks
  if (previousEntry.type !== currentEntry.type) {
    return [...previousEntries, currentEntry];
  }
  previousEntry.event = (previousEntry.event || []).concat(currentEntry.event);
  return previousEntries;
};

const combineDescriptionChanges = (
  previousEntry: DescriptionChangeEntry,
  currentEntry: DescriptionChangeEntry
) => {
  if (
    previousEntry.event.update.changes.description &&
    currentEntry.event.update.changes.description
  ) {
    previousEntry.event.update.changes.description.new =
      currentEntry.event.update.changes.description.new;
  }
  return previousEntry;
};

/**
 * This is an internal helper function which collapses down the reviewers added entries.
 * Note that this includes reviewers removed as well.
 */
const combineReviewersAddedAndRemoved = (
  previousEntry: ReviewersAddedEntry,
  currentEntry: ReviewersAddedEntry
) => {
  if (!previousEntry.event.update.changes.reviewers) {
    // This is primarily to keep the typing happy, and will likely never happen
    previousEntry.event.update.changes.reviewers = {
      added: [],
      removed: [],
    };
  }
  // It is possible to concat two events that only had additions or removals, so we need to handle all the cases
  previousEntry.event.update.changes.reviewers.added = (
    previousEntry.event.update.changes.reviewers.added || []
  ).concat(currentEntry.event.update.changes?.reviewers?.added || []);
  previousEntry.event.update.changes.reviewers.removed = (
    previousEntry.event.update.changes.reviewers.removed || []
  ).concat(currentEntry.event.update.changes?.reviewers?.removed || []);
  return previousEntry;
};

/**
 * This function takes in activiy feed entries and collapsed down the following types of entries:
 * - Reviewers Added (and removed)
 * - Tasks Created
 * - Tasks Resolved
 * @param entries Activity Feed Entries, sorted chronologically in either direction
 */
export const collapseRichActivities = (
  entries: ActivityOverviewEntry[]
): ActivityOverviewEntry[] => {
  // This selector gets run multiple times when a page is loading new data
  // Since it permutes the data we need to make sure
  // we clone the entries first so we don't run that permutation multiple times.
  const clonedEntries = cloneDeep(entries);
  return clonedEntries.reduce((previousEntries, currentEntry) => {
    const previousEntry = previousEntries[previousEntries.length - 1];
    if (!previousEntry) {
      // This is the first entry so we can't merge it with anything
      return [currentEntry];
    }
    if (!previousEntry.actor || !currentEntry.actor) {
      // if we don't have an actor we can't merge
      // this can happen when bots are making automated comments on PRs
      return [...previousEntries, currentEntry];
    }
    const isSameAuthor = currentEntry.actor.uuid === previousEntry.actor.uuid;
    const TIME_GAP = 5; // 5 minutes
    const isRecent =
      differenceInMinutes(currentEntry.timestamp, previousEntry.timestamp) <=
      TIME_GAP;

    // never merge things that aren't close together in time and the same actor
    if (isRecent && isSameAuthor) {
      // collapse the reviewers added if they match (note this includes reviewers removed because reviewers added is a misnomer)
      if (
        isReviewersAddedEntry(currentEntry) &&
        isReviewersAddedEntry(previousEntry)
      ) {
        combineReviewersAddedAndRemoved(previousEntry, currentEntry);
        return previousEntries;
      }
      // collapse the created tasks if they match
      if (
        isTasksCreatedEntry(currentEntry) &&
        isTasksCreatedEntry(previousEntry)
      ) {
        return combineTasks(previousEntries, previousEntry, currentEntry);
      }
      // collapse the resolved tasks if they match
      if (
        isTasksResolvedEntry(currentEntry) &&
        isTasksResolvedEntry(previousEntry)
      ) {
        return combineTasks(previousEntries, previousEntry, currentEntry);
      }
      if (
        isDescriptionChangeEntry(currentEntry) &&
        isDescriptionChangeEntry(previousEntry)
      ) {
        combineDescriptionChanges(previousEntry, currentEntry);
        return previousEntries;
      }
    }

    // This return is the result of it being a brand new entry that doesn't match the previous entry
    return [...previousEntries, currentEntry];
  }, [] as ActivityOverviewEntry[]);
};
