import { monitorForExternal } from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
import { makeFixForAdapter } from './make-fix-for-adapter';
function watchForInteractionStart(_ref) {
  var start = _ref.start;
  return monitorForExternal({
    onDragStart: function onDragStart() {
      start();
    }
  });
}
function watchForInteractionEnd(_ref2) {
  var stop = _ref2.stop;
  return monitorForExternal({
    onDrop: function onDrop() {
      stop();
    }
  });
}
var api = makeFixForAdapter({
  watchForInteractionStart: watchForInteractionStart,
  watchForInteractionEnd: watchForInteractionEnd
});
export function disableDraggingToCrossOriginIFramesForExternal() {
  return api.registerUsage();
}