/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useModal } from './hooks';
import { keylineHeight } from './internal/constants';
var footerStyles = css({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: "var(--ds-space-100, 8px)",
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginBlockStart: -keylineHeight,
  paddingBlockEnd: "var(--ds-space-300, 24px)",
  paddingBlockStart: "var(--ds-space-200, 16px)",
  paddingInline: "var(--ds-space-300, 24px)"
});
/**
 * __Modal footer__
 *
 * A modal footer often contains a primary action and the ability to cancel and close the dialog, though can contain any React element.
 *
 * - [Examples](https://atlassian.design/components/modal-dialog/examples#modal-footer)
 * - [Code](https://atlassian.design/components/modal-dialog/code#modal-footer-props)
 * - [Usage](https://atlassian.design/components/modal-dialog/usage)
 */
var ModalFooter = function ModalFooter(props) {
  var children = props.children,
    userDefinedTestId = props.testId;
  var _useModal = useModal(),
    modalTestId = _useModal.testId;
  var testId = userDefinedTestId || modalTestId && "".concat(modalTestId, "--footer");
  return jsx("div", {
    css: footerStyles,
    "data-testid": testId
  }, children);
};
export default ModalFooter;