/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const SearchFieldContainer = styled.div<{
  widthGridMultiplier?: number;
}>`
  width: ${props =>
    props.widthGridMultiplier ? 8 * props.widthGridMultiplier : 240}px;

  & > div {
    height: 32px;

    &:focus,
    &:hover {
      height: 32px;
    }
  }
`;

export const SearchIconContainer = styled.span`
  padding-right: ${token('space.100')};
  padding-top: ${token('space.025')};
`;

export const HeaderCheckbox = styled.span`
  vertical-align: bottom;
`;

export const BulkActionsHeader = styled.div`
  color: ${token('color.text', colors.N800)};
  font-size: 14px;

  & button:nth-of-type(2),
  & button:nth-of-type(3) {
    margin-left: ${token('space.150')};
  }
`;
