import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useCallback, useEffect, useRef, useState } from 'react';
import { bind } from 'bind-event-listener';
import { usePublish } from '../../../main';
export var ROVO_POST_MESSAGE_EVENT_TYPE = 'rovo-post-message';
export var ROVO_POST_MESSAGE_ACK_EVENT_TYPE = 'rovo-post-message-ack';
// allow subdomains of these domains
var allowedSubdomains = ['.jira-dev.com', '.atlassian.com', '.atlassian.net', '.atl-paas.net'];
var allowedOrigins = ['bitbucket.org', 'trello.com'];
export var isAllowedOrigin = function isAllowedOrigin(origin) {
  if (!origin) {
    return false;
  }
  var url = new URL(origin);
  var hostname = url.hostname;
  return hostname === 'localhost' || url.protocol === 'https:' && (allowedSubdomains.some(function (subdomain) {
    return hostname.endsWith(subdomain);
  }) || allowedOrigins.includes(hostname));
};
export var RovoPostMessagePubsubListener = function RovoPostMessagePubsubListener() {
  var publish = usePublish('ai-mate');
  useEffect(function () {
    var handlerUnbind = bind(window, {
      type: 'message',
      listener: function listener(event) {
        if (!isAllowedOrigin(event.origin)) {
          return;
        }
        if (event.data.eventType === ROVO_POST_MESSAGE_EVENT_TYPE) {
          var _event$source;
          var eventData = event.data;
          var ackPayload = {
            eventType: ROVO_POST_MESSAGE_ACK_EVENT_TYPE,
            payloadId: eventData.payloadId
          };
          (_event$source = event.source) === null || _event$source === void 0 || _event$source.postMessage(ackPayload);
          publish(event.data.payload);
        }
      }
    });
    return handlerUnbind;
  }, [publish]);
  return null;
};
var TIMEOUT_WAIT_FOR_ACK = 100;

/**
 * Hook to send a publish event to parent iframe using postMessage
 * Only supports 1 pubsub event at a time and waits for acknowledgment or timed out
 */
export var useRovoPostMessageToPubsub = function useRovoPostMessageToPubsub() {
  var onAcknowledgeTimeoutTimeoutRef = useRef(null);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isWaitingForAck = _useState2[0],
    setIsWaitingForAck = _useState2[1];
  useEffect(function () {
    var handlerUnbind = bind(window, {
      type: 'message',
      // handler for acknowledgment from parent
      listener: function listener(event) {
        if (!isAllowedOrigin(event.origin)) {
          return;
        }
        if (event.data.eventType !== ROVO_POST_MESSAGE_ACK_EVENT_TYPE || onAcknowledgeTimeoutTimeoutRef.current === null) {
          return;
        }
        var eventData = event.data;

        // if the parent acknowledges different payload (e.g. from different hook instance)
        // disregard the event
        if (eventData.payloadId !== onAcknowledgeTimeoutTimeoutRef.current.lastPayloadId) {
          return;
        }

        // Clear the onAcknowledgeTimeoutTimeout if acknowledgment is received
        clearTimeout(onAcknowledgeTimeoutTimeoutRef.current.timeout);
        onAcknowledgeTimeoutTimeoutRef.current = null;
        setIsWaitingForAck(false);
      }
    });
    return handlerUnbind;
  }, []);
  var publishWithPostMessage = useCallback(function (_ref) {
    var _ref$targetWindow = _ref.targetWindow,
      targetWindow = _ref$targetWindow === void 0 ? window.parent : _ref$targetWindow,
      payload = _ref.payload,
      onAcknowledgeTimeout = _ref.onAcknowledgeTimeout;
    if (!targetWindow) {
      return;
    }
    var payloadId = Math.floor(Math.random() * 1e6).toString();
    targetWindow.postMessage({
      eventType: ROVO_POST_MESSAGE_EVENT_TYPE,
      payload: payload,
      payloadId: payloadId
    }, '*');

    // waiting for acknowledgment from parent
    // if no acknowledgment is received, call onAcknowledgeTimeout
    setIsWaitingForAck(true);
    var timeout = window.setTimeout(function () {
      onAcknowledgeTimeout({
        payload: payload
      });
      onAcknowledgeTimeoutTimeoutRef.current = null;
      setIsWaitingForAck(false);
    }, TIMEOUT_WAIT_FOR_ACK);
    onAcknowledgeTimeoutTimeoutRef.current = {
      timeout: timeout,
      lastPayloadId: payloadId
    };
  }, []);
  return {
    publishWithPostMessage: publishWithPostMessage,
    isWaitingForAck: isWaitingForAck
  };
};