import { useEffect } from 'react';
import usePreviousValue from '@atlaskit/ds-lib/use-previous-value';
/**
 * The hook is used to invoke callback after breadcrumbs is revealed.
 *
 * The hook checks if previous state of breadcrumbs was collapsed and current state is expanded
 * and if so invoke received callback.
 *
 * @param callback the function to invoke after revealing
 * @param param1 the object that contain two field:
 *    1) isExpanded - current state of breadcrumbs
 *    2) isDisabled - to disable the callback.
 */
export function useOnRevealed(callback, _ref) {
  var isExpanded = _ref.isExpanded,
    isDisabled = _ref.isDisabled;
  var prevExtended = usePreviousValue(isExpanded);
  useEffect(function () {
    var hasBeenRevealed = !prevExtended && isExpanded;
    if (!isDisabled && hasBeenRevealed) {
      callback();
    }
  }, [isExpanded, isDisabled, callback, prevExtended]);
}