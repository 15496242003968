import { debug } from './debug';
var pkgName = '@atlaskit/platform-feature-flags';
export var PFF_GLOBAL_KEY = '__PLATFORM_FEATURE_FLAGS__';
var hasProcessEnv = typeof process !== 'undefined' && typeof process.env !== 'undefined';

// FF global overrides can be configured by test runners or Storybook
var ENV_ENABLE_PLATFORM_FF = hasProcessEnv ?
// Use global "process" variable and process.env['FLAG_NAME'] syntax, so it can be replaced by webpack DefinePlugin
process.env['ENABLE_PLATFORM_FF'] === 'true' : false;

// STORYBOOK_ENABLE_PLATFORM_FF is included as storybook only allows env vars prefixed with STORYBOOK
// https://github.com/storybookjs/storybook/issues/12270

var ENV_STORYBOOK_ENABLE_PLATFORM_FF = hasProcessEnv ?
// Use global "process" variable and process.env['FLAG_NAME'] syntax, so it can be replaced by webpack DefinePlugin
process.env['STORYBOOK_ENABLE_PLATFORM_FF'] === 'true' : false;
var ENABLE_GLOBAL_PLATFORM_FF_OVERRIDE = ENV_ENABLE_PLATFORM_FF || ENV_STORYBOOK_ENABLE_PLATFORM_FF;
var DEFAULT_PFF_GLOBAL = {
  // In development mode we want to capture any feature flag checks that happen using the default resolver and log this result when the resolver is replaced.
  // This is because evaluating feature flags when the resolver/FF client is loaded asynchronously could cause unexpected issues.
  earlyResolvedFlags: new Map(),
  booleanResolver: function booleanResolver(flagKey) {
    if (process.env.NODE_ENV !== 'production') {
      var unresolvedFlagCount = this.earlyResolvedFlags.get(flagKey) || 0;
      this.earlyResolvedFlags.set(flagKey, unresolvedFlagCount + 1);
    }
    return false;
  }
};
var globalVar = typeof window !== 'undefined' ? window : globalThis;
globalVar[PFF_GLOBAL_KEY] = globalVar[PFF_GLOBAL_KEY] || DEFAULT_PFF_GLOBAL;
export function setBooleanResolver(resolver) {
  globalVar[PFF_GLOBAL_KEY].booleanResolver = resolver;
  if (process.env.NODE_ENV !== 'production') {
    var _globalVar$PFF_GLOBAL;
    if (((_globalVar$PFF_GLOBAL = globalVar[PFF_GLOBAL_KEY]) === null || _globalVar$PFF_GLOBAL === void 0 || (_globalVar$PFF_GLOBAL = _globalVar$PFF_GLOBAL.earlyResolvedFlags) === null || _globalVar$PFF_GLOBAL === void 0 ? void 0 : _globalVar$PFF_GLOBAL.size) > 0) {
      debug("[%s]: The following list of Feature Flags were called, the following number of times, before setBooleanResolver.", pkgName, Array.from(globalVar[PFF_GLOBAL_KEY].earlyResolvedFlags.entries()));
      globalVar[PFF_GLOBAL_KEY].earlyResolvedFlags.clear();
    }
  }
}
export function resolveBooleanFlag(flagKey) {
  if (ENABLE_GLOBAL_PLATFORM_FF_OVERRIDE) {
    debug('[%s]: The feature flags were enabled while running tests. The flag "%s" will be always enabled.', pkgName, flagKey);
    return true;
  }
  try {
    var _globalVar$PFF_GLOBAL2;
    var result = (_globalVar$PFF_GLOBAL2 = globalVar[PFF_GLOBAL_KEY]) === null || _globalVar$PFF_GLOBAL2 === void 0 ? void 0 : _globalVar$PFF_GLOBAL2.booleanResolver(flagKey);
    if (typeof result !== 'boolean') {
      // eslint-disable-next-line no-console
      console.warn("".concat(flagKey, " resolved to a non-boolean value, returning false for safety"));
      return false;
    }
    return result;
  } catch (e) {
    return false;
  }
}