import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["eventType"];
var isPromise = function isPromise(c) {
  return typeof c.then === 'function';
};
export var sendEvent = function sendEvent(logger, client) {
  return function (event) {
    if (client) {
      var eventType = event.eventType,
        gasEvent = _objectWithoutProperties(event, _excluded);
      var withClient = function withClient(cb) {
        if (isPromise(client)) {
          client.then(cb).catch(function (e) {
            return logger.warn('There was an error sending the event', e);
          });
        } else {
          try {
            cb(client);
          } catch (e) {
            logger.warn('There was an error sending the event', e);
          }
        }
      };
      switch (event.eventType) {
        case 'ui':
          logger.debug('Sending UI Event via analytics client', gasEvent);
          withClient(function (client) {
            return client.sendUIEvent(gasEvent);
          });
          break;
        case 'operational':
          logger.debug('Sending Operational Event via analytics client', gasEvent);
          withClient(function (client) {
            return client.sendOperationalEvent(gasEvent);
          });
          break;
        case 'track':
          logger.debug('Sending Track Event via analytics client', gasEvent);
          withClient(function (client) {
            return client.sendTrackEvent(gasEvent);
          });
          break;
        case 'screen':
          logger.debug('Sending Screen Event via analytics client', gasEvent);
          withClient(function (client) {
            return client.sendScreenEvent(gasEvent);
          });
          break;
        default:
          logger.error("cannot map eventType ".concat(event.eventType, " to an analytics-web-client function"));
      }
    } else {
      logger.warn('AnalyticsWebClient instance is not provided');
    }
  };
};