/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { prefersReducedMotion } from '@atlaskit/motion';
import { TRANSITION_DURATION } from '../../../common/constants';
import { useIsSidebarCollapsing } from '../../../common/hooks';
// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
var prefersReducedMotionStyles = css(prefersReducedMotion());

/**
 * The transition duration is intentionally set to 0ms.
 *
 * A transition is being used here to delay the setting of
 * opacity and visibility so that it syncs collapsing sidebar.
 */
var hideLeftSidebarContentsStyles = css({
  opacity: 0,
  transition: 'opacity 0ms linear, visibility 0ms linear',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  transitionDelay: "".concat(TRANSITION_DURATION - 100, "ms"),
  visibility: 'hidden'
});
var resizableChildrenWrapperStyles = css({
  height: '100%',
  opacity: 1,
  overflow: 'hidden auto',
  transition: 'none',
  visibility: 'visible'
});
var fixedChildrenWrapperStyles = css({
  minWidth: 240,
  height: '100%'
});
var ResizableChildrenWrapper = function ResizableChildrenWrapper(_ref) {
  var children = _ref.children,
    _ref$isLeftSidebarCol = _ref.isLeftSidebarCollapsed,
    isLeftSidebarCollapsed = _ref$isLeftSidebarCol === void 0 ? false : _ref$isLeftSidebarCol,
    _ref$hasCollapsedStat = _ref.hasCollapsedState,
    hasCollapsedState = _ref$hasCollapsedStat === void 0 ? false : _ref$hasCollapsedStat,
    _ref$isFlyoutOpen = _ref.isFlyoutOpen,
    isFlyoutOpen = _ref$isFlyoutOpen === void 0 ? false : _ref$isFlyoutOpen,
    testId = _ref.testId;
  var isCollapsing = useIsSidebarCollapsing();
  var isCollapsed = isLeftSidebarCollapsed || hasCollapsedState;
  var isHidden = isCollapsing || isCollapsed && !isFlyoutOpen;
  return jsx("div", {
    css: [resizableChildrenWrapperStyles, isHidden && hideLeftSidebarContentsStyles, prefersReducedMotionStyles],
    "aria-hidden": isHidden,
    "data-testid": testId,
    tabIndex: isHidden ? -1 : 0
  }, jsx("div", {
    css: fixedChildrenWrapperStyles
  }, children));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default ResizableChildrenWrapper;