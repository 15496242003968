import _defineProperty from "@babel/runtime/helpers/defineProperty";
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { easeInOut } from '@atlaskit/motion/curves';
import { durations } from '@atlaskit/motion/durations';
import { media } from '@atlaskit/primitives';
import { layers } from '@atlaskit/theme/constants';
import { gutter, verticalOffset } from '../constants';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
var maxWidthDimensions = "calc(100vw - ".concat(gutter * 2, "px)");
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
var maxHeightDimensions = "calc(100vh - ".concat(gutter * 2 - 1, "px)");

// Flex and min-content are set to constrain the height of the body and support multi-column scrolling experiences
var positionerStyles = css({
  display: 'flex',
  width: '100%',
  maxWidth: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: layers.modal(),
  flexDirection: 'column',
  insetBlockStart: 0,
  insetInlineStart: 0
});
var viewportScrollStyles = css(_defineProperty({
  height: 'auto',
  position: 'relative'
}, media.above.xs, {
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  margin: "".concat(gutter, "px auto"),
  pointerEvents: 'none'
}));
var bodyScrollStyles = css(_defineProperty({}, media.above.xs, {
  maxWidth: maxWidthDimensions,
  maxHeight: maxHeightDimensions,
  position: 'absolute',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  insetBlockStart: "".concat(gutter, "px"),
  insetInlineEnd: 0,
  insetInlineStart: 0,
  marginInlineEnd: 'auto',
  marginInlineStart: 'auto',
  pointerEvents: 'none'
}));
var stackTransitionStyles = css({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
  transitionDuration: "".concat(durations.medium, "ms"),
  transitionProperty: 'transform',
  transitionTimingFunction: easeInOut,
  /**
   * Duplicated from @atlaskit/motion/accessibility
   * because @repo/internal/styles/consistent-style-ordering
   * doesn't work well with object spreading.
   */
  '@media (prefers-reduced-motion: reduce)': {
    animation: 'none',
    transition: 'none'
  }
});
var stackTransformStyles = css({
  transform: 'translateY(var(--modal-dialog-translate-y))'
});
var stackIdleStyles = css({
  transform: 'none'
});
var Positioner = function Positioner(props) {
  var children = props.children,
    stackIndex = props.stackIndex,
    shouldScrollInViewport = props.shouldScrollInViewport,
    testId = props.testId;
  return jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    style: {
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
      '--modal-dialog-translate-y': "".concat(stackIndex * (verticalOffset / 2), "px")
      // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    },
    css: [positionerStyles, stackTransitionStyles, /* We only want to apply transform on modals shifting to the back of the stack. */
    stackIndex > 0 ? stackTransformStyles : stackIdleStyles, shouldScrollInViewport ? viewportScrollStyles : bodyScrollStyles],
    "data-testid": testId && "".concat(testId, "--positioner")
  }, children);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Positioner;