import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { traverse } from '../traverse/traverse';
import { extension } from '../builders';
import { NodeNestingTransformError } from './errors';
var NESTED_TABLE_EXTENSION_TYPE = 'com.atlassian.confluence.migration',
  NESTED_TABLE_EXTENSION_KEY = 'nested-table';
export var isNestedTableExtension = function isNestedTableExtension(extensionNode) {
  var _extensionNode$attrs, _extensionNode$attrs2;
  return ((_extensionNode$attrs = extensionNode.attrs) === null || _extensionNode$attrs === void 0 ? void 0 : _extensionNode$attrs.extensionType) === NESTED_TABLE_EXTENSION_TYPE && ((_extensionNode$attrs2 = extensionNode.attrs) === null || _extensionNode$attrs2 === void 0 ? void 0 : _extensionNode$attrs2.extensionKey) === NESTED_TABLE_EXTENSION_KEY;
};
var transformNestedTableExtension = function transformNestedTableExtension(nestedTableExtension) {
  var _nestedTableExtension;
  // No content - drop the extension node
  if (!((_nestedTableExtension = nestedTableExtension.attrs) !== null && _nestedTableExtension !== void 0 && (_nestedTableExtension = _nestedTableExtension.parameters) !== null && _nestedTableExtension !== void 0 && _nestedTableExtension.adf)) {
    return false;
  }
  try {
    var _nestedTableExtension2;
    var adf = JSON.parse((_nestedTableExtension2 = nestedTableExtension.attrs) === null || _nestedTableExtension2 === void 0 || (_nestedTableExtension2 = _nestedTableExtension2.parameters) === null || _nestedTableExtension2 === void 0 ? void 0 : _nestedTableExtension2.adf);
    if (!adf.content || adf.content.length === 0) {
      return false;
    }
    return adf.content[0];
  } catch (e) {
    throw new NodeNestingTransformError('Failed to parse nested table content');
  }
};
function isInsideBodiedExtension(parent) {
  var _parent$parent;
  if (parent.node === undefined) {
    return false;
  }
  if (parent.node.type === 'bodiedExtension') {
    return true;
  }
  if (parent !== null && parent !== void 0 && (_parent$parent = parent.parent) !== null && _parent$parent !== void 0 && _parent$parent.node) {
    return isInsideBodiedExtension(parent.parent);
  }
  return false;
}
export var transformNestedTablesIncomingDocument = function transformNestedTablesIncomingDocument(adf) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var isTransformed = false;
  var transformedAdf = traverse(adf, {
    extension: function extension(node, parent) {
      if (isNestedTableExtension(node)) {
        // Bodied extensions in renderer use their own nested renderer to render the content.
        // This results in the document being validated/transformed twice, once with untransformed content and again with transformed content.
        // Since the untransformed content is valid ADF (table as extension in table) but the transformed content is not valid ADF, (table in table)
        // we need to skip transforming nested tables inside bodied extensions in renderer on the first pass or else it will fail validation and render an unsupported block.
        if (options.environment === 'renderer' && isInsideBodiedExtension(parent)) {
          return undefined;
        }
        isTransformed = true;
        return transformNestedTableExtension(node);
      }
    }
  });
  return {
    transformedAdf: transformedAdf,
    isTransformed: isTransformed
  };
};
export var transformNestedTableNodeOutgoingDocument = function transformNestedTableNodeOutgoingDocument(tableCellNode) {
  try {
    var _tableCellNode$conten;
    return _objectSpread(_objectSpread({}, tableCellNode), {}, {
      content: (_tableCellNode$conten = tableCellNode.content) === null || _tableCellNode$conten === void 0 ? void 0 : _tableCellNode$conten.map(function (childNode) {
        // wrap nested table in an extension node
        if ((childNode === null || childNode === void 0 ? void 0 : childNode.type) === 'table') {
          return extension({
            extensionType: NESTED_TABLE_EXTENSION_TYPE,
            extensionKey: NESTED_TABLE_EXTENSION_KEY,
            parameters: {
              adf: JSON.stringify({
                type: 'doc',
                version: 1,
                content: [childNode]
              })
            }
          });
        }
        return childNode;
      })
    });
  } catch (e) {
    throw new NodeNestingTransformError('Failed to encode nested table node');
  }
};