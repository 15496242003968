import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _excluded = ["isLeftSidebarCollapsed", "label", "testId"];
/* eslint-disable @atlaskit/ui-styling-standard/no-unsafe-values */
/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import ChevronRight from '@atlaskit/icon/utility/migration/chevron-right';
import { easeOut } from '@atlaskit/motion/curves';
import { durations } from '@atlaskit/motion/durations';
import { UNSAFE_media } from '@atlaskit/primitives/responsive';
import { B100, B200, N0, N200, N30A } from '@atlaskit/theme/colors';
import { RESIZE_BUTTON_SELECTOR } from '../../common/constants';
var increaseHitAreaStyles = css({
  position: 'absolute',
  insetBlockEnd: "var(--ds-space-negative-300, -24px)",
  insetBlockStart: "var(--ds-space-negative-300, -24px)",
  insetInlineEnd: "var(--ds-space-negative-150, -12px)",
  insetInlineStart: "var(--ds-space-negative-100, -8px)"
});
var mobileStyles = css(_defineProperty({}, UNSAFE_media.below.sm, {
  opacity: 1
}));
var resizeIconButtonStyles = css({
  width: 24,
  height: 24,
  padding: "var(--ds-space-0, 0px)",
  position: 'absolute',
  backgroundColor: "var(--ds-surface-overlay, ".concat(N0, ")"),
  border: 0,
  borderRadius: "var(--ds-border-radius-circle, 50%)",
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage -- TODO: https://product-fabric.atlassian.net/browse/DSP-3392; this boxShadow needs further investigation, along with the hover and active background colors.
  boxShadow: "0 0 0 1px ".concat(N30A, ", 0 2px 4px 1px ").concat(N30A),
  color: "var(--ds-text-subtle, ".concat(N200, ")"),
  cursor: 'pointer',
  insetBlockStart: "var(--ds-space-400, 32px)",
  insetInlineStart: 0,
  /**
   * The fallback value of 0 ensures that the button is hidden by default,
   * unless some parent (or the button itself) overrides it.
   */
  opacity: "var(--ds--resize-button--opacity,0)",
  outline: 0,
  transform: 'translateX(-50%)',
  transition: "\n    background-color ".concat(durations.small, "ms linear,\n    color ").concat(durations.small, "ms linear,\n    opacity ").concat(durations.medium, "ms ").concat(easeOut, "\n  "),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  ':hover': {
    backgroundColor: "var(--ds-background-selected-bold, ".concat(B100, ")"),
    color: "var(--ds-text-inverse, ".concat(N0, ")"),
    opacity: 1
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  ':active, :focus': {
    backgroundColor: "var(--ds-background-selected-bold-hovered, ".concat(B200, ")"),
    color: "var(--ds-text-inverse, ".concat(N0, ")"),
    opacity: 1
  }
});
var resizeIconButtonExpandedStyles = css({
  transform: 'rotate(180deg)',
  transformOrigin: 7
});
var preventDefault = function preventDefault(event) {
  return event.preventDefault();
};
var cssSelector = _defineProperty({}, RESIZE_BUTTON_SELECTOR, true);
var ResizeButton = function ResizeButton(_ref) {
  var isLeftSidebarCollapsed = _ref.isLeftSidebarCollapsed,
    label = _ref.label,
    testId = _ref.testId,
    props = _objectWithoutProperties(_ref, _excluded);
  return jsx("button", _extends({}, cssSelector, {
    // DO NOT remove. used as a CSS selector.
    "aria-expanded": !isLeftSidebarCollapsed,
    "aria-label": label,
    type: "button"
    // The error goes away when we remove the spread ...props
    ,
    css: [resizeIconButtonStyles, mobileStyles, !isLeftSidebarCollapsed && resizeIconButtonExpandedStyles],
    "data-testid": testId
    // Prevents focus staying attached to the button when pressed
    ,
    onMouseDown: preventDefault
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, props), jsx(ChevronRight, {
    label: "",
    color: "currentColor"
  }), jsx("span", {
    css: increaseHitAreaStyles
  }));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default ResizeButton;