import memoizeOne from 'memoize-one';
import { extensionFrame, layoutSectionWithSingleColumn, multiBodiedExtension, expandWithNestedExpand, blockquoteWithNonBodiedMacrosStage0, listItemWithNonBodiedMacrosStage0, panelWithNonBodiedMacrosStage0, tableWithNestedTable, tableRowWithNestedTable, tableCellWithNestedTable, tableHeaderWithNestedTable, nestedExpandWithNonBodiedMacrosStage0 } from './nodes';
import { createSchema } from './create-schema';
var getDefaultSchemaConfig = function getDefaultSchemaConfig() {
  var defaultSchemaConfig = {
    nodes: ['doc', 'paragraph', 'text', 'bulletList', 'orderedList', 'listItem', 'heading', 'blockquote', 'codeBlock', 'panel', 'rule', 'image', 'caption', 'mention', 'media', 'mediaGroup', 'mediaSingle', 'mediaInline', 'confluenceUnsupportedBlock', 'confluenceUnsupportedInline', 'confluenceJiraIssue', 'expand', 'nestedExpand', 'extension', 'inlineExtension', 'bodiedExtension', 'hardBreak', 'emoji', 'table', 'tableCell', 'tableHeader', 'tableRow', 'decisionList', 'decisionItem', 'taskList', 'taskItem', 'unknownBlock', 'date', 'status', 'placeholder', 'layoutSection', 'layoutColumn', 'inlineCard', 'blockCard', 'embedCard', 'unsupportedBlock', 'unsupportedInline'],
    marks: ['link', 'em', 'strong', 'strike', 'subsup', 'underline', 'code', 'textColor', 'backgroundColor', 'confluenceInlineComment', 'breakout', 'alignment', 'indentation', 'annotation', 'dataConsumer', 'border', 'unsupportedMark', 'unsupportedNodeAttribute', 'typeAheadQuery',
    // https://product-fabric.atlassian.net/browse/ED-10214,
    'fragment']
  };
  return defaultSchemaConfig;
};
export var defaultSchemaConfig = getDefaultSchemaConfig();
export var getSchemaBasedOnStage = memoizeOne(function () {
  var stage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'final';
  var defaultSchemaConfig = getDefaultSchemaConfig();
  if (stage === 'stage0') {
    defaultSchemaConfig.customNodeSpecs = {
      layoutSection: layoutSectionWithSingleColumn,
      multiBodiedExtension: multiBodiedExtension,
      extensionFrame: extensionFrame,
      expand: expandWithNestedExpand,
      blockquote: blockquoteWithNonBodiedMacrosStage0,
      listItem: listItemWithNonBodiedMacrosStage0,
      panel: panelWithNonBodiedMacrosStage0(true),
      table: tableWithNestedTable,
      tableRow: tableRowWithNestedTable,
      tableCell: tableCellWithNestedTable,
      tableHeader: tableHeaderWithNestedTable,
      nestedExpand: nestedExpandWithNonBodiedMacrosStage0
    };
  }
  return createSchema(defaultSchemaConfig);
});
export var defaultSchema = getSchemaBasedOnStage();