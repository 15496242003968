import { blockquote as blockquoteFactory } from '../../next-schema/generated/nodeTypes';
import { blockquoteLegacy as blockquoteLegacyFactory, blockquoteWithNonBodiedMacrosStage0 as blockquoteWithNonBodiedMacrosStage0Factory, blockquoteWithoutNestedCodeblockOrMedia as blockquoteWithoutNestedCodeblockOrMediaFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name blockquote_node
 */

var nodeSpecOptions = {
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM: function toDOM() {
    return ['blockquote', 0];
  }
};
export var blockquote = blockquoteLegacyFactory(nodeSpecOptions);
export var blockquoteWithNestedCodeblockOrMedia = blockquoteFactory(nodeSpecOptions);

/**
 * @name blockquote_with_list_node
 */
export var blockquoteWithList = blockquoteWithoutNestedCodeblockOrMediaFactory({
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM: function toDOM() {
    return ['blockquote', 0];
  }
});
export var blockquoteWithNonBodiedMacrosStage0 = blockquoteWithNonBodiedMacrosStage0Factory({
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM: function toDOM() {
    return ['blockquote', 0];
  }
});