import React from 'react';

import Flag from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';
import store from 'src/utils/store';

import { messages } from './pull-request-draft-reviewers.i18n';

type Props = {
  id: ComponentFlagId;
} & HandleDismissedType;

export const PULL_REQUEST_DRAFT_REVIEWERS_FLAG_ID =
  'pull-request-draft-reviewers-flag-dismissed' as const;

export const getPullRequestDraftFlagDismissed = (): boolean => {
  return store.get(PULL_REQUEST_DRAFT_REVIEWERS_FLAG_ID);
};

export const PullRequestDraftReviewers = ({
  id,
  handleDismissed,
}: Props): JSX.Element => {
  const intl = useIntl();
  const dismiss = () => {
    store.set(PULL_REQUEST_DRAFT_REVIEWERS_FLAG_ID, true);
    handleDismissed(id);
  };

  const actions = [
    {
      content: intl.formatMessage(
        messages.pullRequestDraftReviewersFlagEditorDismiss
      ),
      onClick: () => dismiss(),
    },
  ];

  return (
    <Flag
      id={id}
      title={intl.formatMessage(messages.pullRequestDraftReviewersFlagTitle)}
      description={intl.formatMessage(
        messages.pullRequestDraftReviewersFlagDescription
      )}
      onDismissed={() => dismiss()}
      actions={actions}
      icon={
        <InfoIcon
          primaryColor={token('color.icon.information', colors.B600)}
          label="Info"
        />
      }
    />
  );
};
