import { useContext, useEffect } from 'react';
import { OpenLayerObserverContext } from './open-layer-observer-context';

/**
 * Hook that increments/decrements the open layer count when the component mounts/unmounts or becomes visible/hidden.
 * It is used to "notify" the layer observer(s) that a new layer has been added/opened.
 *
 * It takes an optional parameter with an `isOpen` option, which can be used to conditionally update the layer count
 * based on the visibility of the layered component.
 *
 * Example usage:
 * ```tsx
 * const [isOpen, setIsOpen] = useState(false); // State for controlling layer visibility
 * useNotifyLayerObserver({ isOpen });
 * ```
 */
export function useNotifyOpenLayerObserver() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$isOpen = _ref.isOpen,
    isOpen = _ref$isOpen === void 0 ? true : _ref$isOpen;
  var context = useContext(OpenLayerObserverContext);
  useEffect(function () {
    if (context === null) {
      return;
    }
    if (!isOpen) {
      return;
    }
    context.increment();
    return function () {
      context.decrement();
    };
  }, [context, isOpen]);
}