import _extends from "@babel/runtime/helpers/extends";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { jsx } from '@emotion/react';
import { isAppleDevice } from '@atlaskit/ds-lib/device-check';
import { fg } from '@atlaskit/platform-feature-flags';
import { getStyleProps } from '../utils';
import A11yText from './internal/a11y-text';
export var optionCSS = function optionCSS(_ref) {
  var isDisabled = _ref.isDisabled,
    isFocused = _ref.isFocused,
    isSelected = _ref.isSelected;
  var color = "var(--ds-text, #172B4D)";
  if (isDisabled) {
    color = "var(--ds-text-disabled, #091E424F)";
  } else if (isSelected) {
    color = "var(--ds-text-selected, #0C66E4)";
  }
  var boxShadow;
  var backgroundColor;
  if (isDisabled) {
    backgroundColor = undefined;
  } else if (isSelected && isFocused) {
    backgroundColor = "var(--ds-background-selected-hovered, #CCE0FF)";
  } else if (isSelected) {
    backgroundColor = "var(--ds-background-selected, #E9F2FF)";
  } else if (isFocused) {
    backgroundColor = "var(--ds-background-neutral-subtle-hovered, #091E420F)";
  }
  if (!isDisabled && (isFocused || isSelected)) {
    boxShadow = "inset 2px 0px 0px ".concat("var(--ds-border-selected, #0C66E4)");
  }
  var cursor = isDisabled ? 'not-allowed' : 'default';
  return {
    label: 'option',
    display: 'block',
    // eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
    fontSize: 'inherit',
    width: '100%',
    userSelect: 'none',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    padding: "var(--ds-space-075, 6px)".concat(" ", "var(--ds-space-150, 12px)"),
    backgroundColor: backgroundColor,
    color: color,
    cursor: cursor,
    boxShadow: boxShadow,
    ':active': {
      backgroundColor: !isDisabled ? isSelected ? "var(--ds-background-selected-pressed, #85B8FF)" : "var(--ds-background-neutral-subtle-pressed, #091E4224)" : undefined
    },
    '@media screen and (-ms-high-contrast: active)': {
      borderLeft: !isDisabled && (isFocused || isSelected) ? '2px solid transparent' : ''
    }
  };
};
var Option = function Option(props) {
  var children = props.children,
    isDisabled = props.isDisabled,
    isFocused = props.isFocused,
    isSelected = props.isSelected,
    innerRef = props.innerRef,
    innerProps = props.innerProps;
  // eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
  var isVoiceOver = isAppleDevice() && fg('design_system_select-a11y-improvement');
  return jsx("div", _extends({}, getStyleProps(props, 'option', {
    option: true,
    'option--is-disabled': isDisabled,
    'option--is-focused': isFocused,
    'option--is-selected': isSelected
  }), {
    ref: innerRef
  }, innerProps, {
    tabIndex: -1
  }), children, isVoiceOver && (isSelected || isDisabled) && jsx(A11yText, null, "".concat(isSelected ? ',selected' : '').concat(isDisabled ? ',dimmed' : '')));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Option;