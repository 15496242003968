import { useContext } from 'react';
import { RouterLinkProviderContext } from '../index';

/**
 * __useRouterLink()__
 *
 * Hook: Returns app-configured router link component.
 *
 * A generic can be passed to define advanced link configuration:
 * ```
 * type MyRouterLinkConfig = {
 *  to: string;
 *  replace?: boolean;
 * }
 *
 * const RouterLink = useRouterLink<MyRouterLinkConfig>();
 * ```
 */
var useRouterLink = function useRouterLink() {
  var _useContext = useContext(RouterLinkProviderContext),
    routerLinkComponent = _useContext.routerLinkComponent;
  if (routerLinkComponent) {
    return routerLinkComponent;
  }
};
export default useRouterLink;