import React from 'react';

import Flag from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';
import store from 'src/utils/store';

export const AUTOREVIEW_DOGFOODING_FLAG_STORE_KEY =
  'autoreview.dogfooding.flag.seen.v2';

export const AUTOREVIEW_DOGFOODING_FLAG_ID = 'autoreview-dogfooding' as const;

type Props = {
  id: ComponentFlagId;
} & HandleDismissedType;

export const AutoreviewDogfoodingFlag = ({
  id,
  handleDismissed,
}: Props): JSX.Element => {
  const description = (
    <p>
      Atlassian staff - We would love to hear your feedback on Autoreview before
      we release it to customers. Please{' '}
      <a target="_blank" href="https://forms.gle/1ZyvAasdHo1VPMN5A">
        take this 5 minute survey
      </a>{' '}
      and let us know if it’s hitting the mark.
    </p>
  );
  return (
    <Flag
      id={id}
      icon={
        <InfoIcon
          primaryColor={token('color.icon.information', colors.B400)}
          label="Info"
        />
      }
      title="Tell us what you think of Autoreview!"
      description={description}
      onDismissed={() => {
        store.set(AUTOREVIEW_DOGFOODING_FLAG_STORE_KEY, true);
        handleDismissed(AUTOREVIEW_DOGFOODING_FLAG_ID);
      }}
    />
  );
};
