export function getMousePosition(mouseCoordinates) {
  var safeMouse = mouseCoordinates || {
    top: 0,
    left: 0
  };
  var getBoundingClientRect = function getBoundingClientRect() {
    return {
      top: safeMouse.top,
      left: safeMouse.left,
      bottom: safeMouse.top,
      right: safeMouse.left,
      width: 0,
      height: 0
    };
  };
  return {
    getBoundingClientRect: getBoundingClientRect,
    clientWidth: 0,
    clientHeight: 0
  };
}