/**
 * Largely taken from analytics-web-react
 */
import merge from 'lodash/merge';
var extractFromEventContext = function extractFromEventContext(propertyNames, event) {
  var namespacedContextOnly = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var contextName = arguments.length > 3 ? arguments[3] : undefined;
  return event.context.reduce(function (acc, contextItem) {
    propertyNames.forEach(function (propertyName) {
      var navContext = contextItem[contextName];
      var navContextProp = navContext ? navContext[propertyName] : null;
      var value = namespacedContextOnly ? navContextProp : navContextProp || contextItem[propertyName];
      if (value) {
        acc.push(value);
      }
    });
    return acc;
  }, []);
};
export var getSources = function getSources(event, contextName) {
  return extractFromEventContext(['source'], event, false, contextName);
};
export var getComponents = function getComponents(event, contextName) {
  return extractFromEventContext(['component', 'componentName'], event, false, contextName);
};
export var getExtraAttributes = function getExtraAttributes(event, contextName) {
  return extractFromEventContext(['attributes'], event, true, contextName).reduce(function (result, extraAttributes) {
    return merge(result, extraAttributes);
  }, {});
};
export var getPackageInfo = function getPackageInfo(event, contextName) {
  return event.context.map(function (contextItem) {
    var navContext = contextItem[contextName];
    var item = navContext ? navContext : contextItem;
    return {
      packageName: item.packageName,
      packageVersion: item.packageVersion
    };
  }).filter(function (p) {
    return p.packageName;
  });
};
export var getPackageVersion = function getPackageVersion(event, contextName) {
  return extractFromEventContext(['packageVersion'], event, true, contextName);
};