import _defineProperty from "@babel/runtime/helpers/defineProperty";
/* eslint-disable @repo/internal/react/require-jsdoc */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
export var CSS_VAR_CONTENTS_OPACITY = '--contents-opacity';
var containerStyles = css({
  position: 'relative'
});
export var Container = function Container(_ref) {
  var children = _ref.children,
    testId = _ref.testId;
  return jsx("div", {
    css: containerStyles,
    "data-testid": testId && "".concat(testId, "--container")
  }, children);
};
var contentsContainerStyles = css({
  opacity: "var(".concat(CSS_VAR_CONTENTS_OPACITY, ")"),
  pointerEvents: 'none'
});
export var ContentsContainer = function ContentsContainer(_ref2) {
  var children = _ref2.children,
    contentsOpacity = _ref2.contentsOpacity,
    testId = _ref2.testId;
  return jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    style: _defineProperty({}, CSS_VAR_CONTENTS_OPACITY, contentsOpacity),
    css: [contentsContainerStyles],
    "data-testid": testId && "".concat(testId, "--contents--container")
  }, children);
};
var spinnerContainerStyles = css({
  display: 'flex',
  position: 'absolute',
  inset: 0,
  alignItems: 'center',
  justifyContent: 'center'
});
export var SpinnerContainer = function SpinnerContainer(_ref4) {
  var children = _ref4.children,
    testId = _ref4.testId;
  return jsx("div", {
    css: spinnerContainerStyles,
    "data-testid": testId && "".concat(testId, "--spinner--container")
  }, children);
};