import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { Targets } from '@atlassiansox/cross-flow-base-types';
import { WAC_STAGING_URL, WAC_PROD_URL, WAC_BUNDLE_SIGNUP_PATH, OPSGENIE_SIGNUP_PATH, WAC_SIGNUP_PATH } from './constants';
import { UtmCampaign } from '../types';
var utmCampaignMap = {
  'atlassian-switcher': UtmCampaign['atlassian-switcher']
};
var isBundledProduct = function isBundledProduct(productKey) {
  return [Targets.JIRA_SOFTWARE, Targets.JIRA_SERVICE_DESK, Targets.JIRA_CORE, Targets.CONFLUENCE].includes(productKey);
};
var getBundleSignUpParams = function getBundleSignUpParams(productKey) {
  var bundles = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, Targets.JIRA_SOFTWARE, 'jira-software'), Targets.JIRA_SERVICE_DESK, 'jira-service-desk'), Targets.JIRA_CORE, 'jira-core'), Targets.CONFLUENCE, 'confluence');
  // Bundles default to free
  return "bundle=".concat(bundles[productKey], "&edition=free");
};
var isProductWithCustomSignUpPath = function isProductWithCustomSignUpPath(productKey) {
  return [Targets.OPSGENIE].includes(productKey);
};
var getProductCustomSignUpPath = function getProductCustomSignUpPath(productKey) {
  // Only Opsgenie. Trello, Bitbucket and Statuspage are handled by Product Store directly.
  var links = _defineProperty({}, Targets.OPSGENIE, OPSGENIE_SIGNUP_PATH);
  return links[productKey];
};
var isSourceComponentWithUtmParams = function isSourceComponentWithUtmParams(sourceComponent) {
  return Boolean(utmCampaignMap[sourceComponent]);
};
var getUtmParams = function getUtmParams(sourceComponent, sourceContext) {
  var utmParams = '';
  if (sourceComponent && isSourceComponentWithUtmParams(sourceComponent)) {
    utmParams = utmParams + "utm_campaign=".concat(utmCampaignMap[sourceComponent], "&utm_medium=in_product_ad");
    if (sourceContext) {
      utmParams = utmParams + "&utm_source=".concat(sourceContext);
    }
  }
  return utmParams;
};
export var getProductSignUpLink = function getProductSignUpLink(productKey, env, sourceComponent, sourceContext) {
  var wacUrl = env === 'staging' ? WAC_STAGING_URL : WAC_PROD_URL;
  var utmParams = getUtmParams(sourceComponent, sourceContext);
  var queryParams = '';
  var bundleParams = '';
  var path = WAC_SIGNUP_PATH;
  if (isBundledProduct(productKey)) {
    path = WAC_BUNDLE_SIGNUP_PATH;
    bundleParams = getBundleSignUpParams(productKey);
  } else if (isProductWithCustomSignUpPath(productKey)) {
    path = getProductCustomSignUpPath(productKey);
  }
  if (!!bundleParams) {
    queryParams = queryParams + "?".concat(bundleParams);
  }
  if (!!utmParams) {
    queryParams = queryParams + (!!queryParams ? '&' : '?') + utmParams;
  }
  return wacUrl + path + queryParams;
};
export var setProductSignUpLocation = function setProductSignUpLocation(productKey) {
  var env = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'production';
  var sourceComponent = arguments.length > 2 ? arguments[2] : undefined;
  var sourceContext = arguments.length > 3 ? arguments[3] : undefined;
  var url = getProductSignUpLink(productKey, env, sourceComponent, sourceContext);
  window.open(url, '_blank');
};