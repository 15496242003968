import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Inspired by analytics-web-react
 */

import last from 'lodash/last';
import merge from 'lodash/merge';
import { DEFAULT_SOURCE, UI_EVENT_TYPE, SCREEN_EVENT_TYPE, TRACK_EVENT_TYPE, OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { getSources, getExtraAttributes, getPackageInfo, getComponents } from '../helpers/extract-data-from-event';
import { NOTIFICATIONS_CONTEXT } from '@atlaskit/analytics-namespaced-context';
import { FabricChannel } from '../types';
var listenerVersion = "8.14.0";

/**
 * This util exists to convert the analytics-next event format into the analytics platform format.
 *
 * Analytics-next event format:
 * event {
 *      payload: {
 *          ...attributesFromLowestPointInTheTree
 *      },
 *      context: [{
 *          ...attributesFromHighestPointInTheTree
 *      }, {
 *          ...attributesFromSecondHighestPointInTheTree
 *      }]
 * }
 *
 * Analytics platform event format:
 *  event {
 *      type: @atlaskit/analytics-gas-types.EventType
 *      payload {
 *          ...mandatoryAttributesBasedOnEventType
 *          attributes: {
 *              ...arbitraryAttributes
 *          }
 *      }
 *  }
 */

export default (function (event, logger) {
  var sources = getSources(event, NOTIFICATIONS_CONTEXT);
  var source = last(sources) || DEFAULT_SOURCE;
  var extraAttributes = getExtraAttributes(event, NOTIFICATIONS_CONTEXT);
  var components = getComponents(event, NOTIFICATIONS_CONTEXT);
  var packages = getPackageInfo(event, NOTIFICATIONS_CONTEXT);
  var _ref = last(getPackageInfo(event, NOTIFICATIONS_CONTEXT)) || {},
    packageName = _ref.packageName,
    packageVersion = _ref.packageVersion;
  var packageHierarchy = packages.map(function (p) {
    return p.packageVersion ? "".concat(p.packageName, "@").concat(p.packageVersion) : p.packageName;
  });
  var _event$payload = event.payload,
    _event$payload$eventT = _event$payload.eventType,
    eventType = _event$payload$eventT === void 0 ? UI_EVENT_TYPE : _event$payload$eventT,
    action = _event$payload.action,
    actionSubject = _event$payload.actionSubject,
    actionSubjectId = _event$payload.actionSubjectId,
    payloadAttributes = _event$payload.attributes,
    name = _event$payload.name;
  var attributes = _objectSpread(_objectSpread({
    listenerVersion: listenerVersion,
    sourceHierarchy: sources.join('.') || undefined,
    componentHierarchy: components.join('.') || undefined,
    packageHierarchy: packageHierarchy.join(',') || undefined
  }, {
    packageName: packageName,
    packageVersion: packageVersion
  }), merge(extraAttributes, payloadAttributes));
  // Ensure navigation tag is not duplicated by using Set
  var tags = new Set(event.payload.tags || []);
  tags.add(FabricChannel.notifications);
  if (event.payload) {
    switch (eventType) {
      case UI_EVENT_TYPE:
      case OPERATIONAL_EVENT_TYPE:
      case TRACK_EVENT_TYPE:
        return {
          eventType: eventType,
          source: source,
          actionSubject: actionSubject,
          action: action,
          actionSubjectId: actionSubjectId,
          attributes: attributes,
          tags: Array.from(tags)
        };
      case SCREEN_EVENT_TYPE:
        return {
          eventType: eventType,
          name: name,
          attributes: attributes,
          tags: Array.from(tags)
        };
      default:
        logger.error('Invalid event type', eventType);
        break;
    }
  }
  return null;
});