/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const PermissionsTableContainer = styled.div`
  & th {
    line-height: ${token('space.300', '24px')};
  }
`;

export const HeaderCheckbox = styled.span`
  vertical-align: bottom;
`;

export const GroupAvatarContainer = styled.div`
  background: ${token('color.background.accent.gray.subtler', colors.N50)};
  border-radius: 50%;
  border: ${token('space.025', '2px')} solid
    ${token('color.border.inverse', colors.N0)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextFieldSection = styled.div`
  margin-bottom: ${token('space.200', '16px')};
  margin-top: ${token('space.150', '12px')};
  display: flex;
  align-items: center;

  & > div:nth-of-type(1) {
    margin-right: ${token('space.200', '16px')};
  }
`;

export const BulkActionsHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${token('color.text.subtle', colors.N800)};
  font: ${token('font.body')};

  & button:nth-of-type(2),
  & button:nth-of-type(3) {
    margin-left: ${token('space.150', '12px')};
  }
`;

export const DetailsTableWrapper = styled.div`
  tbody {
    border-bottom: ${token('space.025', '2px')} solid
      ${token('color.border', colors.N40)};
    > tr {
      height: ${token('space.500', '40px')};
    }
  }
`;

export const DetailsTableFooter = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${token('space.200', '16px')};
  margin-bottom: ${token('space.800', '64px')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
`;

export const DetailsTablePaginationWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const PrivilegesDropdownText = styled.span`
  display: inline-flex;
  padding: 0 ${token('space.150', '12px')};
  align-items: center;
  cursor: default;
`;

export const PrivilegeWithIcon = styled.div`
  display: inline-flex;
  align-items: center;
  [role='img'] {
    line-height: inherit;
  }
`;
