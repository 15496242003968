import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
/**
 * Returns a new object with only non-empty properties of the input object.
 *
 * In this context, "empty" refers to properties with `null`, `undefined`, or `""`
 * (empty string) values.
 *
 * @param obj - The input object.
 * @returns A new object with non-empty properties of the input object.
 */
export function stripEmptyProperties(obj) {
  return Object.entries(obj).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    if (value !== null && value !== undefined && value !== '') {
      acc[key] = value;
    }
    return acc;
  }, {});
}