import { BuildConfiguration } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  CLEAR_BUILD_CONFIGURATION,
  REQUEST_BUILD_CONFIGURATION,
  REQUEST_COMMIT_CONFIGURATION,
} from '../actions/pipelines';

interface BuildConfigurationSuccessAction extends Action {
  type: typeof REQUEST_BUILD_CONFIGURATION.SUCCESS;
  payload: { yml: string; ymlPath: string };
  meta: any;
}
function searchConfiguration(pattern: string, file: string): number {
  const lines = file.split(/\r\n|\r|\n/);
  let matchingLineNumber = 0;
  lines.some((line, i) => {
    if (line.startsWith(`${'    '}${pattern}:`)) {
      matchingLineNumber = i;
      return true;
    }
    return false;
  });
  return matchingLineNumber + 1; // Source code line numbers start at 1.
}

export default createReducer(new BuildConfiguration(), {
  [CLEAR_BUILD_CONFIGURATION]() {
    return new BuildConfiguration();
  },
  [REQUEST_BUILD_CONFIGURATION.REQUEST](state: BuildConfiguration) {
    return new BuildConfiguration({
      ...state?.toJS?.(),
      hasFetchedRawYmlFile: false,
    });
  },
  [REQUEST_BUILD_CONFIGURATION.SUCCESS](
    state: BuildConfiguration,
    action: BuildConfigurationSuccessAction & { meta: { pattern: string } }
  ) {
    return new BuildConfiguration({
      ...state?.toJS?.(),
      hasFetchedRawYmlFile: true,
      hasYmlFile: true,
      matchingLineNumber: searchConfiguration(
        action.meta.pattern,
        action.payload.yml || ''
      ),
      yml: action.payload.yml,
      ymlPath: action.payload.ymlPath,
    });
  },
  [REQUEST_BUILD_CONFIGURATION.ERROR](state: BuildConfiguration) {
    return new BuildConfiguration({
      ...state?.toJS?.(),
      hasFetchedRawYmlFile: true,
      hasYmlFile: false,
    });
  },
  [REQUEST_COMMIT_CONFIGURATION.REQUEST](state: BuildConfiguration) {
    return new BuildConfiguration({
      ...state?.toJS?.(),
      isCommiting: true,
    });
  },
  [REQUEST_COMMIT_CONFIGURATION.SUCCESS](state: BuildConfiguration) {
    return new BuildConfiguration({
      ...state?.toJS?.(),
      isCommiting: false,
    });
  },
  [REQUEST_COMMIT_CONFIGURATION.ERROR](
    state: BuildConfiguration,
    action: Action<any>
  ) {
    return new BuildConfiguration({
      ...state?.toJS?.(),
      isCommiting: false,
      isCommitingError: action.payload,
    });
  },
});
