import React, { PureComponent } from 'react';

import { AvatarPropTypes } from '@atlaskit/avatar';
import { ProfilecardTriggerPosition } from '@atlaskit/profilecard';
import Tooltip from '@atlaskit/tooltip';

import BaseUserAvatar from './base-user-avatar';
import { deletedUserProfileClient } from './deleted-user-profile-client';
import UserProfileCardTrigger from './user-profile-card-trigger';
import { getName } from './utils';

export type DeletedUserAvatarProps = {
  /** A flag indicating whether or not to use the avatar as a trigger for a user profile card. */
  hasProfileCard?: boolean;
  /** The position where the profile card should appear (if enabled), relative to the contents of the avatar. */
  profileCardPosition?: ProfilecardTriggerPosition;
  /** The type of interaction that will trigger the user profile card. */
  profileCardTrigger?: 'click' | 'hover';
  /** Customize the `name` prop passed to `@atlaskit/avatar`. Should incorporate the `privateName` argument into the return value. */
  renderName: (privateName: string) => string;
};

export default class DeletedUserAvatar extends PureComponent<
  DeletedUserAvatarProps & AvatarPropTypes
> {
  static defaultProps = {
    renderName: (name: string) => name,
  };

  render() {
    const {
      hasProfileCard,
      profileCardPosition,
      profileCardTrigger,
      renderName,
      ...avatarProps
    } = this.props;

    const avatar = <BaseUserAvatar {...avatarProps} renderName={renderName} />;
    const fullName = getName(undefined);

    if (!hasProfileCard) {
      const name = renderName(fullName);
      return <Tooltip content={name}>{avatar}</Tooltip>;
    }

    return (
      <UserProfileCardTrigger
        position={profileCardPosition}
        profileClient={deletedUserProfileClient}
        trigger={profileCardTrigger}
        // The underlying Fabric component expects a non-empty userId string
        userId="null"
        fullName={fullName}
      >
        {avatar}
      </UserProfileCardTrigger>
    );
  }
}
