import React, { ReactNode, useMemo, useRef } from 'react';

import { useResource } from 'react-resource-router';

import { projectNavigationResource } from 'src/sections/project/project-navigation-resource';
import { repositoryNavigationResource } from 'src/sections/repository/repository-navigation-resource';
import { workspaceNavigationResource } from 'src/sections/workspace/workspace-navigation-resource';
import { NavigationData } from 'src/types/navigation';

export type NavData = {
  menuItems?: NavigationData['menuItems'];
  links?: NavigationData['links'];
  user?: NavigationData['user'];
};

const initialValue = {
  user: undefined,
  links: undefined,
  menuItems: undefined,
};

export const NavDataContext = React.createContext<NavData>(initialValue);

export const NavDataProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const hasUpdatedRef = useRef(false);

  const { data: workspaceData } = useResource(workspaceNavigationResource);
  const { data: projectData } = useResource(projectNavigationResource);
  const { data: repoData } = useResource(repositoryNavigationResource);

  const currentResourceData = repoData || projectData || workspaceData;
  const { menuItems, links, user } = currentResourceData || initialValue;

  const navDataValue = useMemo(() => {
    return {
      user,
      links,
      menuItems,
    };
  }, [menuItems, links, user]);

  if (currentResourceData) {
    hasUpdatedRef.current = true;
  }

  const value = hasUpdatedRef.current ? navDataValue : initialValue;

  return (
    <NavDataContext.Provider value={value}>{children}</NavDataContext.Provider>
  );
};
