import { useCallback, useRef } from 'react';
/**
 * This will run the respective passed in callback functions when modal is
 * opened or closed.
 */
export default function useOnMotionFinish(_ref) {
  var onOpenComplete = _ref.onOpenComplete,
    onCloseComplete = _ref.onCloseComplete;
  var motionRef = useRef(null);
  var onMotionFinish = useCallback(function (state) {
    if (state === 'entering' && onOpenComplete) {
      onOpenComplete(motionRef.current, true);
    }
    if (state === 'exiting' && onCloseComplete) {
      onCloseComplete(motionRef.current);
    }
  }, [onOpenComplete, onCloseComplete]);
  return [motionRef, onMotionFinish];
}