/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const ReadViewContainer = styled.div`
  font: ${token('font.heading.large')};
  padding: ${token('space.075')} ${token('space.075')};
  word-break: break-word;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const EditViewContainer = styled.div<{
  scrollHeight: number;
}>`
  & textarea {
    font: ${token('font.heading.large')};
    height: ${props =>
      props.scrollHeight ||
      28}px !important; /* Override the the inline height rule that AtlasKit injects */
    word-break: break-word;
  }
`;

export const ButtonGroupContainer = styled.div`
  margin-top: ${token('space.100')};
`;

export const WorkspaceInfoSection = styled.div`
  box-shadow: 0 3px 5px ${colors.N50A}, 0 0 1px ${colors.N60A};
  border-radius: 4px;
  padding: ${token('space.250')};
  margin-bottom: ${token('space.500')};
  display: flex;
  justify-content: space-between;
`;

export const EditGroupButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const WorkspaceInfoHeader = styled.h3`
  font: ${token('font.heading.xsmall')};
  margin-top: ${token('space.200')};
`;

export const DropdownItemContent = styled.div`
  min-width: 160px;
`;
