import React, { useEffect, useState, useMemo } from 'react';

import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import { ApolloClient, from, HttpLink } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { IntlProvider as IntlNextProvider } from 'react-intl-next';
import { Provider } from 'react-redux';

import FabricAnalyticsListeners, {
  FabricChannel,
} from '@atlaskit/analytics-listeners';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { SpotlightManager } from '@atlaskit/onboarding';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { Provider as ConnectProvider } from '@atlassian/bitbucket-connect-react';
import { StatsigFeatureKeys } from '@atlassian/bitbucket-features';
import {
  FactContext,
  AnalyticsClientManager,
} from '@atlassian/bitkit-analytics';
import { initializeControls } from '@atlassian/browser-storage-controls';
import { ExperienceTrackerContext } from '@atlassian/experience-tracker';
import { UFOLoggerLink } from '@atlassian/ufo-apollo-log/link';
import CrossFlowProvider from '@atlassiansox/cross-flow-support/bitbucket';

import DevAssetLoader from 'src/components/dev-asset-loader';
import Favicon from 'src/components/favicon';
import { Heartbeat } from 'src/components/heartbeat/heartbeat';
import KeyboardShortcutDialog from 'src/components/keyboard-shortcut-dialog';
import {
  useReactUFOWatcher,
  BitbucketCustomData,
} from 'src/components/react-ufo';
import { LoadRecentWorkspaces } from 'src/components/recent-workspace-loader';
import StatuspagePoller from 'src/components/statuspage-poller';
import { AddonManager, ConnectHost, sendAnalyticsEvent } from 'src/connect';
import { DEFAULT_TITLE, TITLE_TEMPLATE } from 'src/constants/helmet';
import { EngagementProvider } from 'src/contexts/engagement-provider';
import {
  FeatureProvider,
  StatsigFeatures,
  useStatsigGate,
} from 'src/contexts/feature-provider';
import { ForgeAppsProvider } from 'src/contexts/forge-apps-provider';
import { PostOfficeContextProvider } from 'src/contexts/post-office-context-provider';
import { StatsigConfigProvider } from 'src/contexts/statsig-config-provider';
import { InjectIntlContext } from 'src/hooks/intl';
import {
  getRoutes,
  history,
  Router as ResourceRouter,
  getRouteFeaturesFromAppData,
} from 'src/router';
import GlobalCreateBranchDialog from 'src/sections/create-branch/containers/global-dialog';
import TermsAndConditionsModal from 'src/sections/global/components/terms-and-conditions-modal';
import Flags from 'src/sections/global/containers/flags';
import MarketingConsentManager from 'src/sections/global/containers/marketing-consent-manager';
import SiteMessageFlagManager from 'src/sections/global/containers/site-message-flag-manager';
import { AnalyticsBridgeListener } from 'src/utils/analytics/analytics-bridge';
import { analyticsClient } from 'src/utils/analytics/client';
import { getExperienceTracker } from 'src/utils/analytics/experience-tracker';
import { publishFact } from 'src/utils/analytics/publish';

import { useBrowserMetricsWatcher } from '../components/browser-metrics';
import { getContainersPlugin, getUsersPlugin } from '../growth/cross-flow';
import locale from '../locale';
import { settings } from '../settings';
import { SpaComponentProps } from '../ssr/common/types';
import { bitbucketStore as ourStore } from '../store';

import { CurrentDataProvider, CurrentDataContext } from './current-data';
import { AppDataProvider } from './data';
import { NavDataProvider } from './nav-data';
import {
  LocalizationMessages,
  getDefaultMessages,
  getPlatformMessages,
} from './platform-messages';
import { UniversalRoutes } from './universal-routes';

const EXCLUDED_CHANNELS = [
  FabricChannel.atlaskit,
  FabricChannel.elements,
  FabricChannel.editor,
];

const GRAPHQL_CLIENT_NAME = 'Bitbucket Front End';

// SpaComponentProps are provided in SSR
type RootProps = Partial<SpaComponentProps>;

/* eslint @typescript-eslint/ban-types: "warn" */
const Root: React.FC<RootProps> = ({
  appSettings = settings(),
  isSsr = false,
  location = '',
  appData = {
    navigationIsOpen: true,
    features: {},
    frontbucket_environment: 'local',
    frontbucket_version: 'local',
    links: {
      overviewUrl: '',
      backButtonUrl: undefined,
    },
    initialContext: {},
  },
}) => {
  useBrowserMetricsWatcher();
  useReactUFOWatcher();
  const routes = getRoutes(getRouteFeaturesFromAppData(appData));
  const [platformMessages, setPlatformMessages] =
    useState<LocalizationMessages>({});

  const hasWebStorageControls = useStatsigGate(
    StatsigFeatureKeys.webStorageControls
  );

  useEffect(() => {
    initializeControls({
      product: 'Bitbucket',
      webStorageEnabled: hasWebStorageControls,
    });
  }, [hasWebStorageControls]);

  useEffect(() => {
    const fetchPlatformMessages = async () => {
      try {
        const translations = await Promise.all(
          getPlatformMessages(locale.locale)
        );
        const allTranslations = translations.reduce((acc, translation) => {
          const defaults = getDefaultMessages(translation);
          // Only merge the keys with string values from the default messages
          // See https://ops.internal.atlassian.com/jira/browse/HOT-84998
          const filteredDefaults = Object.fromEntries(
            Object.entries(defaults).filter(
              ([_key, value]) => typeof value === 'string'
            )
          );
          return { ...acc, ...filteredDefaults };
        }, {});
        setPlatformMessages(allTranslations);
      } catch (e) {
        Sentry.captureException(e);
      }
    };
    fetchPlatformMessages();
  }, []);

  const apolloClient = useMemo(() => {
    return new ApolloClient({
      connectToDevTools: process.env.NODE_ENV !== 'production',
      link: from([
        UFOLoggerLink,
        new HttpLink({
          credentials: 'include',
          uri: '/gateway/api/graphql',
          headers: {
            'atl-client-name': GRAPHQL_CLIENT_NAME,
            'atl-client-version': appData.frontbucket_version,
          },
        }),
      ]),
      cache: new InMemoryCache(),
    });
  }, [appData.frontbucket_version]);

  // Locale messages are sent from transifex and contain all of the translated frontbucket + bitkit strings.
  // Platform messages are included from the platform package distributions.
  const combinedMessages = {
    ...locale.messages,
    ...platformMessages,
  };

  return (
    <UFOSegment name="bitbucket-root">
      <IntlProvider locale={locale.locale} messages={combinedMessages}>
        <IntlNextProvider locale={locale.locale} messages={combinedMessages}>
          <InjectIntlContext>
            <AppDataProvider appData={appData}>
              <CurrentDataProvider initialContext={appData.initialContext}>
                <CurrentDataContext.Consumer>
                  {currentData => (
                    <AnalyticsClientManager
                      analyticsClient={analyticsClient()}
                      accountId={appData.user?.aaid || undefined}
                      workspaceId={
                        currentData.workspace?.uuid || appData.tenantId
                      }
                    />
                  )}
                </CurrentDataContext.Consumer>
                <NavDataProvider>
                  <ApolloProvider client={apolloClient}>
                    <PostOfficeContextProvider>
                      <CrossFlowProvider
                        analyticsClient={analyticsClient()}
                        locale={locale.locale}
                        plugins={[getContainersPlugin, getUsersPlugin]}
                      >
                        <SmartCardProvider>
                          <Provider store={ourStore}>
                            <LoadRecentWorkspaces />
                            <ConnectProvider
                              connectHost={ConnectHost}
                              addonManager={AddonManager}
                              analyticsEventHandler={sendAnalyticsEvent}
                            >
                              <FactContext.Provider value={{ publishFact }}>
                                <FabricAnalyticsListeners
                                  client={analyticsClient()}
                                  excludedChannels={EXCLUDED_CHANNELS}
                                >
                                  <AnalyticsBridgeListener>
                                    <SpotlightManager>
                                      <ExperienceTrackerContext.Provider
                                        value={getExperienceTracker()}
                                      >
                                        <ResourceRouter
                                          history={history}
                                          routes={routes}
                                          resourceContext={{
                                            reduxStore: ourStore,
                                            appSettings,
                                            appData,
                                          }}
                                          isSsr={isSsr}
                                          location={location}
                                        >
                                          <FeatureProvider>
                                            <StatsigConfigProvider>
                                              <EngagementProvider>
                                                <StatsigFeatures />
                                                <ForgeAppsProvider>
                                                  <BitbucketCustomData />
                                                  <KeyboardShortcutDialog />
                                                  <Favicon />
                                                  <StatuspagePoller />
                                                  <DevAssetLoader />
                                                  <Flags />
                                                  <Helmet
                                                    defaultTitle={DEFAULT_TITLE}
                                                    titleTemplate={
                                                      TITLE_TEMPLATE
                                                    }
                                                  />
                                                  <MarketingConsentManager />
                                                  <SiteMessageFlagManager />
                                                  <TermsAndConditionsModal />
                                                  {
                                                    // @ts-ignore
                                                    <GlobalCreateBranchDialog />
                                                  }
                                                  {
                                                    // @ts-ignore: requires @atlaskit/router to be bumped after AKR-23 released
                                                    <UniversalRoutes />
                                                  }
                                                  <Heartbeat />
                                                </ForgeAppsProvider>
                                              </EngagementProvider>
                                            </StatsigConfigProvider>
                                          </FeatureProvider>
                                        </ResourceRouter>
                                      </ExperienceTrackerContext.Provider>
                                    </SpotlightManager>
                                  </AnalyticsBridgeListener>
                                </FabricAnalyticsListeners>
                              </FactContext.Provider>
                            </ConnectProvider>
                          </Provider>
                        </SmartCardProvider>
                      </CrossFlowProvider>
                    </PostOfficeContextProvider>
                  </ApolloProvider>
                </NavDataProvider>
              </CurrentDataProvider>
            </AppDataProvider>
          </InjectIntlContext>
        </IntlNextProvider>
      </IntlProvider>
    </UFOSegment>
  );
};

export default Root;
