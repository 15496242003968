import React, { Component } from 'react';

import PropTypes from 'prop-types';

import {
  ConnectHost,
  AddonManager,
  SendEvent,
} from '@atlassian/bitbucket-connect-js';
import {
  DialogModule,
  FlagModule,
  MessageModule,
  InlineDialogModule,
} from '@atlassian/connect-module-core';

import AddonProvider from '../internal/addon/provider';
import Dialog from '../internal/dialog/dialog';
import DialogProvider, {
  DialogProvider as DialogProviderCls,
} from '../internal/dialog/provider';
import Flag from '../internal/flag/flag';
import FlagProvider, {
  FlagProvider as FlagProviderCls,
} from '../internal/flag/provider';
import InlineDialog from '../internal/inlineDialog/inlineDialogGlobal';
import InlineDialogProvider, {
  InlineDialogProvider as InlineDialogProviderCls,
} from '../internal/inlineDialog/provider';
import Message from '../internal/message/message';
import MessageProvider, {
  MessageProvider as MessageProviderCls,
} from '../internal/message/provider';
import OAuth from '../internal/oauth';

export interface ConnectProviderProps {
  connectHost: typeof ConnectHost;
  addonManager: typeof AddonManager;
  analyticsEventHandler?: SendEvent;
  children?: React.ReactNode;
}

export interface ConnectProviders {
  dialogProvider: DialogProviderCls;
  flagProvider: FlagProviderCls;
  messageProvider: MessageProviderCls;
  inlineDialogProvider: InlineDialogProviderCls;
}

export type ConnectProviderContext = ConnectProviderProps & ConnectProviders;

export default class ConnectProvider extends Component<ConnectProviderProps> {
  declare props: ConnectProviderProps;
  static childContextTypes = {
    dialogProvider: PropTypes.object.isRequired,
    flagProvider: PropTypes.object.isRequired,
    messageProvider: PropTypes.object.isRequired,
    inlineDialogProvider: PropTypes.object.isRequired,
    connectHost: PropTypes.object.isRequired,
    addonManager: PropTypes.object.isRequired,
    analyticsEventHandler: PropTypes.func,
  };
  constructor(props: ConnectProviderProps) {
    super(props);
    props.connectHost.registerProvider('addon', AddonProvider);
  }
  getChildContext(): ConnectProviderContext {
    const { connectHost, addonManager, analyticsEventHandler } = this.props;
    return {
      dialogProvider: DialogProvider,
      flagProvider: FlagProvider,
      messageProvider: MessageProvider,
      inlineDialogProvider: InlineDialogProvider,
      connectHost,
      addonManager,
      analyticsEventHandler,
    };
  }
  componentDidMount() {
    // @ts-ignore TODO: fix noImplicitAny error here
    this.props.connectHost.onFrameClick(iframe => {
      // clicks inside an iframe don't register in the parent window
      // this causes popups like inline dialog not to behave as expected
      // triggering the click on the iframe element fixes this
      iframe.click();
    });
  }
  componentWillUnmount() {
    this.props.connectHost.offFrameClick();
  }
  render() {
    const {
      dialogProvider,
      flagProvider,
      messageProvider,
      inlineDialogProvider,
      connectHost,
      addonManager,
    } = this.getChildContext();
    const adaptor = connectHost.getFrameworkAdaptor();
    return (
      <>
        {this.props.children}
        <Dialog
          connectHost={connectHost}
          dialogProvider={dialogProvider}
          addonManager={addonManager}
        />
        <DialogModule adaptor={adaptor} dialogProvider={dialogProvider} />
        <InlineDialog
          connectHost={connectHost}
          addonManager={addonManager}
          inlineDialogProvider={inlineDialogProvider}
        />
        <InlineDialogModule
          adaptor={adaptor}
          inlineDialogProvider={inlineDialogProvider}
        />
        <Flag flagProvider={flagProvider} />
        <FlagModule adaptor={adaptor} flagProvider={flagProvider} />
        <Message connectHost={connectHost} messageProvider={messageProvider} />
        <MessageModule adaptor={adaptor} messageProvider={messageProvider} />
        <OAuth addonManager={addonManager} />
      </>
    );
  }
}
