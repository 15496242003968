import { useRef } from 'react';
var uniqueReferencedValue = {};

/**
 * Executes the initializer function once and saves its result into a ref.
 *
 * ```js
 * const ref = useLazyRef(() => 10);
 * ```
 *
 * @param initializer
 */
export default function useLazyRef(initializer) {
  var ref = useRef(uniqueReferencedValue);
  if (ref.current === uniqueReferencedValue) {
    ref.current = initializer();
  }
  return ref;
}