import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import memoizeOne from 'memoize-one';

// TODO: comma needed after generic declaration below, or the .tsx file thinks
// it is a JSX element. Tidy up in a future refactor.
var collapseRange = function collapseRange(pages, current, _ref, testId) {
  var max = _ref.max,
    ellipsis = _ref.ellipsis,
    transform = _ref.transform;
  var total = pages.length;
  // only need ellipsis if we have more pages than we can display
  var needEllipsis = total > max;
  // show start ellipsis if the current page is further away than max - 4 from the first page
  var hasStartEllipsis = needEllipsis && max - 4 < current;
  // show end ellipsis if the current page is further than total - max + 3 from the last page
  var hasEndEllipsis = needEllipsis && current < total - max + 3;
  var getPageComponents = memoizeOne(function () {
    var startIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var lastIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : total;
    return pages.slice(startIndex, lastIndex).map(function (page, index) {
      return transform(page, startIndex + index, testId);
    });
  });
  if (!needEllipsis) {
    return getPageComponents(0, total);
  }
  if (hasStartEllipsis && !hasEndEllipsis) {
    var _pageCount = max - 2;
    return [].concat(_toConsumableArray(getPageComponents(0, 1)), [ellipsis({
      key: 'ellipsis-1',
      testId: testId && "".concat(testId, "-ellipsis"),
      from: 2,
      to: total - _pageCount
    })], _toConsumableArray(getPageComponents(total - _pageCount)));
  }
  if (!hasStartEllipsis && hasEndEllipsis) {
    var _pageCount2 = max - 2;
    return [].concat(_toConsumableArray(getPageComponents(0, _pageCount2)), [ellipsis({
      key: 'ellipsis-1',
      testId: testId && "".concat(testId, "-ellipsis"),
      from: _pageCount2 + 1,
      to: total - 1
    })], _toConsumableArray(getPageComponents(total - 1)));
  }
  // we have both start and end ellipsis
  var pageCount = max - 4;
  return [].concat(_toConsumableArray(getPageComponents(0, 1)), [ellipsis({
    key: 'ellipsis-1',
    testId: testId && "".concat(testId, "-ellipsis"),
    from: 2,
    to: current - Math.floor(pageCount / 2)
  })], _toConsumableArray(getPageComponents(current - Math.floor(pageCount / 2), current + pageCount - 1)), [ellipsis({
    key: 'ellipsis-2',
    testId: testId && "".concat(testId, "-ellipsis"),
    from: current + 3,
    to: total - 1
  })], _toConsumableArray(getPageComponents(total - 1)));
};
export default collapseRange;