/** @jsxRuntime classic  */
/** @jsx jsx */
import { ReactNode } from 'react';

import { jsx } from '@emotion/core';
import { css } from 'emotion';

import { visuallyHidden } from '@atlaskit/theme';

export const srOnlyStyles = css`
  ${visuallyHidden()}
`;

// Only display content to screen readers
// See README for more information

export const ScreenReadersOnly = (props: { children: ReactNode }) => (
  /* eslint react/no-unknown-property: "warn" */
  <span css={visuallyHidden} {...props}>
    {props.children}
  </span>
);
