/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchAllPages } from 'src/redux/pull-request/sagas/utils/fetch-with-access-token';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest from 'src/utils/fetch';

import { ANNOTATIONS_PAGE_SIZE } from '../../constants';
import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import {
  REPORT_UPDATED,
  REQUEST_ANNOTATIONS,
  REQUEST_REPORT,
  REQUEST_REPORTS,
} from '../actions/pipelines';
import { getCurrentPipeline } from '../selectors/pipelines';

export const getReportsUrl = (
  repositoryFullSlug: string,
  commitHash: string
) => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/commit/${commitHash}/reports`;
};

export function* requestReportsSaga(): any {
  const { full_name } = yield select(getCurrentRepository);
  const pipeline = yield select(getCurrentPipeline);

  try {
    const url = getReportsUrl(full_name, pipeline.revision);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);

    yield put({
      type: REQUEST_REPORTS.SUCCESS,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_REPORTS.ERROR,
    });
    yield put({
      type: REQUEST_REPORTS.ERROR,
      payload: e,
    });
  }
}

export function* requestReportUpdatedSaga(action: {
  reportUuid?: string;
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const pipeline = yield select(getCurrentPipeline);
  const { reportUuid } = action;

  if (!reportUuid) {
    return;
  }

  try {
    const url = `${getReportsUrl(full_name, pipeline.revision)}/${reportUuid}`;
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);

    yield put({
      type: REQUEST_REPORT.SUCCESS,
      meta: { reportUuid },
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_REPORT.ERROR,
    });
    yield put({
      type: REQUEST_REPORT.ERROR,
      meta: { reportUuid },
      payload: e,
    });
  }
}

export function* requestAnnotationsSaga(action: {
  meta: { reportUuid: string };
  type: string;
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const pipeline = yield select(getCurrentPipeline);

  try {
    const url = `${getReportsUrl(full_name, pipeline.revision)}/${
      action.meta.reportUuid
    }/annotations?sort=-severity&page=1`;
    const data = yield call(fetchAllPages, url, ANNOTATIONS_PAGE_SIZE);

    yield put({
      type: REQUEST_ANNOTATIONS.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_ANNOTATIONS.ERROR,
    });
    yield put({
      type: REQUEST_ANNOTATIONS.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(REQUEST_REPORTS.REQUEST, requestReportsSaga),
    takeLatest(REQUEST_ANNOTATIONS.REQUEST, requestAnnotationsSaga),
    takeLatest(REPORT_UPDATED, requestReportUpdatedSaga),
  ]);
}
