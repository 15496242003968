import _defineProperty from "@babel/runtime/helpers/defineProperty";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { easeOut, prefersReducedMotion } from '@atlaskit/motion';
import { UNSAFE_media } from '@atlaskit/primitives/responsive';
import { BANNER_HEIGHT, LEFT_PANEL_WIDTH, LEFT_SIDEBAR_FLYOUT_WIDTH, LEFT_SIDEBAR_WIDTH, MAX_MOBILE_SIDEBAR_FLYOUT_WIDTH, MOBILE_COLLAPSED_LEFT_SIDEBAR_WIDTH, TOP_NAVIGATION_HEIGHT, TRANSITION_DURATION } from '../../../common/constants';
import { useIsSidebarDragging } from '../../../common/hooks';
// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
var prefersReducedMotionStyles = css(prefersReducedMotion());
var mobileStyles = css(_defineProperty({}, UNSAFE_media.below.sm, {
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: "".concat(MOBILE_COLLAPSED_LEFT_SIDEBAR_WIDTH, "px"),
  position: 'fixed',
  insetBlockEnd: 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  insetBlockStart: "calc(".concat(BANNER_HEIGHT, " + ").concat(TOP_NAVIGATION_HEIGHT, ")"),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  insetInlineStart: "".concat(LEFT_PANEL_WIDTH),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  transition: "width ".concat(TRANSITION_DURATION, "ms ").concat(easeOut, " 0s")
}));
var mobileInnerFlyoutStyles = css(_defineProperty({}, UNSAFE_media.below.sm, {
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: "min(90vw, ".concat(MAX_MOBILE_SIDEBAR_FLYOUT_WIDTH, "px)"),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  maxWidth: MAX_MOBILE_SIDEBAR_FLYOUT_WIDTH,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  transition: "width ".concat(TRANSITION_DURATION, "ms ").concat(easeOut, " 0s, box-shadow ").concat(TRANSITION_DURATION, "ms ").concat(easeOut, " 0s")
}));

/**
 * This inner wrapper is required to allow the sidebar to be `position: fixed`.
 *
 * If we were to apply `position: fixed` to the outer wrapper, it will be popped
 * out of its flex container and Main would stretch to occupy all the space.
 */
var fixedInnerStyles = css({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: "".concat(LEFT_SIDEBAR_WIDTH),
  position: 'fixed',
  insetBlockEnd: 0,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  insetBlockStart: "calc(".concat(BANNER_HEIGHT, " + ").concat(TOP_NAVIGATION_HEIGHT, ")"),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  insetInlineStart: "".concat(LEFT_PANEL_WIDTH),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  transition: "width ".concat(TRANSITION_DURATION, "ms ").concat(easeOut, " 0s")
});
var fixedInnerFlyoutStyles = css({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: LEFT_SIDEBAR_FLYOUT_WIDTH
});

/**
 * Static in the sense of `position: static`.
 *
 * It will expand the page height to fit its content.
 */
var staticInnerStyles = css({
  height: '100%'
});
var draggingStyles = css({
  cursor: 'ew-resize',
  // Make sure drag to resize does not animate as the user drags
  transition: 'none'
});
var LeftSidebarInner = function LeftSidebarInner(_ref) {
  var children = _ref.children,
    _ref$isFixed = _ref.isFixed,
    isFixed = _ref$isFixed === void 0 ? false : _ref$isFixed,
    _ref$isFlyoutOpen = _ref.isFlyoutOpen,
    isFlyoutOpen = _ref$isFlyoutOpen === void 0 ? false : _ref$isFlyoutOpen;
  var isDragging = useIsSidebarDragging();
  return jsx("div", {
    css: [
    // mobile viewport styles
    mobileStyles, isFlyoutOpen && mobileInnerFlyoutStyles,
    // generic styles
    !isFixed && staticInnerStyles, isFixed && fixedInnerStyles, isFixed && isFlyoutOpen && fixedInnerFlyoutStyles, isDragging && draggingStyles, prefersReducedMotionStyles]
  }, children);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default LeftSidebarInner;