/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useContext } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { ClassNames, css, jsx } from '@emotion/react';
import { propDeprecationWarning } from '@atlaskit/ds-lib/deprecation-warning';
import FocusRing from '@atlaskit/focus-ring';
import { Inline, Stack, xcss } from '@atlaskit/primitives';
import { N20, N200, N30 } from '@atlaskit/theme/colors';
import { SELECTION_STYLE_CONTEXT_DO_NOT_USE, SpacingContext } from './menu-context';
var defaultRender = function defaultRender(Component, props) {
  return (
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
    jsx(Component, props)
  );
};
var beforeAfterElementStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0
});
var contentStyles = xcss({
  outline: 'none',
  overflow: 'hidden',
  textAlign: 'left'
});
var truncateStyles = css({
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});
var titleStyles = css({
  font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
});
var wordBreakStyles = css({
  wordBreak: 'break-word'
});
var descriptionStyles = css({
  color: "var(--ds-text-subtlest, ".concat(N200, ")"),
  font: "var(--ds-font-body-UNSAFE_small, normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
});
var disabledDescriptionStyles = css({
  color: "var(--ds-text-disabled, ".concat(N200, ")")
});
var positionRelativeStyles = css({
  position: 'relative'
});
var primitiveStyles = css({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  minHeight: 40,
  margin: "var(--ds-space-0, 0px)",
  alignItems: 'center',
  border: 0,
  outline: 0,
  textDecoration: 'none',
  userSelect: 'none',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&::-moz-focus-inner': {
    border: 0
  },
  '&:hover': {
    textDecoration: 'none'
  }
});
var spacingMapStyles = {
  cozy: css({
    // 8 * 2 (16) + icon (24) === 40
    paddingBlock: "var(--ds-space-100, 8px)",
    paddingInline: "var(--ds-space-200, 16px)"
  }),
  compact: css({
    minHeight: 32,
    // 4 * 2 (8) + icon (24) === 32
    paddingBlock: "var(--ds-space-050, 4px)",
    paddingInline: "var(--ds-space-150, 12px)"
  })
};
var interactiveStyles = css({
  cursor: 'pointer'
});
var unselectedStyles = css({
  backgroundColor: "var(--ds-background-neutral-subtle, transparent)",
  color: 'currentColor',
  '&:visited': {
    color: 'currentColor'
  },
  '&:hover': {
    backgroundColor: "var(--ds-background-neutral-subtle-hovered, ".concat(N20, ")"),
    color: 'currentColor'
  },
  '&:active': {
    backgroundColor: "var(--ds-background-neutral-subtle-pressed, ".concat(N30, ")"),
    boxShadow: 'none',
    color: 'currentColor'
  }
});
var disabledStyles = css({
  cursor: 'not-allowed',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&, :hover, :active': {
    backgroundColor: "var(--ds-background-neutral-subtle, transparent)",
    color: "var(--ds-text-disabled, ".concat(N200, ")")
  }
});
var selectedBorderStyles = css({
  '&::before': {
    width: 2,
    position: 'absolute',
    background: "var(--ds-border-selected, transparent)",
    content: '""',
    insetBlockEnd: 0,
    insetBlockStart: 0,
    insetInlineStart: 0
  }
});
var selectedNotchStyles = css({
  '&::before': {
    width: 4,
    position: 'absolute',
    background: "var(--ds-border-selected, transparent)",
    borderRadius: "0 ".concat("var(--ds-border-radius, 4px)", " ", "var(--ds-border-radius, 4px)", " 0"),
    content: '""',
    insetBlockEnd: "var(--ds-space-150, 12px)",
    insetBlockStart: "var(--ds-space-150, 12px)",
    insetInlineStart: 0
  }
});
var selectedStyles = css({
  backgroundColor: "var(--ds-background-selected, ".concat(N20, ")"),
  // Fallback set as babel plugin inserts one otherwise
  color: "var(--ds-text-selected, currentColor)",
  '&:visited': {
    color: "var(--ds-text-selected, currentColor)"
  },
  '&:hover': {
    backgroundColor: "var(--ds-background-selected-hovered, ".concat(N20, ")"),
    color: "var(--ds-text-selected, currentColor)"
  },
  '&:active': {
    backgroundColor: "var(--ds-background-selected-pressed, ".concat(N30, ")"),
    color: "var(--ds-text-selected, currentColor)"
  }
});
var gapMap = {
  compact: 'space.100',
  cozy: 'space.150'
};

/**
 * __Menu item primitive__
 *
 * Menu item primitive contains all the styles for menu items,
 * including support for selected, disabled, and interaction states.
 *
 * Using children as function prop you wire up this to your own host element.
 *
 * ```jsx
 * <MenuItemPrimitive>
 *   {({ children, ...props }) => <button {...props}>{children}</button>}
 * </MenuItemPrimitive>
 * ```
 */
var MenuItemPrimitive = function MenuItemPrimitive(_ref) {
  var children = _ref.children,
    title = _ref.title,
    description = _ref.description,
    iconAfter = _ref.iconAfter,
    iconBefore = _ref.iconBefore,
    overrides = _ref.overrides,
    UNSAFE_externalClassName = _ref.className,
    _ref$shouldTitleWrap = _ref.shouldTitleWrap,
    shouldTitleWrap = _ref$shouldTitleWrap === void 0 ? false : _ref$shouldTitleWrap,
    _ref$shouldDescriptio = _ref.shouldDescriptionWrap,
    shouldDescriptionWrap = _ref$shouldDescriptio === void 0 ? false : _ref$shouldDescriptio,
    _ref$isDisabled = _ref.isDisabled,
    isDisabled = _ref$isDisabled === void 0 ? false : _ref$isDisabled,
    _ref$isSelected = _ref.isSelected,
    isSelected = _ref$isSelected === void 0 ? false : _ref$isSelected,
    testId = _ref.testId;
  propDeprecationWarning("@atlaskit/menu" || '', 'overrides', overrides !== undefined, '' // TODO: Create DAC post when primitives/xcss are available as alternatives
  );
  var spacing = useContext(SpacingContext);
  var selectionStyle = useContext(SELECTION_STYLE_CONTEXT_DO_NOT_USE);
  var renderTitle = overrides && overrides.Title && overrides.Title.render || defaultRender;
  var UNSAFE_className = UNSAFE_externalClassName;
  return jsx(ClassNames, null, function (_ref2) {
    var cn = _ref2.css,
      cx = _ref2.cx;
    return jsx(FocusRing, {
      isInset: true
    }, children({
      className: cx([cn([positionRelativeStyles, primitiveStyles, spacingMapStyles[spacing], !isDisabled && !isSelected && unselectedStyles, !isDisabled && isSelected && [selectedStyles, [selectionStyle === 'border' && selectedBorderStyles, selectionStyle === 'notch' && selectedNotchStyles]], isDisabled ? disabledStyles : interactiveStyles]), UNSAFE_className]),
      children: jsx(Inline, {
        as: "span",
        spread: "space-between",
        alignBlock: "center",
        space: gapMap[spacing],
        grow: "fill",
        testId: testId && "".concat(testId, "--container")
      }, iconBefore && jsx("span", {
        "data-item-elem-before": true,
        css: beforeAfterElementStyles,
        "data-testid": testId && "".concat(testId, "--icon-before")
      }, iconBefore), title && jsx(Stack, {
        alignBlock: "center",
        grow: "fill",
        xcss: contentStyles
      }, renderTitle('span', {
        children: title,
        className: cn(titleStyles, shouldTitleWrap ? wordBreakStyles : truncateStyles),
        'data-item-title': true
      }), description && jsx("span", {
        "data-item-description": true,
        css: [descriptionStyles, isDisabled && disabledDescriptionStyles, shouldDescriptionWrap ? wordBreakStyles : truncateStyles]
      }, description)), iconAfter && jsx("span", {
        "data-item-elem-after": true,
        css: beforeAfterElementStyles,
        "data-testid": testId && "".concat(testId, "--icon-after")
      }, iconAfter))
    }));
  });
};
export default MenuItemPrimitive;