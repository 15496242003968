import React from 'react';

import Loadable from 'react-loadable';

import { NavigationSkeleton } from '@atlaskit/atlassian-navigation/skeleton';

import DrawerHeader from './drawer-header';
import { NavigationV3Props } from './index-next';

const HorizontalNav = Loadable({
  loading: () => (
    <NavigationSkeleton
      primaryItemsCount={5}
      secondaryItemsCount={2}
      shouldShowSearch
    />
  ),
  loader: () =>
    import(
      /* webpackChunkName: "horizontal-navigation" */ './horizontal-nav/horizontal-navigation'
    ),
});

const MobileNav = Loadable({
  loading: () => null,
  loader: () =>
    import(
      /* webpackChunkName: "horizontal-mobile-nav" */ './horizontal-nav/mobile-navigation/horizontal-mobile-nav'
    ),
});

export const HorizontalOrMobileNav = (props: NavigationV3Props) => {
  const { isMobileHeaderActive, isBeingRenderedInsideMobileNav } = props;
  const showMobileNav: boolean =
    isMobileHeaderActive && isBeingRenderedInsideMobileNav;
  const showHNav: boolean =
    !isMobileHeaderActive && !isBeingRenderedInsideMobileNav;

  if (!showHNav && !showMobileNav) {
    return null;
  }

  const {
    containerBitbucketActions,
    containerConnectActions,
    containerHref,
    containerLogo,
    containerName,
    createDrawerHeaderLinkComponent,
    currentProject,
    globalBitbucketActions,
    horizontalNavigationItems,
    isGlobalContext,
    isPrivate,
    linkComponent,
    switchAccountUrl,
    manageAccountUrl,
    loginUrl,
    logoutUrl,
    onCloneClick,
    onCreateDrawerClose,
    onSearch,
    onSearchDrawerInit,
    onSearchSubmit,
    pullRequestLinkComponent,
    recentlyViewed,
    recentlyViewedWorkspacesKeys,
    repoName,
    repositoryLinkComponent,
    repoSlug,
    searchQuery,
    userAvatarUrl,
    userEmail,
    userDisplayName,
    userUuid,
    userWorkspaceId,
    onKeyboardShortcutsActivated,
    onFeedbackModalOpen,
    adminHubUrl,
    attributionsUrl,
    whatsNewUrl,
    onToggleAtlassianSwitcher,
    isAtlassianSwitcherOpen,
    isOrgAdmin,
    isWorkspaceAdmin,
    workspaceOrgId,
    publishUiEvent,
    useContainerNavigationProps,
    isSearchDrawerOpen,
    onSearchDrawerClose,
    onSearchDrawerOpen,
    workspaceLinkComponent,
    hasPride,
    currentRepository,
    currentWorkspace,
    currentUser,
    environment,
    tenantId,
  } = props;

  const handleProductClick = (e: React.MouseEvent) => {
    const { onProductClick } = props;

    // Following checks are for not preventing the "open in tab" action:
    if (e.metaKey) {
      // e.metaKey is 'true' for cmd + click on Mac.
      return;
    }
    if (e.ctrlKey) {
      // e.ctrlKey is 'true' for ctrl + click on Windows.
      return;
    }
    if (e.button === 1) {
      // e.button is '1' when mouse middle button is clicked.
      return;
    }

    if (Boolean(userUuid) && onProductClick) {
      // 'preventDefault' is to avoid full page reload for SPA transitions.
      e.preventDefault();
      onProductClick();
    }
  };

  const drawerHeader = (
    <DrawerHeader
      isGlobalContext={isGlobalContext}
      isPrivate={isPrivate}
      href={containerHref}
      name={containerName}
      logo={containerLogo}
      linkComponent={createDrawerHeaderLinkComponent}
    />
  );

  if (showMobileNav) {
    return (
      <MobileNav
        avatarUrl={userAvatarUrl}
        environment={environment}
        containerBitbucketActions={containerBitbucketActions}
        containerConnectActions={containerConnectActions}
        currentProject={currentProject}
        drawerHeader={drawerHeader}
        globalBitbucketActions={globalBitbucketActions}
        switchAccountUrl={switchAccountUrl}
        manageAccountUrl={manageAccountUrl}
        loginUrl={loginUrl}
        logoutUrl={logoutUrl}
        menuItems={
          horizontalNavigationItems || {
            mainItems: [],
            secondaryItems: [],
            settingsItems: [],
          }
        }
        isLoggedIn={Boolean(userUuid)}
        linkComponent={linkComponent}
        onCloneClick={onCloneClick}
        onCreateDrawerClose={onCreateDrawerClose}
        onSearch={onSearch}
        onSearchDrawerInit={onSearchDrawerInit}
        onSearchSubmit={onSearchSubmit}
        isSearchDrawerOpen={isSearchDrawerOpen}
        onSearchDrawerClose={onSearchDrawerClose}
        onSearchDrawerOpen={onSearchDrawerOpen}
        pullRequestLinkComponent={pullRequestLinkComponent}
        recentlyViewed={recentlyViewed}
        recentlyViewedWorkspacesKeys={recentlyViewedWorkspacesKeys}
        repoName={repoName}
        repositoryLinkComponent={repositoryLinkComponent}
        repoSlug={repoSlug}
        searchQuery={searchQuery}
        userAvatarUrl={userAvatarUrl}
        userEmail={userEmail}
        userDisplayName={userDisplayName}
        userUuid={userUuid}
        userWorkspaceId={userWorkspaceId}
        onKeyboardShortcutsActivated={onKeyboardShortcutsActivated}
        onFeedbackModalOpen={onFeedbackModalOpen}
        adminHubUrl={adminHubUrl}
        attributionsUrl={attributionsUrl}
        whatsNewUrl={whatsNewUrl}
        onToggleAtlassianSwitcher={onToggleAtlassianSwitcher}
        isAtlassianSwitcherOpen={isAtlassianSwitcherOpen}
        isOrgAdmin={isOrgAdmin}
        isWorkspaceAdmin={isWorkspaceAdmin}
        workspaceOrgId={workspaceOrgId}
        publishUiEvent={publishUiEvent}
        useContainerNavigationProps={useContainerNavigationProps}
        containerHref={containerHref}
        containerName={containerName}
        handleProductClick={handleProductClick}
        workspaceLinkComponent={workspaceLinkComponent}
        currentRepository={currentRepository}
        currentWorkspace={currentWorkspace}
        currentUser={currentUser}
      />
    );
  } else {
    return (
      <HorizontalNav
        hasPride={hasPride}
        avatarUrl={userAvatarUrl}
        environment={environment}
        containerBitbucketActions={containerBitbucketActions}
        containerConnectActions={containerConnectActions}
        currentProject={currentProject}
        drawerHeader={drawerHeader}
        globalBitbucketActions={globalBitbucketActions}
        switchAccountUrl={switchAccountUrl}
        manageAccountUrl={manageAccountUrl}
        loginUrl={loginUrl}
        logoutUrl={logoutUrl}
        menuItems={
          horizontalNavigationItems || {
            mainItems: [],
            secondaryItems: [],
            settingsItems: [],
          }
        }
        isLoggedIn={Boolean(userUuid)}
        linkComponent={linkComponent}
        onCloneClick={onCloneClick}
        onCreateDrawerClose={onCreateDrawerClose}
        onSearch={onSearch}
        onSearchDrawerInit={onSearchDrawerInit}
        isSearchDrawerOpen={isSearchDrawerOpen}
        onSearchDrawerClose={onSearchDrawerClose}
        onSearchDrawerOpen={onSearchDrawerOpen}
        onSearchSubmit={onSearchSubmit}
        pullRequestLinkComponent={pullRequestLinkComponent}
        recentlyViewed={recentlyViewed}
        recentlyViewedWorkspacesKeys={recentlyViewedWorkspacesKeys}
        repoName={repoName}
        repositoryLinkComponent={repositoryLinkComponent}
        repoSlug={repoSlug}
        searchQuery={searchQuery}
        userAvatarUrl={userAvatarUrl}
        userEmail={userEmail}
        userDisplayName={userDisplayName}
        userUuid={userUuid}
        userWorkspaceId={userWorkspaceId}
        onKeyboardShortcutsActivated={onKeyboardShortcutsActivated}
        onFeedbackModalOpen={onFeedbackModalOpen}
        adminHubUrl={adminHubUrl}
        attributionsUrl={attributionsUrl}
        whatsNewUrl={whatsNewUrl}
        onToggleAtlassianSwitcher={onToggleAtlassianSwitcher}
        isAtlassianSwitcherOpen={isAtlassianSwitcherOpen}
        isOrgAdmin={isOrgAdmin}
        isWorkspaceAdmin={isWorkspaceAdmin}
        workspaceOrgId={workspaceOrgId}
        handleProductClick={handleProductClick}
        publishUiEvent={publishUiEvent}
        workspaceLinkComponent={workspaceLinkComponent}
        currentRepository={currentRepository}
        currentWorkspace={currentWorkspace}
        currentUser={currentUser}
        tenantId={tenantId}
      />
    );
  }
};
