import { useContext, useEffect } from 'react';
import { v4 as createUUID } from 'uuid';
import coinflip from '../coinflip';
import { getDoNotAbortActivePressInteractionOnTransition, getInteractionRate } from '../config';
import { getActiveTrace } from '../experience-trace-id-context';
import UFOInteractionIDContext, { DefaultInteractionID } from '../interaction-id-context';
import { abortAll, addNewInteraction, addOnCancelCallback, getActiveInteraction, tryComplete } from '../interaction-metrics';
import UFORouteName from '../route-name-context';
import { setInteractionActiveTrace } from './utils/set-interaction-active-trace';
export default function traceUFOTransition(ufoName) {
  var routeName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ufoName;
  var pressInteractionsList = getDoNotAbortActivePressInteractionOnTransition();
  var interaction = getActiveInteraction();
  if (pressInteractionsList && interaction) {
    if (pressInteractionsList.includes(interaction.ufoName)) {
      return;
    }
  }
  abortAll('transition', ufoName !== null && ufoName !== void 0 ? ufoName : undefined);
  if (ufoName) {
    UFORouteName.current = ufoName;
    var rate = getInteractionRate(ufoName, 'transition');
    if (coinflip(rate)) {
      var newId = createUUID();
      setInteractionActiveTrace(newId);
      DefaultInteractionID.current = newId;
      addNewInteraction(newId, ufoName, 'transition', performance.now(), rate, null, routeName, getActiveTrace());
    }
  }
}
export function useUFOTransitionCompleter() {
  var interactionId = useContext(UFOInteractionIDContext);
  var capturedInteractionId = interactionId.current;
  useEffect(function () {
    // If we have a current interaction set...
    if (capturedInteractionId != null) {
      var cancel = requestAnimationFrame(function () {
        cancel = requestAnimationFrame(function () {
          if (capturedInteractionId === interactionId.current) {
            tryComplete(capturedInteractionId);
          }
        });
      });
      addOnCancelCallback(capturedInteractionId, function () {
        cancelAnimationFrame(cancel);
      });
    }
  }, [capturedInteractionId, interactionId]);
}