import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Inspired by analytics-web-react
 */

import { OPERATIONAL_EVENT_TYPE, SCREEN_EVENT_TYPE, TRACK_EVENT_TYPE, UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import last from 'lodash/last';
import { NAVIGATION_CONTEXT } from '@atlaskit/analytics-namespaced-context';
import { getExtraAttributes, getSources } from '../atlaskit/extract-data-from-event';
import { getExtraAttributes as getExtraAttributesFromContextName } from '../helpers/extract-data-from-event';
var CROSS_FLOW_TAG = 'crossFlow';
export default (function (event, logger) {
  var _event$payload$source;
  var _event$payload = event.payload,
    _event$payload$eventT = _event$payload.eventType,
    eventType = _event$payload$eventT === void 0 ? UI_EVENT_TYPE : _event$payload$eventT,
    action = _event$payload.action,
    actionSubject = _event$payload.actionSubject,
    actionSubjectId = _event$payload.actionSubjectId,
    name = _event$payload.name;
  var sources = getSources(event);
  var lastSourceFromContext = last(sources);
  var source = (_event$payload$source = event.payload.source) !== null && _event$payload$source !== void 0 ? _event$payload$source : lastSourceFromContext;
  if (lastSourceFromContext !== source) {
    sources.push(source);
  }
  var attributes = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, getExtraAttributes(event)), getExtraAttributesFromContextName(event, NAVIGATION_CONTEXT)), event.payload.attributes), {}, {
    namespaces: sources.join('.')
  });

  // Ensure navigation tag is not duplicated by using Set
  var tags = new Set(event.payload.tags || []);
  tags.add(CROSS_FLOW_TAG);
  if (event.payload) {
    switch (eventType) {
      case UI_EVENT_TYPE:
      case OPERATIONAL_EVENT_TYPE:
      case TRACK_EVENT_TYPE:
        return {
          eventType: eventType,
          source: source,
          actionSubject: actionSubject,
          action: action,
          actionSubjectId: actionSubjectId,
          attributes: attributes,
          tags: Array.from(tags)
        };
      case SCREEN_EVENT_TYPE:
        return {
          eventType: eventType,
          name: name,
          attributes: attributes,
          tags: Array.from(tags)
        };
      default:
        logger.error('Invalid event type', eventType);
        break;
    }
  }
  return null;
});