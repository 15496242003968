import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react';
import memoizeOne from 'memoize-one';

/*
 * Get a memoized functional ref for use within a Popup's Trigger.
 * This is still very volatile to change as `prop.isOpen` will regularly change, but it's better than nothing.
 * This is memoized within a component as to not be shared across all Popup instances.
 *
 * This is complex because the inputs are split across three different scopes:
 *  - `props.isOpen`
 *  - `useState.setTriggerRef`
 *  - `renderProps.ref`
 */
export var useGetMemoizedMergedTriggerRef = function useGetMemoizedMergedTriggerRef() {
  var _useState = useState(function () {
      return memoizeOne(function (ref, setTriggerRef, isOpen) {
        return function (node) {
          if (node && isOpen) {
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
            setTriggerRef(node);
          }
        };
      });
    }),
    _useState2 = _slicedToArray(_useState, 1),
    getMemoizedMergedTriggerRef = _useState2[0];
  return getMemoizedMergedTriggerRef;
};