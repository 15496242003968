import FeatureGates from '@atlaskit/feature-gate-js-client';
import { StatsigFeatureKeys } from '@atlassian/bitbucket-features';

import atlassianStore from './atlassian-store';
import originalStore from './original-store';

const getStore = () => {
  const shouldUseBrowserStorageControls = FeatureGates.checkGate(
    StatsigFeatureKeys.useBrowserStorageControls
  );
  if (shouldUseBrowserStorageControls) {
    return atlassianStore;
  }
  return originalStore;
};

const store = {
  set: (key: string, data: any, expiration?: number) =>
    getStore().set(key, data, expiration),
  get: (key: string, optionalDefaultValue?: any) =>
    getStore().get(key, optionalDefaultValue),
  remove: (key: string) => getStore().remove(key),
  clearAll: () => getStore().clearAll(),
  enabled: getStore().enabled,
};

export default store;
