import React, { ComponentType, ReactElement, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { MenuItem } from '@atlassian/bitbucket-navigation';

import { FocusedPageHeader } from 'src/components/accessibility';
import { useLocation, useMatch } from 'src/router/hooks';
import { getIsMobileHeaderActive } from 'src/selectors/global-selectors';
import { BucketState } from 'src/types/state';

import { SettingsPageSection, SettingsPageWrapper } from './settings.styled';

const MOBILE_NAV_HEIGHT = 54;

export type SharedProps = {
  selectedMenuItem?: MenuItem | undefined;
  topOffset?: number;
  pageTitle?: string;
  url?: string;
};

export type Props = {
  header?: ComponentType<SharedProps>;
  actions?: ComponentType | ReactElement;
  breadcrumbs?: ComponentType<SharedProps>;
  container?: ComponentType;
  noLayout?: boolean;
  passHeader?: boolean;
  url?: string;
};

const createSettingsHOC =
  (
    getSelectedMenuItem: (
      state: BucketState,
      pathname: string,
      matchUrl: string
    ) => MenuItem | undefined,
    defaultProps?: Props
  ) =>
  <P,>(
    Component: ComponentType<P & Props & SharedProps>,
    componentProps?: Props
    /* eslint @typescript-eslint/ban-types: "warn" */
  ): React.FC<P & Props> => {
    return settingsProps => {
      const match = useMatch();
      const location = useLocation();
      const isMobileHeaderActive = useSelector(getIsMobileHeaderActive);
      const topOffset = isMobileHeaderActive ? MOBILE_NAV_HEIGHT : 0;
      const selectedMenuItem = useSelector((state: BucketState) =>
        getSelectedMenuItem(state, location.pathname, match.url)
      );
      const props = { ...defaultProps, ...componentProps, ...settingsProps };
      const { noLayout } = props;
      const sharedProps = useMemo<SharedProps>(
        () => ({ selectedMenuItem, topOffset }),
        [selectedMenuItem, topOffset]
      );

      if (noLayout) {
        return <Component {...props} {...sharedProps} />;
      }

      const {
        actions: Actions,
        header: Header,
        container: Container,
        breadcrumbs: PageBreadcrumbs,
        passHeader,
      } = props as P & Props;

      const PageHeader = useMemo<ComponentType<SharedProps>>(
        () =>
          (headerProps: Partial<SharedProps> = {}) =>
            Header && typeof Header === 'function' ? (
              <Header {...sharedProps} {...headerProps} />
            ) : (
              <FocusedPageHeader
                actions={typeof Actions === 'function' ? <Actions /> : Actions}
                breadcrumbs={
                  PageBreadcrumbs ? (
                    <PageBreadcrumbs {...sharedProps} {...headerProps} />
                  ) : undefined
                }
              >
                {headerProps.pageTitle ||
                  sharedProps.pageTitle ||
                  sharedProps.selectedMenuItem?.label}
              </FocusedPageHeader>
            ),
        [Header, Actions, PageBreadcrumbs, sharedProps]
      );

      const layout = (
        <SettingsPageSection>
          <SettingsPageWrapper>
            {passHeader ? null : <PageHeader />}
            <Component
              {...props}
              {...sharedProps}
              header={passHeader ? PageHeader : undefined}
            />
          </SettingsPageWrapper>
        </SettingsPageSection>
      );

      if (Container) {
        return <Container>{layout}</Container>;
      }

      return layout;
    };
  };

export default createSettingsHOC;
