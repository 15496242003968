import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["width", "isFixedSize", "shouldTruncate", "innerRef"];
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { cellStyles, fixedSizeTruncateStyles, getTruncationStyleVars, overflowTruncateStyles, truncationWidthStyles } from './constants';

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var TableBodyCell = function TableBodyCell(_ref) {
  var width = _ref.width,
    isFixedSize = _ref.isFixedSize,
    shouldTruncate = _ref.shouldTruncate,
    innerRef = _ref.innerRef,
    props = _objectWithoutProperties(_ref, _excluded);
  return jsx("td", _extends({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    style: getTruncationStyleVars({
      width: width
    }),
    css: [
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
    truncationWidthStyles,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
    isFixedSize && shouldTruncate && fixedSizeTruncateStyles,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
    isFixedSize && overflowTruncateStyles,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
    cellStyles]
    // HOC withDimensions complains about the types but it is working fine
    // @ts-ignore
    ,
    ref: innerRef
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, props));
};