// TODO: eventually move this file to @atlaskit/link-actions

export var ACTION_PENDING = 'pending';
export var ACTION_RESOLVING = 'resolving';
export var ACTION_RESOLVED = 'resolved';
export var ACTION_RELOADING = 'reloading';
export var ACTION_ERROR = 'errored';
export var ACTION_ERROR_FALLBACK = 'fallback';
export var ACTION_PRELOAD = 'preload';
export var ACTION_UPDATE_METADATA_STATUS = 'metadata';

// export const ANALYTICS_RESOLVING = 'resolving';
// export const ANALYTICS_ERROR = 'errored';
// export const ANALYTICS_FALLBACK = 'fallback';

export var cardAction = function cardAction(type, _ref, payload, error, metadataStatus, ignoreStatusCheck) {
  var url = _ref.url;
  return {
    type: type,
    url: url,
    payload: payload,
    error: error,
    metadataStatus: metadataStatus,
    ignoreStatusCheck: ignoreStatusCheck
  };
};