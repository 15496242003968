import { layoutSection as layoutSectionFactory, layoutSectionWithSingleColumnStage0 as layoutSectionWithSingleColumnStage0Factory } from '../../next-schema/generated/nodeTypes';
import { layoutSectionFull as layoutSectionFullFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name layoutSection_node
 */

/**
 * Need duplicate `type` and `marks` to make both validator and json-schema satisfied
 */

/**
 * @name layoutSection_full_node
 */

/**
 * @stage 0
 * @name layoutSection_with_single_column_node
 */

export var layoutSection = layoutSectionFactory({
  parseDOM: [{
    context: 'layoutSection//|layoutColumn//',
    tag: 'div[data-layout-section]',
    skip: true
  }, {
    tag: 'div[data-layout-section]'
  }],
  toDOM: function toDOM() {
    var attrs = {
      'data-layout-section': 'true'
    };
    return ['div', attrs, 0];
  }
});
export var layoutSectionFull = layoutSectionFullFactory({
  parseDOM: [{
    context: 'layoutSection//|layoutColumn//',
    tag: 'div[data-layout-section]',
    skip: true
  }, {
    tag: 'div[data-layout-section]'
  }],
  toDOM: function toDOM() {
    var attrs = {
      'data-layout-section': 'true'
    };
    return ['div', attrs, 0];
  }
});

// stage-0 support for columnRuleStyle attribute and 1-5 columns
export var layoutSectionWithSingleColumn = layoutSectionWithSingleColumnStage0Factory({
  parseDOM: [{
    context: 'layoutSection//|layoutColumn//',
    tag: 'div[data-layout-section]',
    skip: true
  }, {
    tag: 'div[data-layout-section]',
    getAttrs: function getAttrs(dom) {
      var columnRuleStyle = dom.getAttribute('data-column-rule-style');
      return columnRuleStyle ? {
        columnRuleStyle: columnRuleStyle
      } : {};
    }
  }],
  toDOM: function toDOM(node) {
    var columnRuleStyle = node.attrs.columnRuleStyle;
    var attrs = {
      'data-layout-section': 'true',
      'data-column-rule-style': columnRuleStyle || undefined
    };
    return ['div', attrs, 0];
  }
});