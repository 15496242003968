import React from 'react';

import { FormattedMessage } from 'react-intl';

import Flag from '@atlaskit/flag';
import Warning from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { HandleDismissedType } from 'src/redux/flags/types';
import urls from 'src/urls/global';
import store from 'src/utils/store';

import messages from './userpullrequests-removal-flag.i18n';

type Props = {
  id: string;
} & HandleDismissedType;

export const USER_PR_DEPRECATION_FLAG_ID =
  'userpullrequests-removal-flag' as const;

export const UserPullRequestsRemovalFlag = (props: Props): JSX.Element => {
  const intl = useIntl();
  const dismiss = () => {
    store.set(USER_PR_DEPRECATION_FLAG_ID, true);
    props.handleDismissed(props.id);
  };

  return (
    <Flag
      appearance="warning"
      title={intl.formatMessage(messages.title)}
      description={
        <FormattedMessage
          {...messages.description}
          values={{
            userPullRequestsRemovalLink: (
              <a target="_blank" href={urls.external.userPullRequestsRemoval}>
                <FormattedMessage {...messages.userPullRequestsRemovalLink} />
              </a>
            ),
          }}
        />
      }
      icon={
        <Warning
          label=""
          primaryColor={token('color.icon.warning', colors.Y300)}
        />
      }
      actions={[
        {
          content: intl.formatMessage(messages.dismiss),
          target: '_blank',
          onClick: dismiss,
        },
      ]}
      {...props}
    />
  );
};
