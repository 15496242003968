import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import isEqual from 'lodash/isEqual';
import { createStore } from 'redux';
import { createCancellationToken } from '../../utils/createCancellationToken';
import { isMessagingEnabled } from '../../utils/messaging';
import { CLEAR_CURRENT, initialize, RESET_STORE, resetStore, SET_CURRENT, SET_INITIALIZING, SET_MESSAGE_MAPPING } from '../engagement-actions';
import { processMessages } from '../engagement-api';
export var INITIAL_STATE = {
  messageMapping: new Map(),
  current: null,
  initialized: false,
  initializing: false,
  config: {
    cloudId: '',
    aaid: '',
    locale: '',
    product: '',
    stargateUrl: '',
    orgId: '',
    workspaceId: ''
  }
};
var processMessagesCancellationToken = createCancellationToken();
/**
 * Remove all keys whose value matches the component from the mapping
 */
export function removeComponentFromMapping(messageMapping, component) {
  var newMapping = new Map();
  messageMapping.forEach(function (value, key) {
    if (!isEqual(value.component, component)) {
      newMapping.set(key, value);
    }
  });
  return newMapping;
}
export var store = createStore(function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_MESSAGE_MAPPING:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          initialized: true,
          initializing: false,
          messageMapping: action.messageMapping
        });
      }
    case SET_INITIALIZING:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          config: action.config,
          initializing: true
        });
      }
    case SET_CURRENT:
      {
        var eventMapping = action.eventMapping;
        var message = eventMapping.message,
          component = eventMapping.component;
        var messageMapping = removeComponentFromMapping(state.messageMapping, component);
        return _objectSpread(_objectSpread({}, state), {}, {
          current: _objectSpread({
            messageId: message.messageId,
            variationId: message.variationId
          }, component),
          messageMapping: messageMapping
        });
      }
    case CLEAR_CURRENT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          current: null
        });
      }
    case RESET_STORE:
      {
        return INITIAL_STATE;
      }
    default:
      {
        return state;
      }
  }
});
export default (function (config) {
  var state = store.getState();
  if (state.initializing || state.initialized) {
    if (state.config.cloudId === config.cloudId) {
      return store;
    }

    // CloudId has changed, cancel any in process processMessages request
    processMessagesCancellationToken.isCancelled = true;
    processMessagesCancellationToken = createCancellationToken();
    store.dispatch(resetStore());
  }
  var newConfig = _objectSpread({}, config);
  newConfig.locale = newConfig.locale.replace('_', '-');
  store.dispatch(initialize(newConfig));
  if (isMessagingEnabled()) {
    processMessages(store, newConfig, processMessagesCancellationToken);
  }
  return store;
});