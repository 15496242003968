/**
 * Assigns the node to all the refs passed in the argument.
 *
 * @param refs: An array of refs (as function or ref object)
 */
export default function mergeRefs(refs) {
  // TODO: could this be wrapped in `useCallback` so we get a stable function?
  return function (value) {
    refs.forEach(function (ref) {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null) {
        ref.current = value;
      }
    });
  };
}